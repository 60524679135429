import { Spin } from "antd";
import FeatherIcon from "feather-icons-react";
import React from "react";
import { LoadingButtonStyled, DivSpinStyled } from "./style";

const LoadingButton = (props) => {
  const { toggleCondition, onClick, disabled } = props;

  const ButtonHtml = (
    <LoadingButtonStyled
      onClick={(event) => onClick(event)}
      disabled={disabled}
    >
      <FeatherIcon
        icon="rotate-cw"
        color={disabled ? '#8a8a8a' : '#ffffff'}
        size={12}
      />
    </LoadingButtonStyled>
  )

  const SpinHtml = (
    <DivSpinStyled>
      <Spin size="small" />
    </DivSpinStyled >
  )

  return toggleCondition ? SpinHtml : ButtonHtml;
};

export { LoadingButton };
