const actions = {
  IS_LOADING_BOTS: 'IS_LOADING_BOTS',
  FETCH_BOTS: 'FETCH_BOTS',
  SET_BOTS: 'SET_BOTS',
  SELECTED_TYPE: 'SELECTED_TYPE',

  is_loading_bots: (status) => {
    return {
      type: actions.IS_LOADING_BOTS,
      status: status,
    };
  },
  fetch_bots: (status) => {
    return {
      type: actions.FETCH_BOTS,
      status: status,
    };
  },
  set_bots: (data) => {
    return {
      type: actions.SET_BOTS,
      data: data,
    };
  },
  set_selected_type: (status) => {
    return {
      type: actions.SELECTED_TYPE,
      status: status,
    };
  },
};

export default actions;
