import actions from './actions';

const { IS_LOADING_WHATSAPP_CARDS, FETCH_WHATSAPP_CARDS, SET_WHATSAPP_CARDS, IS_EDITING_WHATSAPP_CARD } = actions;

const initState = {
    whatsappCardsList: [],
    fetchWhatsappCards: true,
    isLoadingWhatsappCards: false,
    isEditingWhatsappCard: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const whatsappCardsReducer = (state = initState, action) => {
    const { type, data, status } = action;
    switch (type) {
        case IS_LOADING_WHATSAPP_CARDS:
            return {
                ...state,
                isLoadingWhatsappCards: status,
            };
        case FETCH_WHATSAPP_CARDS:
            return {
                ...state,
                fetchWhatsappCards: status,
            };
        case SET_WHATSAPP_CARDS:
            data.sort((a, b) => (a.cardname.toUpperCase() > b.cardname.toUpperCase()) ? 1 : ((b.cardname.toUpperCase() > a.cardname.toUpperCase()) ? -1 : 0));
            return {
                ...state,
                whatsappCardsList: data,
            };
        case IS_EDITING_WHATSAPP_CARD:
            return {
                ...state,
                isEditingWhatsappCard: data,
            };
        default:
            return state;
    }
};

export default whatsappCardsReducer;