import { notification } from "antd";
import Cookies from "js-cookie";

const openNotificationWithIcon = (type, message, description,placement) => {
  //types: success info warning error
  notification[type]({
    message: message, //'Notification Title',
    description: description, //'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    placement:!placement?"bottomLeft":placement
  });
};

export default openNotificationWithIcon;
