import React from 'react'
import { ModalStyled } from '../../components/modals/styled'
import { Alert, Input, Typography } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addTagToDB } from '../../redux/tags/actionCreator'

const AddTagModal = ({ modalIsOpen, setModalIsOpen }) => {
    const [tagName, setTagName] = useState('')
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const onCancel = () => {
        setModalIsOpen(false)
        setTagName('')
    }
    const onOk = () => {
        if (!tagName.trim()) {
            setError(true)
        }
        else {
            dispatch(addTagToDB(tagName));
            setModalIsOpen(false)
            setTagName('')
        }
    }
    return (
        <ModalStyled
            title={"Add New Tag"}
            visible={modalIsOpen}
            onOk={onOk}
            onCancel={onCancel}
            bodyStyle={{ maxHeight: "55vh", overflow: "auto" }}
        >
            <Typography>Tag Name</Typography>
            <Input
                style={{ marginTop: "10px" }}
                type="text"
                className="form-control"
                placeholder="tag name"
                value={tagName}
                id="tag_name"
                onChange={(e) => {
                    if (tagName.trim()) { setError(false) }
                    setTagName(e.target.value);
                }}
            ></Input>
            {error &&
                <Alert style={{ marginTop: "10px" }} type="error" message={'Tag name cannot be empty !'} banner />}
        </ModalStyled>
    )
}

export default AddTagModal