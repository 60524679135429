import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import { message } from "antd";
import React, { useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism"; // Dark mode with colors
import { Button } from "../../components/buttons/buttons";
import "./reporting.css"
const NodeOutputModal = ({
  nodeOutput,
  setNodeOutput,
  nodeName,
  setNodeNameAndCounter
}) => {
  const ref=useRef(null)

  const handleCopyClick = (value) => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(value);
    if (window.getSelection) {
      const selection = window.getSelection();
      selection.removeAllRanges();
    }
    const info = () => {
      message.info("Copied");
    };
    info();
  };
  const content = (
    <div
    ref={ref}
    className="container"
    >
      <Button
        icon={<CopyOutlined />}
        onClick={() => handleCopyClick(nodeOutput, true)}
        style={{
          position: "absolute",
          top: "15px",
          transform: "translateX(-50%)",
        }}
      />
      <SyntaxHighlighter
        wrapLines
        wrapLongLines
        language="javascript"
        style={atomDark}
        customStyle={{ width: "100%",padding:"3em" }}
      >
        {nodeOutput}
      </SyntaxHighlighter>
    </div>
  );
  const onCancel = () => {
    setNodeOutput("");
    setNodeNameAndCounter(false)
  };
  return (
    (
      <div
        style={{
          height: "auto",
          borderRadius:"10px",
          // maxHeight: "600px",
          width: "auto",
          // maxWidth: "500px",
          position: "absolute",
          top: "0em",
          left: "38em",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          background: "white",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          zIndex: 1,
        }}
      >
        <CloseOutlined
          style={{
            position: "absolute",
            right: "15px",
            top: "15px",
            fontSize: "15px",
          }}
          onClick={() => onCancel(false)}
        />
        <div style={{ display: "flex", flexDirection: "row",margin:"5px" }}>
            { nodeName!=="error"&&
              <img
                style={{ marginRight: "10px" }}
                width="25px"
                height={"25px"}
                src={`/components-icons/${nodeName.replace(/\d/g, "")}.png`}
              />
            }
            <h3>{nodeName}</h3>
          </div>
        <>{content}</>
      </div>
    )
  );
};

export { NodeOutputModal };
