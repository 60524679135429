import { Button, Form, Input } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import { Checkbox } from "../../../../components/checkbox/checkbox";
import FeatherIcon from "feather-icons-react";
import Heading from "../../../../components/heading/heading";
import openNotificationWithIcon from "../../../../components/notification/notification";
import { setIsLoggingIn } from "../../../../redux/authentication/actionCreator";
// eslint-disable-next-line import/no-extraneous-dependencies
// import { FacebookOutlined, TwitterOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { AuthWrapper } from "./style";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const SignUP = () => {
  const history = useHistory();

  let query = useQuery();
  let token = query.get("token");
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [usernameStatus, setusernameStatus] = useState("");
  const [passwordStatus, setpasswordStatus] = useState("");
  const [EmailStatus, setEmailStatus] = useState("");
  const [confirmPassStatus, setConfirmPassStatus] = useState("");

  const { isLoggingIn } = useSelector(state => {
    return {
      isLoggingIn: state.auth.isLoggingIn,
    };
  });


  useEffect(() => {

    let config = {
      method: "post",
      url: process.env.REACT_APP_SIGNUP_URL + `api/verifyLandingPageToken?token=${token}`,

    };

    axios(config)
      .then((response) => {
        if (!response.error) {

          if (response.data.valid === true) {

          } else if (response.data.valid === false) {
            history.push("/");
          }
        }
        else {
          openNotificationWithIcon('error', 'Failed', response.error);
          history.push("/");
        }
      })
      .catch((error) => {
        history.push("/");
      });

  }, []);

  const [loadingDotsNumber, setLoadingDotsNumber] = useState(1);

  useEffect(() => {
    if (isLoggingIn && loadingDotsNumber) {
      setTimeout(() => {
        let newLoadingDotsNumber = loadingDotsNumber === 3 ? 1 : loadingDotsNumber + 1;
        setLoadingDotsNumber(newLoadingDotsNumber);
      }, 500);
    }
  }, [isLoggingIn, loadingDotsNumber]);// eslint-disable-line

  const validateData = (UserName, Email, Password, confirmPassword) => {
    if (UserName !== null) {
      if (UserName.length > 2) {
        setusernameStatus("Success");
        return true;
      } else {
        setusernameStatus("error");
        return false;
      }
    }
    if (Email !== null) {
      const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
      if (!validEmail.test(Email)) {
        setEmailStatus("error");
        return false;
      } else if (validEmail.test(Email)) {
        setEmailStatus("Success");
        return true;
      }
    }
    if (Password !== null) {
      const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
      if (!validPassword.test(Password)) {
        setpasswordStatus("error");
        return false;
      } else if (validPassword.test(Password)) {
        setpasswordStatus("Success");
        return true;
      }
    }
    if (confirmPassword !== null) {
      if (confirmPassword === password) {
        setConfirmPassStatus("Success");
        return true;
      } else {
        setConfirmPassStatus("error");
        return false;
      }
    }
  };
  const validateMessages = {
    types: {
      email: 'Please enter a valid email!',
      name: "UserName is required",
      password: "Password must be 8 characters long"
    },
  };
  const handleSubmit = (values) => {
    let config = {
      method: "post",
      url: process.env.REACT_APP_SIGNUP_URL + "config/user/add",
      data: {
        name: userName,
        password: password,
        email: Email,
      }
    };
    axios(config)
      .then((response) => {
        if (!response.error) {
          history.push("/");
        }
        else {
          openNotificationWithIcon('error', 'Sign Up failed', response.error);
        }
        dispatch(setIsLoggingIn(false));
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Sign Up failed', error.message);
        console.log("Error getting data: " + error);
        dispatch(setIsLoggingIn(false));
      });
  };

  return (
    <AuthWrapper>

      <div className="auth-contents">
        <Form validateMessages={validateMessages} name="login"
          onFinish={handleSubmit}
          layout="vertical">
          <Heading as="h3">{'Welcome, Please Sign Up'}</Heading>

          <Form.Item style={{ "display": "flex", "marginBottom": "20px" }} label="UserName" rules={[{ required: true }]} validateStatus={usernameStatus} help={usernameStatus === "error" ? "Should be longer than 3 characters" : ""}  >

            <Input value={userName} onKeyUp={() => validateData(userName)} onChange={((event) => setUserName(event.target.value))} />

          </Form.Item>

          <Form.Item style={{ "marginBottom": "20px" }} label="Email" rules={[{ type: 'email', required: true }]} validateStatus={EmailStatus} help={EmailStatus === "error" ? "Invalid Email Format" : ""}>
            <Input value={Email} onKeyUp={() => validateData(null, Email)} onChange={((event) => setEmail(event.target.value))} />
          </Form.Item>

          <Form.Item style={{ "marginBottom": "20px" }} label="Password" rules={[{ type: 'password', required: true }]} validateStatus={passwordStatus} help={passwordStatus === "error" ? "Invalid Password" : ""} >
            <Input.Password value={password} onKeyUp={() => validateData(null, null, password) && validateData(null, null, null, confirmPassword)} onChange={(event) => setPassword(event.target.value)} />
          </Form.Item>

          <Form.Item style={{ "marginBottom": "20px" }} label="Confirm password" rules={[{ type: 'password', required: true }]} validateStatus={confirmPassStatus} help={confirmPassStatus === "error" ? "Passwords Dont Match" : ""} >
            <Input.Password value={confirmPassword} onKeyUp={() => validateData(null, null, null, confirmPassword)} onChange={(event) => setConfirmPassword(event.target.value)} />
          </Form.Item>

          <Form.Item>
            <Button className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large" disabled={(usernameStatus === "Success" && passwordStatus === "Success" && EmailStatus === "Success" && confirmPassStatus === "Success") ? false : true}
            // onClick={handleSubmit}
            >
              <FeatherIcon icon="plus" size={8} />
              {"Sign Up"}
            </Button>
          </Form.Item>

        </Form>
      </div>
    </AuthWrapper >
  );
};

export default SignUP;