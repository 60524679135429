import React/* , { lazy } */ from "react";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import { Route, useRouteMatch } from "react-router-dom";

import Tags from "../../container/tags/Tags";

const TagsRoute = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Tags} />
            {/* <Route path={`${path}/add-kb`} component={addKB} /> */}
        </Switch>
    );
};

export default TagsRoute;
