const actions = {
  SHOW_UPLOAD_FILE_MODAL: 'SHOW_UPLOAD_FILE_MODAL',
  IS_LOADING_UPLOADED_FILES: 'IS_LOADING_UPLOADED_FILES',
  FETCH_UPLOADED_FILES: 'FETCH_UPLOADED_FILES',
  SET_UPLOADED_FILES: 'SET_UPLOADED_FILES',

  show_upload_file_modal: (status) => {
    return {
      type: actions.SHOW_UPLOAD_FILE_MODAL,
      status: status,
    };
  },
  is_loading_uploaded_files: (status) => {
    return {
      type: actions.IS_LOADING_UPLOADED_FILES,
      status: status,
    };
  },
  fetch_uploaded_files: (status) => {
    return {
      type: actions.FETCH_UPLOADED_FILES,
      status: status,
    };
  },
  set_uploaded_files: (data) => {
    return {
      type: actions.SET_UPLOADED_FILES,
      data: data,
    };
  },
};

export default actions;
