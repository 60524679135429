import { CloseCircleOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import FeatherIcon from "feather-icons-react";
import { Button } from "../../components/buttons/buttons";
import { deleteCredential } from "../../redux/credentials/actionCreator";
import { imagesByKey } from "./credentialsData";
import React from "react";
export function timeAgo(dateString) {
  const currentDate = new Date();
  const date = new Date(dateString);
  const pastDate = new Date(date.getTime() + 2 * 60 * 60 * 1000);
  const timeDifference = currentDate - pastDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60) - 60;
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) {
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else {
    return "now";
  }
}

export const convertFilteredDataToHTML = ({ filteredData, dispatch, setRadioGroupValue }) => {
  const newFilteredDataAsHtml = [];
  filteredData.map((value, key) => {
    const { credName, lastUpdate, type } = value;
    let img;
    try {
      img = imagesByKey[type];
    } catch (error) {
      img = imagesByKey["noImage"];
    }
    return newFilteredDataAsHtml.push({
      key: key,
      credName: <span className="order-id">{credName}</span>,
      service: (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            style={{ marginRight: "10px" }}
            width="25px"
            height={"25px"}
            src={img}
          />
          <span>{type}</span>
        </div>
      ),
      lastUpdate: <span className="order-id">{timeAgo(lastUpdate)}</span>,
      action: (
        <div className="table-actions">
          <Popconfirm
            icon={<CloseCircleOutlined style={{ color: "#ad3278" }} />}
            placement="top"
            title="Delete this Cred"
            onConfirm={() => {
              dispatch(
                deleteCredential({
                  name: credName,
                })
              );
              setRadioGroupValue("All");
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button className="btn-icon" type="info" shape="circle">
              <FeatherIcon icon="trash-2" size={16} />
            </Button>
          </Popconfirm>
        </div>
      ),
    });
  });

  return newFilteredDataAsHtml;
};
