import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const {
  show_key_vault_modal, set_key_vault_data, is_loading_response_key_vault_data, fetch_response_key_vault_data, is_new_user,
  show_key_vault_modal_aws, set_key_vault_data_aws, is_loading_response_key_vault_data_aws, fetch_response_key_vault_data_aws, is_new_user_aws
} = actions;

const getResponseKeyVaultData = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    const email = getState().auth.email;

    dispatch(is_loading_response_key_vault_data(true));
    dispatch(fetch_response_key_vault_data(false));

    let data = JSON.stringify({
      username: email
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_UPLOAD_FILE_URL + process.env.REACT_APP__GET_KEY_VAULT_ROUTE + "getVault",
      headers: {
        Authorization: 'Bearer ' + authToken,
        "Content-Type": "application/json",
      },
      data: data
    };
    axios(config)
      .then((response) => {
        if (response.data.error) {
          dispatch(is_new_user(true));
          openNotificationWithIcon('error', 'Load Key Vault Data', response.data.error);
        }
        else {
          let { iamKeyVaultData } = convertResponse(response);
          dispatch(setKeyVaultDataAzure(iamKeyVaultData));
          openNotificationWithIcon('success', 'Load Key Vault Data', 'Key Vault Data Loaded Successfully');
          dispatch(is_new_user(false));
        };

        dispatch(is_loading_response_key_vault_data(false));
      })
      .catch((error) => {
        console.log('Key Vault Data Error', error);
        openNotificationWithIcon('error', 'Load Key Vault Data', 'Failed Loading Key Vault Data !');
        dispatch(is_loading_response_key_vault_data(false));
      });
  };
};

const saveKeyVaultDataToDBAzure = (iamKeyVaultData, onModalCancel) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    const email = getState().auth.email;
    const isNewUser = getState().keyVault.isNewUser;

    dispatch(is_loading_response_key_vault_data(true));
    dispatch(fetch_response_key_vault_data(false));

    let data = {
      "new_user": isNewUser,
      "username": email,
      "azure_credentials": {
        "azureClientId": iamKeyVaultData[0].value,
        "azureClientSecret": iamKeyVaultData[1].value,
        "azureClientTenantId": iamKeyVaultData[2].value,
        "azureClientVault": iamKeyVaultData[3].value,
      }
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_UPLOAD_FILE_URL + process.env.REACT_APP__GET_KEY_VAULT_ROUTE + 'AddKeys',
      headers: {
        Authorization: 'Bearer ' + authToken,
        "Content-Type": "application/json",
      },
      data: data
    };

    axios(config)
      .then((response) => {
        dispatch(setKeyVaultDataAzure(iamKeyVaultData));
        dispatch(is_loading_response_key_vault_data(false));
        openNotificationWithIcon('success', 'Save Key Vault Data', 'Key Vault Data saved Successfully');
        onModalCancel();
      })
      .catch((error) => {
        console.log('Key Vault Data Error', error);
        openNotificationWithIcon('error', 'Save Key Vault Data', 'Failed Saving Key Vault Data !');
        dispatch(is_loading_response_key_vault_data(false));
      });
  };
};

const convertResponse = (response) => {
  let values = response.data.secrets[0].values;

  let secrets = response.data.secrets;
  let iamKeyVaultDataLabels = ['Client Id', 'Client Secret', 'Client Tenant Id', 'Client Vault'];

  let iamKeyVaultData = [];
  secrets.forEach((secret, index) => {
    // if (index !== 0) {
    let { name, value, version } = secret;
    let label = iamKeyVaultDataLabels[index];
    iamKeyVaultData = [...iamKeyVaultData, { label, name, version, value }];
    // }
  });

  // let keyVaultAdminData = [];
  // let keyVaultAdminDataLabels = ['CLIENT_ID', 'CLIENT_SECRET', 'TENANT_ID', 'SUBSCRIPTION_ID'];
  // keyVaultAdminDataLabels.forEach((key) => {
  //   let { name, version } = values[key];
  //   keyVaultAdminData = [...keyVaultAdminData, { name, version }];
  // });
  // dispatch(setKeyVaultDataAzure(iamKeyVaultData, /* keyVaultAdminData */));

  return { iamKeyVaultData, };
};

const showKeyVaultModalToggle = (status) => {
  return (dispatch, getState) => {
    dispatch(show_key_vault_modal(status));
  };
};

const setKeyVaultDataAzure = (iamKeyVaultData) => {
  return (dispatch, getState) => {
    dispatch(set_key_vault_data(iamKeyVaultData));
  };
};


const fetchKeyVaultModalToggle = () => {
  return dispatch => {
    dispatch(fetch_response_key_vault_data(true));
  };
};




const getResponseKeyVaultDataAws = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    const email = getState().auth.email;

    dispatch(is_loading_response_key_vault_data_aws(true));
    dispatch(fetch_response_key_vault_data_aws(false));

    let data = JSON.stringify({
      secret_name: email
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_UPLOAD_FILE_URL + "/cloud/aws/secretmanager/getSecret",
      headers: {
        Authorization: 'Bearer ' + authToken,
        "Content-Type": "application/json",
      },
      data: data
    };
    axios(config)
      .then((response) => {


        if (response.data.error) {
          dispatch(is_new_user_aws(true));
          openNotificationWithIcon('error', 'Load AWS Key Vault Data', response.data.error);
        }
        else {
          let { iamKeyVaultDataAws } = convertResponseAws(response);
          dispatch(setKeyVaultDataAws(iamKeyVaultDataAws));
          openNotificationWithIcon('success', 'Load AWS Key Vault Data', 'AWS Key Vault Data Loaded Successfully');
          dispatch(is_new_user_aws(false));
        };

        dispatch(is_loading_response_key_vault_data_aws(false));
      })
      .catch((error) => {
        console.log('AWS Key Vault Data Error', error);
        openNotificationWithIcon('error', 'Load AWS Key Vault Data', 'Failed Loading AWS Key Vault Data !');
        dispatch(is_loading_response_key_vault_data_aws(false));
      });
  };
};

const saveKeyVaultDataToDBAws = (iamKeyVaultDataAws/* , keyVaultAdminData */, onModalCancel) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    const email = getState().auth.email;
    const isNewUserAws = getState().keyVault.isNewUserAws;

    dispatch(is_loading_response_key_vault_data_aws(true));
    dispatch(fetch_response_key_vault_data_aws(false));

    let data = {
      "new_user": isNewUserAws,
      "aws_secret_name_from_adminui": email,
      "aws_secret_name_iam": iamKeyVaultDataAws[3].value,
      "aws_iam_credentials": {
        "aws_access_key_id": iamKeyVaultDataAws[0].value,
        "aws_secret_access_key": iamKeyVaultDataAws[1].value,
        "aws_region_name": iamKeyVaultDataAws[2].value,
      }
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_UPLOAD_FILE_URL + "/cloud/aws/secretmanager/createOrUpdateSecret",
      headers: {
        Authorization: 'Bearer ' + authToken,
        "Content-Type": "application/json",
      },
      data: data
    };
    axios(config)
      .then((response) => {

        dispatch(setKeyVaultDataAws(iamKeyVaultDataAws, /* keyVaultAdminData */));
        dispatch(is_loading_response_key_vault_data_aws(false));
        openNotificationWithIcon('success', 'Save AWS Key Vault Data', 'AWS Key Vault Data saved Successfully');
        onModalCancel();
      })
      .catch((error) => {
        console.log('AWS Key Vault Data Error', error);
        openNotificationWithIcon('error', 'Save AWS Key Vault Data', 'Failed Saving AWS Key Vault Data !');
        dispatch(is_loading_response_key_vault_data_aws(false));
      });
  };
};

const convertResponseAws = (response) => {
  let values = response.data[0];

  // let secrets = response.data;
  let iamKeyVaultDataLabels = ['Access Key Id', 'Secret Access Key', 'Region', 'Secret Name'];

  let iamKeyVaultDataAws = [];
  Object.keys(values).forEach((secret, index) => {
    // if (index !== 0) {
    // let { value, version } = secret;
    let name = iamKeyVaultDataLabels[index];
    iamKeyVaultDataAws = [...iamKeyVaultDataAws, { label: name, name: secret, value: values[secret] }];
    // }
  });
  return { iamKeyVaultDataAws, };
};

const showKeyVaultModalToggleAws = (status) => {
  return (dispatch, getState) => {
    dispatch(show_key_vault_modal_aws(status));
  };
};

const setKeyVaultDataAws = (iamKeyVaultDataAws) => {
  return (dispatch, getState) => {
    dispatch(set_key_vault_data_aws(iamKeyVaultDataAws));
  };
};

const fetchKeyVaultModalToggleAws = () => {
  return dispatch => {
    dispatch(fetch_response_key_vault_data_aws(true));
  };
};

export {
  showKeyVaultModalToggle, setKeyVaultDataAzure, getResponseKeyVaultData, saveKeyVaultDataToDBAzure, fetchKeyVaultModalToggle,
  getResponseKeyVaultDataAws, saveKeyVaultDataToDBAws, setKeyVaultDataAws/* convertResponseAws */, showKeyVaultModalToggleAws, fetchKeyVaultModalToggleAws
};
