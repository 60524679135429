import {
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Input,
  message,
  Select,
  Switch,
  Typography
} from "antd";
import Axios from "axios";
import Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { ModalStyled } from "../../components/modals/styled";
import {
  addCredential,
  editCredential,
  setModalName,
} from "../../redux/credentials/actionCreator";
import {
  containsOnlyLettersAndNumbers,
  DropboxSignIn,
  FacebookSignIn,
  HubSpotSignIn,
  javascriptOrigins,
  JiraSignIn,
  MicrosoftSignIn,
  oAuth2RedirectUris,
  oauthSignIn,
  PipeDriveSignIn,
  QuickBooksSignIn,
  SalesForceSignIn,
  serviceFields,
  setCredOnOneLevel,
  XeroSignIn,
  ZohoSignIn,
  ZoomSignIn,
  InstagramSignIn,
  LinkedInSignIn,
} from "./credentialsData";
import { deleteAllCookiesExcept } from "./CredOAuth2";

const ErrorSpan = (error) => {
  return (
    <Alert style={{ marginTop: "10px" }} type="error" message={error} banner />
  );
};
const handleCopyClick = (label, copy) => {
  // Code to copy the text to the clipboard
  const copyText = document.getElementById(label);
  // Select the text field
  // console.log({ copyText });
  copyText.select();
  // copyText.setSelectionRange(0, 99999); // For mobile devices
  if (copy) {
    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
    if (window.getSelection) {
      const selection = window.getSelection();
      selection.removeAllRanges();
    }
    const info = () => {
      message.info("Copied");
    };
    info();
  }
};
const getFacebookPages = async (set, index, userToken, authToken, setIsLoading) => {
  try {
    setIsLoading(true)
    const response = await Axios.post(`${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/regular/facebook/getAccounts`, { userToken }, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken } })
    set((oldCred) => {
      return {
        ...oldCred, cred: oldCred.cred.map((credField, ind) => {
          if (index == ind) return {
            ...credField, list: [{
              label: "None",
              value: "None"
            }, ...response.data.map(page => ({ label: page.name, value: page.id, data: { pageID: page.id, pageAccessToken: page.accessToken } }))]
          }
          return credField
        })
      }
    })
    setIsLoading(false)
  } catch (error) {
    set((oldCred) => {
      return {
        ...oldCred, cred: oldCred.cred.map((credField, ind) => {
          if (index == ind) return {
            ...credField, Credential_value: "None", list: [{
              label: "None",
              value: "None"
            }]
          }
          return credField
        })
      }
    })
    setIsLoading(false)
    console.log({ error });
  }
}
let oldCredInfo;
const defaultCred = serviceFields.find(cred => cred.service === "Ziwo");
const credsThatHaveConsentScreenList = [
  { name: "Google", function: oauthSignIn },
  { name: "SalesForce", function: SalesForceSignIn },
  { name: "ZohoCRM", function: ZohoSignIn },
  { name: "Zoom", function: ZoomSignIn },
  { name: "HubSpot", function: HubSpotSignIn },
  { name: "Dropbox", function: DropboxSignIn },
  { name: "Microsoft", function: MicrosoftSignIn },
  { name: "Xero", function: XeroSignIn },
  { name: "PipeDrive", function: PipeDriveSignIn },
  { name: "QuickBooks", function: QuickBooksSignIn },
  { name: "Jira", function: JiraSignIn, condition: true },
  { name: "Facebook", function: FacebookSignIn, continueProcess: true },
  { name: "Instagram", function: InstagramSignIn },
  { name: "LinkedIn", function: LinkedInSignIn },

];

const credsWithImagesList = (serviceFields) => {
  return serviceFields.map((opt) => {
    let img = ""
    try {
      img = "/components-icons/" + opt.service + ".png"
    } catch (error) {
      img = "/components-icons/noImage.png";
    }
    return {
      title: opt.service,
      image: img,
    };
  })
}
const CredModal = ({ credToBeEdited, setCredToBeEdited }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("Ziwo");
  const [autoCompleteList, setAutoCompleteList] = useState(credsWithImagesList(serviceFields));
  const [credInfo, setCredInfo] = useState(defaultCred);
  const [error, setError] = useState(false);
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const [isCurrentCredOauth2, setIsCurrentCredOauth2] = useState(false);
  const { modalName, credentialsList, authToken } = useSelector((state) => {
    return {
      modalName: state.credentials.modalName,
      credentialsList: state.credentials.credentialsList,
      authToken: state.auth.token

    };
  });
  const credToContinue = Cookies.get("continueProcess")
  useEffect(() => {
    if (credToContinue) {
      dispatch(setModalName("newCred"))
      let credData = serviceFields.find(field => field.service === credToContinue)
      const credFields = credData.cred
      credData = {
        ...credData,
        Service_name: Cookies.get("name"),
        service: credToContinue,
        type: credToContinue,
        cred: credFields.map(field => {
          if (field.deleteAfterProcessEnds)
            return ({ ...field, deleteOnCreate: true, Credential_value: Cookies.get(field.Credential_name) })
          return ({ ...field, deleteOnCreate: true, Credential_value: Cookies.get(field.Credential_name) })

        })
      }
      Cookies.remove("continueProcess")
      deleteAllCookiesExcept()
      const hash = window.location.hash.substring(1);
      // Use URLSearchParams to parse the query string part
      const params = new URLSearchParams(hash);

      // Get the value of long_lived_token
      const longLivedToken = params.get('long_lived_token');
      window.history.replaceState({}, document.title, window.location.pathname);
      if (longLivedToken) {
        credData = {
          ...credData,
          CREATE: true,
          cred: [...credData.cred,
          {
            label: "Page",
            Credential_name: "pages",
            Credential_value: "None",
            getList: () => getFacebookPages(setCredInfo, 1, longLivedToken, authToken, setIsLoadingApi),
            api: true,
            value: "data",
            list: [
              {
                label: "None",
                value: "None"
              },
            ]
          },]
        }
        setCredInfo(credData)
        getFacebookPages(setCredInfo, 1, longLivedToken, authToken, setIsLoadingApi)
        setSearchText(credData.service)
      }
    };


  }, [credToContinue]);
  const onCancel = () => {
    dispatch(setModalName("None"));
    if (Cookies.get("type")) {
      deleteAllCookiesExcept();
    }
    resetCredInfo();
  };
  const handleChange = ({
    e,
    index,
    level,
    child0,
    child1,
    indexChild0,
    indexChild1,
  }) => {
    if (level == 0 || level === undefined) {
      if (index === null || index === undefined)
        setCredInfo({ ...credInfo, [e.target.id]: e.target.value });
      else
        setCredInfo({
          ...credInfo,
          cred: credInfo.cred.map((c, ind) =>
            index === ind ? { ...c, Credential_value: e.target.value } : c
          ),
        });
    } else if (level == 1) {
      setCredInfo({
        ...credInfo,
        cred: credInfo.cred.map((c, ind) => {
          if (indexChild0 === ind) {
            return {
              ...c,
              options: {
                ...c.options,
                [child0]: c.options[`${child0}`].map((ch1, indCh1) => {
                  if (indCh1 === index) {
                    return { ...ch1, Credential_value: e.target.value };
                  } else return ch1;
                }),
              },
            };
          } else {
            return c;
          }
        }),
      });
    } else if (level === 2) {
      setCredInfo({
        ...credInfo,
        cred: credInfo.cred.map((c, ind) => {
          if (indexChild0 === ind) {
            return {
              ...c,
              options: {
                ...c.options,
                [child0]: c.options[`${child0}`].map((ch1, indCh1) => {
                  if (indCh1 === indexChild1) {
                    return {
                      ...ch1,
                      options: {
                        ...ch1.options,
                        [child1]: ch1.options[`${child1}`].map(
                          (ch1, indCh1) => {
                            if (indCh1 === index) {
                              return {
                                ...ch1,
                                Credential_value: e.target.value,
                              };
                            } else return ch1;
                          }
                        ),
                      },
                    };
                  } else return ch1;
                }),
              },
            };
          } else {
            return c;
          }
        }),
      });
    }
  };
  const resetCredInfo = () => {
    setCredInfo({
      service: "Ziwo",
      Service_name: "",
      cred: [
        {
          label: "Base URL",
          Credential_name: "BaseURL",
          Credential_value: "",
        },
        {
          label: "User Name",
          Credential_name: "UserName",
          Credential_value: "",
        },
        {
          label: "Password",
          Credential_name: "Password",
          Credential_value: "",
          hashed: true,
        },
      ],
    });
    setError(false);
    setSearchText("Ziwo");
    setAutoCompleteList(credsWithImagesList(serviceFields))
    oldCredInfo = {};
    if (modalName === "editCred") {
      setCredToBeEdited((prev) => null);
      oldCredInfo = {};
    }
  };
  const onOkAddOrEditCred = () => {
    let newCredInfo = credInfo;
    newCredInfo = { ...newCredInfo, cred: setCredOnOneLevel(credInfo.cred) };
    if (error) setError(false);
    let errorNum = null;
    if (
      newCredInfo.Service_name.trim().length > 0 &&
      newCredInfo.service.trim().length > 0 &&
      searchText.trim()
    ) {
      newCredInfo.cred.forEach((c) => {
        if (c.Credential_value.toString().trim().length < 3 && !c.optional) errorNum = `Credential fields should be at least 3 characters.`;
        else if (c.api && c.Credential_value === "None") errorNum = `Please choose a ${c.label}`
      });
      if (errorNum)
        return setError(errorNum);
      const oAuth2Cred = newCredInfo.cred.find(credField => credField.credTypeConnection)
      const currentCredConnectionTypeOauth = oAuth2Cred && oAuth2Cred.Credential_value === "OAuth Application"
      newCredInfo = {
        ...newCredInfo, cred: newCredInfo.cred.filter(f => {
          return !(f.optional && f.dropdown ? f.Credential_value == "None" : typeof f.Credential_value === "string" ? f.Credential_value.trim().length === 0 || f.credTypeConnection : false)
        }
        )
      }
      const credNameAndFunctionForShowingConsentScreen = credsThatHaveConsentScreenList.find(
        (c) => c.name === newCredInfo.service
      );
      if (modalName === "editCred") {
        if ((credNameAndFunctionForShowingConsentScreen && !credNameAndFunctionForShowingConsentScreen?.condition) || currentCredConnectionTypeOauth) {
          Cookies.set("type", newCredInfo.service);
          Cookies.set("new_user", credentialsList.length > 0 ? false : true);
          Cookies.set("isEditting", modalName);
          Cookies.set(
            "random_nb",
            newCredInfo.cred[0].Credential_name.split("---")[1]
          );
          Cookies.set("oldCred", JSON.stringify(oldCredInfo));
          newCredInfo.cred.forEach((c) => {
            Cookies.set(
              `${c.Credential_name.split("-")[0]}`,
              c.Credential_value
            );
          });
          Cookies.set("name", newCredInfo.Service_name);
          setTimeout(
            () => credNameAndFunctionForShowingConsentScreen.function(),
            100
          );
        } else {
          dispatch(
            editCredential({
              username: Cookies.get("email"),
              new_user: credentialsList.length > 0 ? false : true,
              credInfo: {
                ...newCredInfo,
                cred: newCredInfo.cred.map((obj) => {
                  // Create a copy of the object without the specified key
                  const newObj = { ...obj };
                  delete newObj["hashed"];
                  delete newObj["label"];
                  delete newObj["switch"];
                  delete newObj["dropdown"];
                  delete newObj["placeholder"];


                  return newObj;
                }),
              },
              oldCredInfo,
            })
          );
        }
        resetCredInfo();
        dispatch(setModalName("None"));
      } else {
        if (
          credentialsList.find(
            (c) => c.credName === newCredInfo.Service_name
          )
        ) {
          return setError("Credential name already exists.");
        }
        if (!containsOnlyLettersAndNumbers(newCredInfo.Service_name)) {
          return setError(
            "The Name should only consist of letters and numbers, without any special characters or spaces."
          );
        } else {
          if (!newCredInfo.CREATE && (credNameAndFunctionForShowingConsentScreen && !credNameAndFunctionForShowingConsentScreen?.condition) || currentCredConnectionTypeOauth) {
            if (credNameAndFunctionForShowingConsentScreen.continueProcess) {
              Cookies.set("continueProcess", newCredInfo.service)
            }
            Cookies.set("type", newCredInfo.service);
            Cookies.set("isEditting", modalName);
            newCredInfo.cred.forEach((c) => {
              Cookies.set(`${c.Credential_name}`, c.Credential_value);
            });
            Cookies.set("new_user", credentialsList.length > 0 ? false : true);
            Cookies.set("name", newCredInfo.Service_name);
            setTimeout(
              () => credNameAndFunctionForShowingConsentScreen.function(),
              100
            );
          } else {
            dispatch(
              addCredential({
                username: Cookies.get("email"),
                new_user: credentialsList.length > 0 ? false : true,
                credType: credInfo.service,
                credInfo: {
                  ...newCredInfo,
                  cred: newCredInfo.cred.map((element) => {
                    return { Credential_name: element.Credential_name, Credential_value: element.Credential_value };
                  }),
                },
              })
            );
            resetCredInfo();
            dispatch(setModalName("None"));
          }
        }
      }
    } else {
      setError("Please fill all the fields");
    }
  };
  const handleSearch = (searchText, isSelected) => {
    const newFilteredData = serviceFields.filter((value) =>
      value.service.toUpperCase().startsWith(searchText.toUpperCase())
    );
    const title = credsWithImagesList(newFilteredData)
    setAutoCompleteList(title);
    setSearchText(searchText);
    if (isSelected && credInfo.service !== searchText) {
      const newCred = serviceFields.find((s) => s.service === searchText)
      setCredInfo({
        ...newCred, Service_name: credInfo.Service_name
      });
      const currentCredType = newCred.cred.find(credField => credField.credTypeConnection)
      setIsCurrentCredOauth2(currentCredType && currentCredType.Credential_value === "OAuth Application")
    }
  };
  const auto = ({ credInfo, modalName, level: level = 0, ...rest }) => {
    return credInfo?.map((c, index) => {
      return (
        <Fragment key={index}>
          <Typography style={{ margin: "10px auto" }}>{`${c.label}`}<span style={{ marginLeft: "5px", fontSize: "smaller", color: "#a7a7a7" }}>{c.optional ? "(Optional)" : ""}</span></Typography>
          {c.hashed ? (
            <Input.Password
              className="form-control"
              placeholder={c.label}
              value={c.Credential_value}
              id={
                modalName === "editCred"
                  ? c.Credential_name.split("-")[0]
                  : c.Credential_name
              }
              onChange={(e) => handleChange({ e, index, level, ...rest })}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          ) : c.switch ? (
            <Switch
              id={
                modalName === "editCred"
                  ? c.Credential_name.split("-")[0]
                  : c.Credential_name
              }
              disabled={modalName === "editCred"}
              onChange={(c) =>
                handleChange({
                  e: {
                    target: {
                      id:
                        modalName === "editCred"
                          ? c.Credential_name.split("-")[0]
                          : c.Credential_name,
                      value: c,
                    },
                  },
                  index,
                  level,
                  ...rest,
                })
              }
              checked={c.Credential_value}
            />
          ) : c.api ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> <Select
              className="form-control"
              style={{ width: "100%" }}
              value={c.Credential_value}
              onChange={(e) => {
                if (c.credTypeConnection) {
                  setIsCurrentCredOauth2(e === "OAuth Application")
                }
                handleChange({
                  e: {
                    target: {
                      id:
                        modalName === "editCred"
                          ? c.Credential_name.split("-")[0]
                          : c.Credential_name,
                      value: e,
                    },
                  },
                  index,
                  level,
                  ...rest,
                })
              }
              }
              id={
                modalName === "editCred"
                  ? c.Credential_name.split("-")[0]
                  : c.Credential_name
              }
              options={c.list}
              disabled={modalName === "editCred"}
            />
              <LoadingButton
                toggleCondition={isLoadingApi}
                onClick={() => c.getList()}
              /></div>
          ) : c.dropdown ? (
            <Select
              className="form-control"
              style={{ width: "100%" }}
              value={c.Credential_value}
              onChange={(e) => {
                if (c.credTypeConnection) {
                  setIsCurrentCredOauth2(e === "OAuth Application")
                }
                handleChange({
                  e: {
                    target: {
                      id:
                        modalName === "editCred"
                          ? c.Credential_name.split("-")[0]
                          : c.Credential_name,
                      value: e,
                    },
                  },
                  index,
                  level,
                  ...rest,
                })
              }
              }
              id={
                modalName === "editCred"
                  ? c.Credential_name.split("-")[0]
                  : c.Credential_name
              }
              options={c.list}
              disabled={modalName === "editCred"}
            />
          ) : (
            <Input
              type="text"
              className="form-control"
              placeholder={c.placeholder || c.label}
              value={c.Credential_value}
              id={
                modalName === "editCred"
                  ? c.Credential_name.split("-")[0]
                  : c.Credential_name
              }
              onChange={(e) => {
                handleChange({ e, index, level, ...rest });
              }}
            ></Input>
          )}
          {c.options &&
            c.options.hasOwnProperty(c.Credential_value.toString()) &&
            auto({
              credInfo: c.options[c.Credential_value.toString()],
              modalName,
              level: level + 1,
              [`child${level}`]: c.Credential_value,
              [`indexChild${level}`]: index,
              ...rest,
            })}
        </Fragment>
      );
    });
  };
  return (
    <>
      <ModalStyled
        title={`${modalName === "newCred" ? "Add new" : "Edit"} Credential`}
        visible={modalName === "newCred" || modalName === "editCred"}
        onOk={onOkAddOrEditCred}
        onCancel={onCancel}
        bodyStyle={{ maxHeight: "55vh", overflow: "auto" }}
      >
        <div>
          <Typography style={{ margin: "10px auto" }}>Name</Typography>
          <Input
            type="text"
            className="form-control"
            disabled={modalName === "editCred"}
            placeholder="Name"
            value={credInfo.Service_name}
            id="Service_name"
            onChange={(e) => {
              handleChange({
                e: { target: { id: e.target.id, value: e.target.value } },
              });
            }}
          ></Input>

          <Typography style={{ margin: "10px auto" }}>
            Service or app to connect to
          </Typography>
          <div style={{ marginBottom: "20px" }}>
            <AutoComplete
              id="autoComplete"

              onSearch={(event) => {
                handleSearch(event, false);
              }} //event here is the input value.
              onSelect={(event, option) => {
                handleSearch(event, true);
              }} //event here is the input value.
              disabled={modalName === "editCred"}
              onClick={(event) => {
                handleCopyClick("autoComplete");
              }}
              onClear={() =>
                handleChange({ e: { target: { id: "Service_name", value: "" } } })

              }
              onBlur={(e) => {
                if (!autoCompleteList.find(item => item.title === searchText))
                  setSearchText(credInfo.service)
              }}
              dataSource={autoCompleteList}
              value={searchText}
              placeholder="Search here"
              width="100%"
            />
          </div>
          {auto({ credInfo: credInfo.cred, modalName })}
          {javascriptOrigins.type.find((f) => f === credInfo.service) && <CopyFields field={javascriptOrigins} />}
          {(oAuth2RedirectUris.type.find((f) => f === credInfo.service) || isCurrentCredOauth2) && <CopyFields field={oAuth2RedirectUris} />}
          {error && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {ErrorSpan(error)}
            </div>
          )}
        </div>
      </ModalStyled>
    </>
  );
};

export default CredModal;

const CopyFields = ({ field }) => {
  return (<Fragment>
    <Typography style={{ margin: "10px auto" }}>
      {field.label}
    </Typography>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative"
      }}
    >
      <Input
        id={field.label}
        value={field.value}
        // disabled
        readOnly
        onClick={() => handleCopyClick(field.label, true)}
        style={{
          paddingRight: "30px",
          background: "#f5f5f5",
          cursor: "pointer",
          color: "darkgray",
        }} // Adjust the padding to make room for the icon
      />
      <Button
        icon={<CopyOutlined />}
        onClick={() => handleCopyClick(field.label, true)}
        style={{
          position: "absolute",
          // right: '8px',
          // top: '50%',
          transform: "translateX(-40%)",
        }}
      />
    </div>
  </Fragment>
  )
}
