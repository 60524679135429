import { Alert, Empty, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cards } from "../../components/cards/frame/cards-frame";
import {
    ChartjsStackedChart
} from "../../components/charts/chartjs";
import Heading from "../../components/heading/heading";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { CardBarChart } from "../../container/dashboard/DashboardComponents/style";
import { getTop10TokenUsage } from "../../redux/token-usage/actionCreator";
import { getDate } from "../new-reporting/reportingData";
import { addDays } from "date-fns";
import { CalendarButtonPageHeader } from "../../components/buttons/calendar-button/calendar-button";


const initialState = {
    datePickerInternational: null,
    dateRangePicker: {
        selection: {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: "selection",
        },
    },
};
const Top10TokenUsage = () => {
    const dispatch = useDispatch();
    const { isLoadingTop10TokenUsage, top10TokenUsageList } = useSelector(state => {
        return {
            isLoadingTop10TokenUsage: state.tokenUsage.isLoadingTop10TokenUsage,
            top10TokenUsageList: state.tokenUsage.top10TokenUsageList,
        };
    });
    const [dateRange, setDateRange] = useState(initialState);
    const [appliedDateRange, setAppliedDateRange] = useState(initialState);
    const [calendarVisible, setCalendarVisible] = useState(false);

    const onSelectChange = (selectedRowKey) => {
        setDateRange({ ...dateRange, selectedRowKeys: selectedRowKey });
    };
    const handleRangeChange = (which) => {
        setDateRange({
            ...dateRange,
            dateRangePicker: {
                ...dateRange.dateRangePicker,
                ...which,
            },
        });
    };
    const onApply = () => {
        setAppliedDateRange(dateRange);
        dispatch(
            getTop10TokenUsage(
                {
                    start_date: getDate(dateRange.dateRangePicker.selection.startDate),
                    end_date: getDate(dateRange.dateRangePicker.selection.endDate),
                }
            )
        );
        setCalendarVisible(false);
    };
    useEffect(() => {
        dispatch(getTop10TokenUsage({
            start_date: getDate(dateRange.dateRangePicker.selection.startDate),
            end_date: getDate(dateRange.dateRangePicker.selection.endDate),

        }))
    }, []);// eslint-disable-line
    const onCancel = () => { };
    function clearFilteringInputs() {
    }
    const labels = Object.keys(top10TokenUsageList);

    const providers = {};
    labels.forEach(label => {
        top10TokenUsageList[label].models.forEach(model => {
            if (!providers[model.provider]) {
                providers[model.provider] = Array(labels.length).fill(0);
            }
        });
    });

    labels.forEach((label, index) => {
        top10TokenUsageList[label].models.forEach(model => {
            providers[model.provider][index] += model.tokenUsage;
        });
    });

    const datasets = Object.keys(providers).map(provider => ({
        label: provider,
        data: providers[provider],
        backgroundColor: getBackgroundColor(provider)
    }));
    function getBackgroundColor(provider) {
        const colors = {
            openAi: '#72AFDD',
            ollama: '#FF8C42',
            anthropic: '#FF7043',
            awsBedrock: '#FFCA28',
            googlePaLMGemini: '#66BB6A',
            azureOpenAi: '#42A5F5',
            mistralAi: '#AB47BC',
            cohere: '#8D6E63',
            togetherAi: '#FFA726',
            huggingFace: '#F06292',
            vertexAi: '#26A69A',
        };
        return colors[provider] || '#000000';
    }
    return (
        <>

            <Cards
                isbutton={<div className="card-nav"><LoadingButton
                    toggleCondition={isLoadingTop10TokenUsage}
                    onClick={() => {
                        dispatch(getTop10TokenUsage({
                            start_date: getDate(dateRange.dateRangePicker.selection.startDate),
                            end_date: getDate(dateRange.dateRangePicker.selection.endDate),

                        }))
                    }}
                /></div>}
                title="Top 10 Flows Token Usage"
                size="large"
            >
                <CalendarButtonPageHeader
                    visible={calendarVisible}
                    setVisible={setCalendarVisible}
                    key="1"
                    onApply={onApply}
                    onCancel={onCancel}
                    state={dateRange}
                    setState={setDateRange}
                    onSelectChange={onSelectChange}
                    handleRangeChange={handleRangeChange}
                    appliedDateRange={appliedDateRange}
                />
                {isLoadingTop10TokenUsage ? (
                    <div className="sd-spin">
                        <Spin />
                    </div>
                ) :
                    labels.length == 0 ? <>
                        <Empty />
                        <Alert type="info" showIcon
                            style={{ background: "#2c99ff15", width: "55%", margin: "15px auto 0px auto" }}
                            message={"Ensure the date is valid."} />
                    </> :
                        top10TokenUsageList !== null && (<CardBarChart>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ marginTop: "5px" }} className="card-bar-top">
                                <p>Token</p>
                                <Heading as="h3">
                                    {Object.keys(top10TokenUsageList).reduce(
                                        (accumulator, currentValue) => {
                                            return accumulator + top10TokenUsageList[currentValue].totalTokenUsage
                                        },
                                        0
                                    )}
                                </Heading>
                            </div>
                            </div>

                            <ChartjsStackedChart
                                {...{
                                    height: 200,
                                    labels: labels,
                                    datasets: datasets,
                                    options: {
                                        maintainAspectRatio: true,
                                        responsive: true,
                                        scales: {
                                            yAxes: [
                                                {
                                                    stacked: true,
                                                    gridLines: {
                                                        color: '#e5e9f2',
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        fontSize: 16,
                                                        fontColor: '#182b49',
                                                    },
                                                }
                                            ],
                                            xAxes: [
                                                {
                                                    stacked: true,
                                                    gridLines: {
                                                        display: false,
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        fontSize: 16,
                                                        fontColor: '#182b49',
                                                    },
                                                }
                                            ]
                                        },
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                fontSize: 16,
                                                fontColor: '#182b49',
                                            },
                                        },
                                        tooltips: {
                                            mode: 'index',
                                            intersect: false,
                                        },
                                    }
                                }}
                            />
                        </CardBarChart>)}
            </Cards>

        </>
    );
};

export default Top10TokenUsage;
