import actions from './actions';

const { IS_LOADING_RPAS, FETCH_RPAS, SET_RPAS, SHOW_DELETE_MODAL } = actions;

const initState = {
  rpasList: [],
  fetchRPAs: true,
  isLoadingRPAs: true,
  showDeleteModal: false,

};

/**
 *
 * @todo impure state mutation/explaination
 */
const rpasReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case IS_LOADING_RPAS:
      return {
        ...state,
        isLoadingRPAs: status,
      };
    case FETCH_RPAS:
      return {
        ...state,
        fetchRPAs: status,
      };
    case SET_RPAS:
      data.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
      return {
        ...state,
        rpasList: data,
      };
    case SHOW_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: status,
      };
    default:
      return state;
  }
};

export default rpasReducer;