import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { is_loading_bots, fetch_bots, set_bots, set_selected_type } = actions;

const getBots = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_bots(true));
    dispatch(fetch_bots(false));

    var config = {
      method: 'get',
      url: process.env.REACT_APP_CARDS_URL + "configuration/getConfigurationlist",
      headers: { Authorization: "Bearer " + authToken },
    };

    axios(config)
      .then((response) => {
        dispatch(setBots(response.data));
        openNotificationWithIcon('success', 'Load Bots', 'Bots Loaded successfully');
        dispatch(is_loading_bots(false));
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Load Bots', "Failed Loading Bots");
        console.log('Load Bots Error', error);
        dispatch(is_loading_bots(false));
      });
  };
};

const fetchBotsToggle = () => {
  return dispatch => {
    dispatch(fetch_bots(true));
  };
};

const setBots = (newList) => {
  return dispatch => {
    dispatch(set_bots(newList));
  };
};

const deleteBotByID = (id, clearFilteringInputs) => {
  return async (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_bots(true));

    let config = {
      method: 'delete',
      url: 'https://flowapi-ae.ziwo.io/config/configuration/deleteConfiguration/' + id,
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Deleting Bot", "Bot Deleted Successfully");
        dispatch(deleteRPAByIDLocally(id));
        dispatch(is_loading_bots(false));
        clearFilteringInputs();
      })
      .catch(function (error) {
        openNotificationWithIcon("error", "Deleting Bot", "Deleting Bot Was Not Successfully !");
        console.log('Deleting Bot Error', error);
        dispatch(is_loading_bots(false));
      });
  };
};

const deleteRPAByIDLocally = (id) => {
  return (dispatch, getState) => {
    const { botsList } = getState().chatbots;
    let newBotList = [...botsList];

    newBotList = newBotList.filter(bot => bot.id !== id);
    dispatch(set_bots(newBotList));
  };
};

const changeBotsType = (type) => {
  return (dispatch, getState) => {
    dispatch(set_selected_type(type));
  };
};

export { getBots, fetchBotsToggle, setBots, deleteBotByID, changeBotsType };
