import React, { useEffect } from 'react'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Button, Card, Col, Empty, Pagination, Popconfirm, Popover, Row, Tooltip, Typography } from 'antd'
import { useHistory, useParams } from "react-router-dom";
import { Cards } from '../../components/cards/frame/cards-frame';
import FeatherIcon from "feather-icons-react";
import classes from "./style.module.css"
import { useState } from 'react';
import InsertDataModal from './insertDataModal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDocByID, fetchDocsToggle, getDocs } from '../../redux/data-set/actionCreator';
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { LoadingButton } from '../../components/loading-button/loading-button';
import { Main, TopToolBox } from '../styled';
import { useLocation } from 'react-router-dom';
import { CloseCircleOutlined } from "@ant-design/icons";
import { ButtonFlowCardStyled } from '../../components/buttons/styled';
import { timeAgo } from '../credentials/TableRow';

function truncateString(inputString, maxLength) {
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.substring(0, maxLength) + "...";
    }
}
const DataSetDetails = () => {
    const history = useHistory()
    const { indexName } = useParams();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [autoCompleteList, setAutoCompleteList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch()
    const location = useLocation();

    const { isLoadingDocs, fetchDocs, docsList, isLoadingGetDocs } = useSelector(state => {
        return {
            isLoadingGetDocs: state.dataSet.isLoadingGetDocs,
            isLoadingDocs: state.dataSet.isLoadingDocs,
            fetchDocs: state.dataSet.fetchDocs,
            docsList: state.dataSet.docsList,
        };
    });
    const { elm } = location.state || {};

    useEffect(() => {
        dispatch(getDocs({
            index_name: indexName
        }));
    }, [indexName])
    useEffect(() => {
        if (fetchDocs) {
            // dispatch(getDocs({
            //     index_name: indexName
            // }));
        }
        else {
            setFilteredData(docsList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
        }
    }, [fetchDocs, docsList, indexName]);// eslint-disable-line
    const handleSearch = (searchText) => {
        const data = docsList.filter((value) =>
            value.description.toUpperCase().startsWith(searchText.toUpperCase())
        );
        // setFilteredData(data);
        setFilteredData(data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));

        const title = data.map((e) => {
            return {
                title: e.description,
            };
        });
        setAutoCompleteList(title);
        setSearchText(searchText);
    };
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const itemsPerPage = 6;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const totalItems = filteredData ? filteredData.length : 0;
    return (
        <>
            <InsertDataModal elm={elm} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            <PageHeader ghost title={`Index: ${indexName} / Documents`} />
            <Main>
                <Cards headless>
                    <Row style={{ margin: "5px" }} >
                        <Col span={12}>
                            <Button type="primary"
                                className={classes.goBackButton}
                                onClick={() => history.goBack()}
                            >
                                <FeatherIcon icon="arrow-left" size={12} />
                                {"Go Back"}
                            </Button>
                        </Col>
                        <Col className={classes.end} span={12}  >
                            <Button type="primary"
                                disabled={isLoadingDocs}
                                className={classes.goBackButton}
                                onClick={() => { setModalIsOpen(true) }}
                            >
                                <FeatherIcon icon="plus" size={12} />
                                {"Insert Data"}
                            </Button>
                        </Col>
                    </Row>
                    <TopToolBox>
                        <Row>
                            <Col span={20} style={{ display: "flex" }}>
                                <div className="table-search-box">
                                    <AutoComplete
                                        onSearch={event => handleSearch(event)} //event here is the input value.
                                        onSelect={event => handleSearch(event)} //event here is the input value.
                                        onClear={() => setSearchText("")}
                                        dataSource={autoCompleteList}
                                        value={searchText}
                                        placeholder="Search here"
                                        patterns
                                    />
                                </div>
                                <Popover placement="bottomRight" content={"Refresh Docs"} action="hover">
                                    <LoadingButton
                                        toggleCondition={isLoadingDocs}
                                        onClick={() => dispatch(getDocs({
                                            index_name: indexName
                                        }))}
                                    />
                                </Popover>
                            </Col>
                            <Col style={{ marginRight: "10px" }} span={2} align="end" ><b>Total:&nbsp;</b>{!isLoadingGetDocs ? filteredData.length : 0}</Col>

                        </Row>
                    </TopToolBox>
                    {elm && <>
                        <Col span={24}>
                            <Typography><b>Provider: </b>{elm.provider}</Typography>
                        </Col>
                        <Col span={24}>
                            <Typography><b>Model: </b>{elm.model}</Typography>
                        </Col>
                    </>}
                </Cards >
                <Row gutter={[15, 15]}>
                    {
                        !isLoadingGetDocs && filteredData && filteredData
                            .slice(startIndex, endIndex).map((elm, index) => {
                                const { description, created_at, source } = elm
                                return (
                                    <Col key={index} xs={24} lg={12} xxl={8}>
                                        <Card
                                            hoverable={false}
                                            className={classes.detailsCard} >
                                            <Row align='end'>
                                                <Typography.Text type="secondary">{timeAgo(created_at)}</Typography.Text>
                                            </Row>

                                            <Tooltip
                                                color={"#F0FBFF"}
                                                title={
                                                    description.length > 30 &&
                                                    <div>
                                                        {description}
                                                    </div>}
                                                placement="top">
                                                <Typography><b>Description: </b>{truncateString(description, 30)}</Typography>
                                            </Tooltip>
                                            <Tooltip
                                                style={{ display: "block" }}
                                                color={"#F0FBFF"}
                                                title={
                                                    source.length > 30 &&
                                                    <div>
                                                        {source}
                                                    </div>}
                                                placement="top">
                                                <Typography><b>Source: </b>{truncateString(source, 30)}</Typography>
                                            </Tooltip>
                                            <Row align='end'>
                                                <Popconfirm
                                                    icon={
                                                        <CloseCircleOutlined style={{ color: "#72AFDD" }} />
                                                    }
                                                    placement="top"
                                                    title={`Delete This Doc?`}
                                                    onConfirm={(e) => {
                                                        e.stopPropagation()
                                                        dispatch(deleteDocByID({
                                                            index_name: indexName,
                                                            source: source
                                                        }))
                                                    }}
                                                    onCancel={(e) => {
                                                        e.stopPropagation()
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <ButtonFlowCardStyled
                                                        style={{ border: "none", boxShadow: "none" }}
                                                        className="btn-icon" type="info" shape="circle">
                                                        <FeatherIcon
                                                            style={{ width: "24px", height: "24px" }}
                                                            icon="trash-2" />
                                                    </ButtonFlowCardStyled>
                                                </Popconfirm>
                                            </Row>
                                        </Card>
                                    </Col>
                                )
                            })
                    }

                </Row>

                <Row align="end" style={{ marginTop: "20px", marginBottom: "10px" }}>
                    {!isLoadingGetDocs && totalItems > itemsPerPage && (
                        <Pagination
                            style={{ marginTop: '16px', textAlign: 'center' }}
                            defaultCurrent={1}
                            total={totalItems}
                            pageSize={itemsPerPage}
                            showSizeChanger={false}
                            onChange={onPageChange}
                        />
                    )}
                </Row>
            </Main>
        </>
    )
}

export default DataSetDetails