import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { is_loading_adaptive_cards, fetch_adaptive_cards, set_adaptive_cards, is_editing_adaptive_cards } = actions;

const getAdaptiveCards = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_adaptive_cards(true));
    dispatch(fetch_adaptive_cards(false));

    let config = {
      method: "get",
      url: process.env.REACT_APP_CARDS_URL + "card/get_cards",
      headers: { Authorization: "Bearer " + authToken },
    };

    axios(config)
      .then((response) => {
        dispatch(setAdaptiveCards(response.data));
        openNotificationWithIcon('success', 'Load Adaptive Cards', 'Adaptive Cards Loaded Successfully');
        dispatch(is_loading_adaptive_cards(false));
      })
      .catch((error) => {
        console.log('Adaptive Cards Error', error);
        openNotificationWithIcon('error', 'Load Adaptive Cards', 'Failed Loading Adaptive Cards !');
        dispatch(is_loading_adaptive_cards(false));
      });
  };
};

const fetchAdaptiveCardsToggle = () => {
  return dispatch => {
    dispatch(fetch_adaptive_cards(true));
  };
};

const setAdaptiveCards = (newList) => {
  return dispatch => {
    dispatch(set_adaptive_cards(newList));
  };
};

const deleteAdaptiveCardByName = (cardname, clearFilteringInputs) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_adaptive_cards(true));
    let config = {
      method: "delete",
      url: process.env.REACT_APP_CARDS_URL + "card/delete_card/" + cardname,
      headers: { Authorization: "Bearer " + authToken },
    };

    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Deleting Card", "Card Deleted Successfully");
        dispatch(deleteAdaptiveCardByNameLocally(cardname));
        dispatch(is_loading_adaptive_cards(false));
        clearFilteringInputs();
      })
      .catch(function (error) {
        openNotificationWithIcon("error", "Deleting Card", "Deleting Card Was Not Successfully !");
        dispatch(is_loading_adaptive_cards(false));
        console.log(error);
      });
  };
};

const saveOrUpdateAdaptiveCard = (newAdaptiveCard, variables, handleCancelButtonClicked) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_adaptive_cards(true));
    const { isEditingAdaptiveCard } = getState().adaptiveCards;
    const { cardid, cardname, cardDetails } = newAdaptiveCard;
    // let newCardPayload = { ...newBody, version: version };

    let newCard = {
      card_type: "adaptiveCard",
      name: cardname.trim(),
      card: cardDetails,
      variables: variables && variables.length > 0 ? variables : null,
    };

    if (isEditingAdaptiveCard) {
      newCard = {
        ...newCard,
        // id: cardItem.cardid
        id: cardid
      };
    }

    let config = isEditingAdaptiveCard
      ? {
        method: "put",
        url: process.env.REACT_APP_CARDS_URL + "card/update/",
        data: newCard,
        headers: {
          Authorization: "Bearer " + authToken,
        },
      }
      : {
        method: "post",
        url: process.env.REACT_APP_CARDS_URL + "card/add_card/",
        data: newCard,
        headers: {
          Authorization: "Bearer " + authToken,
        },
      };


    axios(config)
      .then((response) => {
        openNotificationWithIcon("success", "Saving Card", "Card Saved Successfully");
        handleCancelButtonClicked();
        dispatch(fetch_adaptive_cards(true))
        let { card_id } = response.data;
        isEditingAdaptiveCard
          ? dispatch(updateAdaptiveCardLocally({ ...newAdaptiveCard, variables }))
          : dispatch(addAdaptiveCardLocally({ ...newAdaptiveCard, variables, cardid: card_id }));
        dispatch(is_loading_adaptive_cards(false));
        dispatch(isEditingAdaptiveCardToggled(false));
      })
      .catch((error) => {
        console.log("Saving Card Error", error);
        openNotificationWithIcon("error", "Saving Card", "Failed Saving Card !");
        dispatch(is_loading_adaptive_cards(false));
      });
  };
};

const addAdaptiveCardLocally = (newAdaptiveCard) => {
  return (dispatch, getState) => {
    const { adaptiveCardsList } = getState().adaptiveCards;
    let newAdaptiveCardsList = [...adaptiveCardsList];

    newAdaptiveCardsList = [...newAdaptiveCardsList, newAdaptiveCard];
    dispatch(set_adaptive_cards(newAdaptiveCardsList));
  };
};


const updateAdaptiveCardLocally = (newAdaptiveCard) => {
  return (dispatch, getState) => {

    const { cardid } = newAdaptiveCard;
    const { adaptiveCardsList } = getState().adaptiveCards;
    let newAdaptiveCardsList = [...adaptiveCardsList];
    let newCardIndex = newAdaptiveCardsList.findIndex(card => card.cardid === cardid);
    newAdaptiveCardsList[newCardIndex] = newAdaptiveCard;

    dispatch(set_adaptive_cards(newAdaptiveCardsList));
  };
};

const deleteAdaptiveCardByNameLocally = (cardname) => {
  return (dispatch, getState) => {
    const { adaptiveCardsList } = getState().adaptiveCards;
    let newAdaptiveCardsList = [...adaptiveCardsList];

    newAdaptiveCardsList = newAdaptiveCardsList.filter(card => card.cardname !== cardname);
    dispatch(set_adaptive_cards(newAdaptiveCardsList));
  };
};

const isEditingAdaptiveCardToggled = (data) => {
  return (dispatch, getState) => {
    dispatch(is_editing_adaptive_cards(data));
  };
};



export { getAdaptiveCards, fetchAdaptiveCardsToggle, setAdaptiveCards, deleteAdaptiveCardByName, saveOrUpdateAdaptiveCard, isEditingAdaptiveCardToggled };
