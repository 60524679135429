import { EyeInvisibleOutlined, EyeTwoTone, } from "@ant-design/icons"
import { Alert, Input, Typography } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ModalStyled } from '../../components/modals/styled'
import { addUserToDB } from '../../redux/users/actionCreator'
import { validateEmail, validatePassword } from "../../functions"

const AddUserModal = ({ modalToOpen, setModalToOpen }) => {
    const [addUserData, setAddUserData] = useState({ name: "", email: "", password: "", instance_name: "" })
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const onCancel = () => {
        setModalToOpen("")
        setAddUserData({ name: "", email: "", password: "", instance_name: "" })
        setError(false)
    }
    const onOk = () => {
        const { name, email, password, instance_name } = addUserData
        const result = validatePassword(password.trim());

        if (!name.trim() || !email.trim() || !password.trim() || !instance_name.trim()) {
            return setError(["Please fill all the required fields !"])
        }
        else if(!validateEmail(email.trim())){
            return setError(["Please fill a valid email"]);
        }
        else if (!result.isValid) {
            console.log("Password is invalid");
            return setError(result.errors);
        }
        else {
            setIsLoading(true)
            dispatch(addUserToDB(addUserData)).then(res=>{
                if(res){
                    console.log("User added successfully");
                    setModalToOpen("")
                    setAddUserData({ name: "", email: "", password: "", instance_name: "" })
                    setIsLoading(false)
                }else{
                    console.log("Failed to add user");
                    setError(["Failed to add user try again"])
                    setIsLoading(false)
                }
               
            });
        }
    }
    const onChange = (name, value) => {
        setAddUserData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    return (
        <ModalStyled
            title={"Add New User"}
            visible={modalToOpen === "addUserModal"}
            onOk={onOk}
            confirmLoading={isLoading}
            onCancel={onCancel}
            bodyStyle={{ maxHeight: "55vh", overflow: "auto" }}
        >

            <Typography>Name</Typography>
            <Input
                style={{ marginTop: "10px" }}
                type="text"
                className="form-control"
                placeholder="name"
                value={addUserData.name}
                id="name"
                onChange={(e) => {
                    onChange("name", e.target.value)
                    // if (name.trim()) { setError(false) }
                }}
            ></Input>
            <Typography
                style={{ marginTop: "10px" }}>
                Email
            </Typography>
            <Input
                type="text"
                className="form-control"
                placeholder="email@gmail.com"
                value={addUserData.email}
                id="email"
                onChange={(e) => {
                    onChange("email", e.target.value)
                }}
            ></Input>
            <Typography
                style={{ marginTop: "10px" }}>
                Password
            </Typography>
            <Input.Password
                className="form-control"
                placeholder="password"
                value={addUserData.password}
                id="password"
                onChange={(e) => {
                    onChange("password", e.target.value)
                }}
                iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
            />
            <p style={{ fontSize: "0.7rem", color: "#6799fd",marginTop:"3px",marginLeft:"3px" }}>Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character</p>
            <Typography
                style={{ marginTop: "10px" }}>
                Instance Name
            </Typography>
            <Input
                type="text"
                className="form-control"
                placeholder="instane name"
                value={addUserData.instance_name}
                id="instance_name"
                onChange={(e) => {
                    onChange("instance_name", e.target.value)
                }}
            ></Input>
            {error &&
                <Alert style={{ marginTop: "10px",whiteSpace:"pre-line" }} type="error" message={error.join("\n")} banner />}
        </ModalStyled>
    )
}

export default AddUserModal