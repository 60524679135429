import { Col, Popconfirm, Row, Table } from "antd";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { Button } from "../../components/buttons/buttons";
import { Cards } from "../../components/cards/frame/cards-frame";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Popover } from "../../components/popup/popup";
import { Main, TableWrapper, TopToolBox } from "../styled";
import { CloseCircleOutlined } from "@ant-design/icons";
import { deleteTagByID, fetchTagsToggle, getTags } from "../../redux/tags/actionCreator";
import AddTagModal from "./AddTagModal";
import { timeAgo } from "../credentials/TableRow";

const Tags = () => {
    const dispatch = useDispatch();


    const [searchText, setSearchText] = useState("");
    const [autoCompleteList, setAutoCompleteList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const { isLoadingTags, fetchTags, tagsList } = useSelector(state => {
        return {
            isLoadingTags: state.tags.isLoadingTags,
            fetchTags: state.tags.fetchTags,
            tagsList: state.tags.tagsList,
        };
    });

    useEffect(() => {
        if (fetchTags) {
            dispatch(getTags());
            clearFilteringInputs();
        }
        else {
            setFilteredData(tagsList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));

        }
    }, [fetchTags, tagsList]);// eslint-disable-line

    const handleSearch = (searchText) => {
        const data = tagsList.filter((value) =>
            value.name.toUpperCase().startsWith(searchText.toUpperCase())
        );
        setFilteredData(data);
        const title = data.map((e) => {
            return {
                title: e.name,
            };
        });
        setAutoCompleteList(title);
        setSearchText(searchText);
    };

    function clearFilteringInputs() {
        setSearchText('');
        setAutoCompleteList([]);
    }

    const convertFilteredDataToHTML = () => {
        const newFilteredDataAsHtml = [];

        filteredData.map((value, key) => {
            const { name, id, number_of_flows, created_at } = value;
            return newFilteredDataAsHtml.push({
                key: key,
                tagName: <span className="order-id">{name}</span>,
                numberOfFlows: <span className="order-id">{number_of_flows} Workflow</span>,
                createdAt: <span className="order-id">{timeAgo(created_at)}</span>,
                action: (
                    <div className="table-actions">
                        <>
                            <Popconfirm
                                icon={
                                    <CloseCircleOutlined style={{ color: "#ad3278" }} />
                                }
                                placement="top"
                                title="Delete this tag"
                                onConfirm={() => {
                                    dispatch(deleteTagByID(id, clearFilteringInputs))
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className="btn-icon"
                                    type="info"
                                    shape="circle"
                                >
                                    <FeatherIcon icon="trash-2" size={16} />
                                </Button>
                            </Popconfirm>
                        </>
                    </div>
                ),
            });
        });

        return newFilteredDataAsHtml;
    }

    const columns = [
        { title: "Tag Name", dataIndex: "tagName" },
        { title: "Usage", dataIndex: "numberOfFlows" },
        { title: "Last Update", dataIndex: "createdAt" },
        { title: "Action", dataIndex: "action" },
    ];

    let filteredDataAsHtml = convertFilteredDataToHTML();

    return (
        <>
            <PageHeader ghost title="Tags" />
            <Main>
                <AddTagModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                <Cards headless>
                    <Row gutter={15}>
                        <Col xs={24}>
                            <TopToolBox>
                                <Row gutter={15} className="justify-content-center">
                                    <Col span={10}>
                                        <div className="table-search-box">
                                            <AutoComplete
                                                onSearch={event => handleSearch(event)} //event here is the input value.
                                                onSelect={event => handleSearch(event)} //event here is the input value.
                                                onClear={() => setSearchText("")}
                                                dataSource={autoCompleteList}
                                                value={searchText}
                                                placeholder="Search here"
                                                width="100%"
                                                patterns
                                            />
                                        </div>
                                    </Col>
                                    <Col span={2}>
                                        <Popover placement="bottomRight" content={"Refresh Tags"} action="hover">
                                            <LoadingButton
                                                toggleCondition={isLoadingTags}
                                                onClick={() => dispatch(fetchTagsToggle())}
                                            />
                                        </Popover>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right', display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                        <Button style={{ display: "flex", padding: "15px 12px" }} size="small" type="primary"
                                            disabled={isLoadingTags}
                                            onClick={() => setModalIsOpen(true)}
                                        >
                                            <FeatherIcon icon="plus" size={12} />
                                            {"Add Tag"}
                                        </Button>
                                    </Col>
                                </Row>
                            </TopToolBox>
                        </Col>
                    </Row>
                    <Row style={{ margin: "10px" }} align="end"><b>Total:&nbsp;</b>{filteredData.length}</Row>
                    <Row gutter={15}>
                        <Col md={24}>
                            <TableWrapper className="table-order table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={filteredDataAsHtml}
                                    pagination={{ pageSize: 6, showSizeChanger: false, total: filteredDataAsHtml.length, }}
                                />
                            </TableWrapper>
                        </Col>
                    </Row>
                </Cards>
            </Main>
        </>
    );
};

export default Tags;