import React, { useEffect } from 'react'
import { ModalStyled } from '../../components/modals/styled'
import { Alert, Input, Select, Typography } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeInstanceName, changePassword, changeRole, changeStatus } from '../../redux/users/actionCreator'
import { EyeInvisibleOutlined, EyeTwoTone, } from "@ant-design/icons";
import { validatePassword } from '../../functions'
const UsersModal = ({ modalToOpen, setModalToOpen, cardData }) => {
    const [data, setData] = useState({ role: cardData.role, status: cardData.status, password: "", instance_name: cardData.ziwo_instance_name })
    // const [data, setData] = useState({ role: "", status: "", password: "", instance_name: "" })
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const onCancel = () => {
        setError(false)
        setModalToOpen("")
        setData({ role: cardData.role, status: cardData.status, password: "", instance_name: cardData.ziwo_instance_name })
    }
    const modalData = {
        "changeRoleModal": {
            title: "Change Role",
            alertMessage: "Changing the status to Admin will grant this user access to the Super Admin dashboard.",
            fn: async () => {
                return dispatch(changeRole({
                    user_id: cardData.id,
                    role: data.role
                }))
            }
        },
        "changePasswordModal": {
            title: "Change Password",
            fn: () => {
                return dispatch(changePassword({
                    user_id: cardData.id,
                    password: data.password.trim()
                }))
            }
        },
        "changeInstanceModal": {
            title: "Change Instance Name",
            fn: () => {
                return dispatch(changeInstanceName({
                    user_id: cardData.id,
                    instance_name: data.instance_name.trim()
                }))
            }
        },
        "changeStatusModal": {
            title: "Change Status",
            alertMessage: "Deactivating a user's status will deactivate all their workflows and remove their access from the workflow (no data will be deleted from their account).",
            fn: () => {
                return dispatch(changeStatus({
                    user_id: cardData.id,
                    status: data.status
                }))
            }
        }
    }
    useEffect(() => {
        setData({ role: cardData.role, status: cardData.status, password: "", instance_name: cardData.ziwo_instance_name })
    }, [cardData])
    const onOk = () => {
        const result = validatePassword(data.password.trim());

        if (modalToOpen === "changePasswordModal" && !result.isValid) {
            console.log("Password is invalid");
            return setError(result.errors);
        }
        else if (modalToOpen === "changeInstanceModal" && !data.instance_name.trim()) {
            setError(["Please fill all the required fields !"])
        }
        else {
            setIsLoading(true)
            modalData[modalToOpen]?.fn().then(res => {
                setIsLoading(false)
                if (res) {
                    setModalToOpen("")
                    setData({ role: cardData.role, status: cardData.status, password: "", instance_name: cardData.ziwo_instance_name })
                    setError(false)
                }
                else{
                    setError(["Failed to change data. Please try again later!"])
                }
            })
        }
    }
    const onChange = (name, value) => {
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    return (
        <ModalStyled
            title={modalData[modalToOpen]?.title}
            visible={modalToOpen.trim() && modalToOpen !== 'addUserModal'}
            onOk={onOk}
            onCancel={onCancel}
            confirmLoading={isLoading}
            bodyStyle={{ maxHeight: "55vh", overflow: "auto" }}
        >
            {modalToOpen === "changeRoleModal" && <>
                <Alert style={{ fontSize: '0.75rem', marginBottom: "10px" }} message={modalData[modalToOpen]?.alertMessage} type="info" showIcon />
                <Typography>Role</Typography>
                <Select
                    style={{ width: "100%" }}
                    className="form-control"
                    value={data.role}
                    defaultValue={data.role}
                    id="role"
                    onChange={(e) => {
                        onChange("role", e)
                    }}
                    options={[
                        { option: "Admin", value: "Admin" },
                        { option: "Member", value: "Member" }
                    ]}
                />
            </>}
            {
                modalToOpen === "changeStatusModal" &&
                <>
                    <Alert style={{ fontSize: '0.75rem', marginBottom: "10px" }} message={modalData[modalToOpen]?.alertMessage} type="info" showIcon />
                    <Typography>Status</Typography>
                    <Select
                        style={{ width: "100%" }}
                        className="form-control"
                        value={data.status}
                        defaultValue={data.status}
                        id="status"
                        onChange={(e) => {
                            onChange("status", e)
                        }}
                        options={[
                            { option: "Active", value: "Active" },
                            { option: "InActive", value: "InActive" }
                        ]}
                    />
                </>
            }
            {
                modalToOpen === "changeInstanceModal" &&
                <>
                    <Typography>Instance Name</Typography>
                    <Input
                        type="text"
                        className="form-control"
                        placeholder="instane name"
                        value={data.instance_name}
                        defaultValue={data.instance_name}
                        id="instance_name"
                        onChange={(e) => {
                            onChange("instance_name", e.target.value)
                        }}
                    ></Input>
                </>
            }
            {
                modalToOpen === "changePasswordModal" &&
                <>
                    <Typography>Password</Typography>
                    <Input.Password
                        // type="password"
                        className="form-control"
                        placeholder="password"
                        value={data.password}
                        id="password"
                        onChange={(e) => {
                            onChange("password", e.target.value)
                        }}
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                    <p style={{ fontSize: "0.7rem", color: "#6799fd", marginTop: "3px", marginLeft: "3px" }}>Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character</p>

                </>
            }
            {error &&
                <Alert style={{ marginTop: "10px" ,whiteSpace:"pre-line"}} type="error" message={error.join("\n")} banner />}

        </ModalStyled>
    )
}

export default UsersModal