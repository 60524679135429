import { Col, Radio, Row, Skeleton, Table, Tooltip, Typography } from "antd";
import { addDays } from "date-fns";
import FeatherIcon from "feather-icons-react";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { Button } from "../../components/buttons/buttons";
import { CalendarButtonPageHeader } from "../../components/buttons/calendar-button/calendar-button";
import { Cards } from "../../components/cards/frame/cards-frame";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Popover } from "../../components/popup/popup";
import {
  fetchReportingToggle,
  getReporting,
  setShowModal,
} from "../../redux/reporting/actionCreator";
import { Main, TableWrapper, TopToolBox } from "../styled";
import SearchByNodeType from "./SearchByNodeType";
import TopFail from "./TopFail";
import TopPass from "./TopPass";
import "./reporting.css";
import { formatDate, getDate, initialOptions } from "./reportingData";
import ReportingModal from "./reportingModal";
function removeDuplicatesByKey(array, key) {
  const seen = new Set();
  return array.filter(obj => {
      if (!seen.has(obj[key])) {
          seen.add(obj[key]);
          return true;
      }
      return false;
  });
}
const columns = [
  { title: "Flow Name", dataIndex: "name" },
  { title: "Node Count", dataIndex: "nodeCount" },
  { title: "Date", dataIndex: "date" },
  { title: "Status", dataIndex: "resultStatus" },
  { title: "Run Type", dataIndex: "runType" },
  { title: "Results", dataIndex: "action" },
];
const initialState = {
  datePickerInternational: null,
  dateRangePicker: {
    selection: {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  },
};
const Logs = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [radioGroupValue, setRadioGroupValue] = useState("All");
  const [runTyperadioGroupValue, setRunTypeRadioGroupValue] = useState("All");
  const [filterByTypeObject, setFilterByTypeObject] = useState({ All: true });
  const [flowDetails, setFlowDetails] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [dateRange, setDateRange] = useState(initialState);
  const [appliedDateRange, setAppliedDateRange] = useState(initialState);
  const [nodesList, setNodesList] = useState(initialOptions);
  const [searchValueForNodesFilter, setSearchValueForNodesFilter] = useState(
    ""
  );
  let autoCompleteList = [];
  const flowStatusFilterKey = ["All", "PASS", "FAIL"];
  const runTypeFilterKey = ["All", "TRIGGERED", "TESTING"];
  const { isLoadingReporting, fetchReporting, reportingList } = useSelector(
    (state) => {
      return {
        isLoadingReporting: state.reporting.isLoadingReporting,
        fetchReporting: state.reporting.fetchReporting,
        reportingList: state.reporting.reportingList,
      };
    }
  );
  useEffect(() => {
    if (fetchReporting) {
      clearFilteringInputs();
      dispatch(
        getReporting(
          getDate(dateRange.dateRangePicker.selection.startDate),
          getDate(dateRange.dateRangePicker.selection.endDate)
        )
      );
    }
  }, [fetchReporting]); // eslint-disable-line
  const onSelectChange = (selectedRowKey) => {
    setDateRange({ ...dateRange, selectedRowKeys: selectedRowKey });
  };
  const handleRangeChange = (which) => {
    setDateRange({
      ...dateRange,
      dateRangePicker: {
        ...dateRange.dateRangePicker,
        ...which,
      },
    });
  };
  const handleSearch = (searchText) => {
    setSearchText(searchText);
  };
  const handleChangeForFilterFlowStatus = (event) => {
    let { value } = event.target;
    setRadioGroupValue(value);
  };
  function clearFilteringInputs() {
    setSearchText("");
    setRadioGroupValue("All");
    setFilterByTypeObject({ All: true });
    setRunTypeRadioGroupValue("All");
    setNodesList(initialOptions);
    setSearchValueForNodesFilter("");
  }
  const checkIfAppExistsInFlow = (flow) => {
    if (Object.keys(flow.End).length < Object.keys(filterByTypeObject))
      return false;
    let found = false;
    for (let key in filterByTypeObject) {
      for (let node in flow.End) {
        if (node.replace(/\d/g, "") === key) {
          found = true;
          break;
        } else {
          found = false;
        }
      }
      if (!found) break;
    }
    if (found) return true;
    else return false;
  };
  const filterByAllApps = () =>
    reportingList.filter((flow) => {
      const { flow_name, Status, date, error, source } = flow;
      if (
        (Status === radioGroupValue || radioGroupValue === "All") &&
        ((source &&
          source.toUpperCase() === runTyperadioGroupValue.toUpperCase()) ||
          runTyperadioGroupValue === "All")
      ) {
        if (searchText.trim().length > 0) {
          if (flow_name.toUpperCase().startsWith(searchText.toUpperCase())) {
            autoCompleteList.push({ title: flow_name });
            return true;
          }
          return false;
        }
        autoCompleteList.push({ title: flow_name });
        return true;
      }
      return false;
    });
  const filterBySpecifcApps = () =>
    reportingList.filter((flow) => {
      const { flow_name, Status, date, error, source } = flow;
      if (
        flow.hasOwnProperty("End") &&
        (Status === radioGroupValue || radioGroupValue === "All") &&
        ((source &&
          source.toUpperCase() === runTyperadioGroupValue.toUpperCase()) ||
          runTyperadioGroupValue === "All")
      ) {
        if (searchText.trim().length > 0) {
          if (flow_name.toUpperCase().startsWith(searchText.toUpperCase())) {
            const flowPassedFilterCheck = checkIfAppExistsInFlow(flow);
            if (flowPassedFilterCheck) {
              autoCompleteList.push({ title: flow_name });
              return true;
            }
            return false;
          }
          return false;
        }
        const flowPassedFilterCheck = checkIfAppExistsInFlow(flow);
        if (flowPassedFilterCheck) {
          autoCompleteList.push({ title: flow_name });
          return true;
        }
        return false;
      }
      return false;
    });
  const getFilteredData = () =>
    filterByTypeObject.hasOwnProperty("All")
      ? filterByAllApps()
      : filterBySpecifcApps();
  const filteredData = getFilteredData();
  const showNodesImages = (value) => {
    let nodesToShowList = [];
    const nodesList = Object.keys(value.End);
    if (value.End)
      for (const [index, nodeName] of nodesList.entries()) {
        if (nodeName !== "error" &&nodeName!=="flow_status"&& index < 3) {
          nodesToShowList.push(
            <img
              style={{ marginRight: "10px" }}
              width="20px"
              height={"20px"}
              src={`/components-icons/${nodeName.replace(/\d/g, "")}.png`}
            />
          );
        } else break;
      }
    return nodesToShowList;
  };
  const convertFilteredDataToHTML = () => {
    const newFilteredDataAsHtml = [];
    filteredData.map((value, key) => {
      const { flow_name, Status, date, error, source } = value;
      {
        return newFilteredDataAsHtml.push({
          key: key,
          name: (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span>{flow_name}</span>

              <span style={{ marginLeft: "10px" }}>
                {showNodesImages(value)}
              </span>
              <span>
                {" "}
                {value?.End && Object.keys(value.End).length > 3
                  ? "..."
                  : null || null}
              </span>
            </div>
          ),
          nodeCount: (
            <span className="order-id">
              {(value?.End && Object.keys(value.End).length) || null}
            </span>
          ),
          date: <span className="order-id">{formatDate(date)}</span>,
          resultStatus:
            Status === "FAIL" ? (
              <span className={`status ${"error"}`}>{Status}</span>
            ) : Status === "PASS" ? (
              <span className={`status ${"Success"}`}>{Status}</span>
            ) : (
              <span className={`status ${"error"}`}>{Status || "Error"}</span>
            ),
          runType: (
            <span className={`status`}>{source?.toUpperCase() || "-"}</span>
          ),
          action: (
            <div className="table-actions">
              {error ? (
                <Popover
                  placement="topRight"
                  content={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FeatherIcon
                        icon="x"
                        style={{ color: "red", margin: "4px" }}
                      />
                      <Typography>{error || value.End.error}</Typography>
                    </div>
                  }
                  action="click"
                >
                  <Button
                    className="btn-icon"
                    type="info"
                    shape="circle"
                    onClick={() => {}}
                  >
                    <FeatherIcon icon="x-circle" />
                  </Button>
                </Popover>
              ) : (
                <Button
                  className="btn-icon"
                  type="info"
                  shape="circle"
                  onClick={() => {
                    setFlowDetails(value.End);
                    dispatch(setShowModal(true));
                  }}
                >
                  <FeatherIcon icon="maximize-2" />
                </Button>
              )}
            </div>
          ),
        });
      }
    });
    return newFilteredDataAsHtml;
  };

  const onApply = () => {
    setAppliedDateRange(dateRange)
    clearFilteringInputs()
    dispatch(
      getReporting(
        getDate(dateRange.dateRangePicker.selection.startDate),
        getDate(dateRange.dateRangePicker.selection.endDate)
      )
    );
    setCalendarVisible(false);
  };
  const onCancel = () => {};
  const onChangeFilterByType = (newValue) => {
    setFilterByTypeObject(
      Object.keys(newValue).length == 0 ? { All: true } : newValue
    );
  };
  const handleChangeFilter = (event) => {
    setSearchValueForNodesFilter(event.target.value);
    const newFilteredData = initialOptions.filter((elt) =>
      elt.toUpperCase().startsWith(event.target.value.toUpperCase())
    );
    setNodesList(newFilteredData);
  };
  const filteredDataAsHtml = convertFilteredDataToHTML();
  return (
    <>
      {flowDetails && (
        <ReportingModal
          flowDetails={flowDetails}
          setFlowDetails={setFlowDetails}
        />
      )}
      <PageHeader ghost title="Logs" />{" "}
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={24}>
              <TopToolBox>
                <Row gutter={15} className="justify-content-center">
                  <Col span={8}>
                    <div className="table-search-box">
                      <AutoComplete
                        onSearch={(event) => handleSearch(event)} //event here is the input value.
                        onSelect={(event) => handleSearch(event)} //event here is the input value.
                        onClear={() => setSearchText("")}
                        dataSource={removeDuplicatesByKey(autoCompleteList,"title")}
                        value={searchText}
                        placeholder="Search here"
                        width="100%"
                        patterns
                      />
                    </div>
                  </Col>
                  <Col span={3}>
                    <Popover
                      placement="bottomRight"
                      content={"Refresh Flows"}
                      action="hover"
                    >
                      <LoadingButton
                        toggleCondition={isLoadingReporting}
                        onClick={() => dispatch(fetchReportingToggle())}
                      />
                    </Popover>
                  </Col>

                  <Col
                    span={13}
                    style={{
                      textAlign: "right",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      // alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span>Total: {filteredData.length}</span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <div>
                        <span
                          style={{ fontSize: "1em" }}
                          className={`status ${"Success"}`}
                        >
                          PASS:
                        </span>
                        <span>
                          {
                            filteredData.filter(
                              (flow) => flow.Status === "PASS"
                            ).length
                          }
                        </span>
                      </div>
                      <div>
                        <span
                          style={{ fontSize: "1em" }}
                          className={`status ${"error"}`}
                        >
                          FAIL:
                        </span>
                        <span>
                          {
                            filteredData.filter(
                              (flow) => flow.Status === "FAIL"
                            ).length
                          }
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row gutter={15} className="justify-content-center">
                  <Col span={24}>
                    <div key="1" className="page-header-actions">
                      <Tooltip title="Filter by Flow Status">
                        <Radio.Group
                          onChange={handleChangeForFilterFlowStatus}
                          value={radioGroupValue}
                          disabled={isLoadingReporting}
                          style={{ margin: "6px" }}
                        >
                          {flowStatusFilterKey.map((value) => {
                            return (
                              <Radio.Button
                                style={{ height: "32px", lineHeight: "34px" }}
                                key={value}
                                value={value}
                              >
                                {value}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </Tooltip>
                      <CalendarButtonPageHeader
                        visible={calendarVisible}
                        setVisible={setCalendarVisible}
                        key="1"
                        onApply={onApply}
                        onCancel={onCancel}
                        state={dateRange}
                        setState={setDateRange}
                        onSelectChange={onSelectChange}
                        handleRangeChange={handleRangeChange}
                        appliedDateRange={appliedDateRange}
                      />
                      <SearchByNodeType
                        handleChangeFilter={handleChangeFilter}
                        searchvalue={searchValueForNodesFilter}
                        setSearchValue={setSearchValueForNodesFilter}
                        onChange={onChangeFilterByType}
                        value={filterByTypeObject}
                        options={nodesList}
                        setOptions={setNodesList}
                      />
                      <Tooltip title="Filter by Run Type">
                        <Radio.Group
                          onChange={(e) =>
                            setRunTypeRadioGroupValue(e.target.value)
                          }
                          value={runTyperadioGroupValue}
                          disabled={isLoadingReporting}
                          style={{ margin: "6px" }}
                        >
                          {runTypeFilterKey.map((value) => {
                            return (
                              <Radio.Button
                                style={{ height: "32px", lineHeight: "34px" }}
                                key={value}
                                value={value}
                              >
                                {value}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              </TopToolBox>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper
                className="table-order table-responsive"
                style={{ background: "" }}
              >
                <Table
                  columns={columns}
                  dataSource={filteredDataAsHtml}
                  pagination={{
                    pageSize: 5,
                    showSizeChanger: false,
                    total: filteredDataAsHtml.length,
                  }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};
export default Logs;
