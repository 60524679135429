import React from "react";
import { Menu } from "antd";
import { NavLink, Link, useRouteMatch } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { changeBotsType } from "../redux/chatbots/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import Image from "../components/image/image";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { decrypt } from "../container/profile/authentication/overview/util";
// import { HiOutlineDocumentReport } from "react-icons/hi";
// import { BsCardText } from "react-icons/bs";
const { SubMenu } = Menu;

const SideMenu = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory()
  const location = useLocation()
  const pathName = window.location.pathname;
  const pathArray = pathName.toLocaleLowerCase().split(path.toLocaleLowerCase());
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );



  const { userList, firstElement } = useSelector(state => {
    return {
      userList: state.userDetails.userList,
      firstElement: state.userDetails.firstElement,
    };
  });












  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  // console.log("ssssssssssss", path, pathArray, pathName, mainPathSplit, openKeys, firstElement, !topMenu
  //   ? [
  //     `${mainPathSplit.length === 1
  //       ? "dashboard"
  //       : mainPathSplit.length === 2
  //         ? mainPathSplit[1]
  //         : mainPathSplit[2]
  //     }`,
  //   ]
  //   : [], !topMenu
  //   ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
  //   : []);
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode && "dark"}
      style={{marginTop:"25px"}}
      // selectedKeys={["credentials"]}
      defaultSelectedKeys={
        !topMenu
          ? [
            `${(mainPathSplit.length === 1 && path === "/dashboard") ?
              // ? userType === "superAdministrator" ? "users" : 
              "dashboard" : (mainPathSplit.length === 1 && path === "/superAdmin") ?
                "users"
                : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {
        // userType !== "superAdministrator" &&
        path === "/dashboard" &&
        <>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/credentials`}
                // to={`${path}/rpas`}
                >
                  <FeatherIcon icon="lock" />
                </NavLink>
              )
            }
            key="credentials" onClick={() => { history.push(`${path}/credentials`) }}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/credentials`}
            //  to={`${path}/rpas`}
            >
              {'Credentials'}
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink  className="menuItem-iocn" to={`${path}/deployments`} >
              <FeatherIcon icon="server" />
            </NavLink>
          )
        }
        key="deployments"
      >
        <NavLink  onClick={toggleCollapsed} to={`${path}/deployments`} >
          {'Deployments'}
        </NavLink>
      </Menu.Item> */}


          {/* {Object.keys(userList).find(x => x === "Deployments") &&
        < SubMenu 
          key="deployments"
          icon={!topMenu && <FeatherIcon icon="server" />}
          title="Deployments"
        >
          <Menu.Item key="newDelloyment">
            <Link
              onClick={() => { toggleCollapsed(); }}
              to={`${path}/deployments`}
            >
              <Image src={require(`../static/img/bots/WebColored.png`)} alt={""} />
              {"Deployments"}
            </Link>
          </Menu.Item>
          <Menu.Item key="newTransactAssist">
            <Link
              onClick={() => { toggleCollapsed(); }}
              to={`${path}/transact`}
            >
              <Image src={require(`../static/img/bots/TeamsColored.png`)} alt={""} />
              {"Transact Assist"}
            </Link>
          </Menu.Item>

        </SubMenu>
      } */}


          {Object.keys(userList).find(x => x === "Stories") &&

            <Menu.Item
              style={{ color: "white" }} icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/stories`}
                  // to={`${path}/rpas`}
                  >
                    <FeatherIcon icon="book-open" />
                  </NavLink>
                )
              }
              key="stories"
              onClick={() => { history.push(`${path}/stories`) }}
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/stories`}

              //  to={`${path}/rpas`}

              >
                {'Stories'}
              </NavLink>
            </Menu.Item>

          }
          {Object.keys(userList).find(x => x === "Flows") &&

            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}`} /* to={`${path}/rpas`} */>
                    <FeatherIcon icon="share-2" />
                  </NavLink>
                )
              }
              key="dashboard"
              onClick={() => { history.push(`${path}`) }}
            >
              <NavLink onClick={toggleCollapsed} to={`${path}`} /* to={`${path}/rpas`} */>
                {'Flows'}
              </NavLink>
            </Menu.Item>
          }
       
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/data-set`}>
              <FeatherIcon icon="database" />
            </NavLink>
          )
        }
        key="data-set"
        onClick={() => { history.push(`${path}/data-set`) }}

      >
        <NavLink onClick={toggleCollapsed} to={`${path}/data-set`}>
          {"Vector DataBasse"}
        </NavLink>
      </Menu.Item> */}
      {Object.keys(userList).find(x => x === "Chatbots") &&

        <SubMenu
          key="chatbot"
          icon={!topMenu && <FeatherIcon icon="meh" />}
          title="Chatbots"
        >
          {/* <Menu.Item key="newWebBot">
            <Link
              onClick={() => { toggleCollapsed(); dispatch(changeBotsType("Web")); }}
              to={`${path}/chatbot`}
            >
              <Image src={require(`../static/img/bots/WebColored.png`)} alt={""} />
              {"Web Bots"}
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item key="newTeamsBot">
            <Link
              onClick={() => { toggleCollapsed(); dispatch(changeBotsType("Teams")); }}
              to={`${path}/chatbot`}
            >
              <Image src={require(`../static/img/bots/TeamsColored.png`)} alt={""} />
              {"Teams Bots"}
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item key="newSlackBot">
            <Link
              onClick={() => { toggleCollapsed(); dispatch(changeBotsType("Slack")); }}
              to={`${path}/chatbot`}
            >
              <Image src={require(`../static/img/bots/SlackColored.png`)} alt={""} />
              {"Slack Bots"}
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item key="newWorkplaceBot">
            <Link
              onClick={() => { toggleCollapsed(); dispatch(changeBotsType("Workplace")); }}
              to={`${path}/chatbot`}
            >
              <Image src={require(`../static/img/bots/WorkplaceColored.png`)} alt={""} />
              {"Workplace Bots"}
            </Link>
          </Menu.Item> */}
              <Menu.Item key="newWhatsAppBot">
                <Link
                  onClick={() => { toggleCollapsed(); dispatch(changeBotsType("Whatsapp")); }}
                  to={`${path}/chatbot`}
                >
                  <Image src={require(`../static/img/bots/WhatsappColored.png`)} alt={""} />
                  {"Whatsapp Bots"}
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="newFacebookBot">
            <Link
              onClick={() => { toggleCollapsed(); dispatch(changeBotsType("Facebook")); }}
              to={`${path}/chatbot`}
            >
              <Image src={require(`../static/img/bots/FacebookColored.png`)} alt={""} />
              {"Facebook Bots"}
            </Link>
          </Menu.Item> */}
        </SubMenu>
      }
      {/* {Object.keys(userList).find(x => x === "Adaptive Cards") &&

        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/cards`}>
                <FeatherIcon icon="credit-card" />
              </NavLink>
            )
          }
          key="cards"
          onClick={() => { history.push(`${path}/cards`) }}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/cards`}>
            {"Adaptive Cards"}
          </NavLink>
        </Menu.Item>
      } */}
      {/* {Object.keys(userList).find(x => x === "Whatsapp Cards") && */}

      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/whatsapp-cards`}>
              <FeatherIcon icon="square" />
            </NavLink>
          )
        }
        key="whatsapp-cards"
        onClick={() => { history.push(`${path}/whatsapp-cards`) }}
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/whatsapp-cards`}>
          {"Whatsapp Cards"}
        </NavLink>
      </Menu.Item> */}
      {/* } */}
      {/* {Object.keys(userList).find(x => x === "Knowledge AI") &&

        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/knowledge-ai`}>
                <FeatherIcon icon="layers" />
              </NavLink>
            </Menu.Item>
          }

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/whatsapp-cards`}>
                  {/* <BsCardText /> */}
                  {/* <FeatherIcon icon="square" />
                </NavLink>
              )
            }
            key="whatsapp-cards"
            onClick={() => { history.push(`${path}/whatsapp-cards`) }}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/whatsapp-cards`}>
              {"Whatsapp Cards"}
            </NavLink>
          </Menu.Item> */}
          {/* {Object.keys(userList).find(x => x === "Knowledge AI") &&

            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/knowledge-ai`}>
                    <FeatherIcon icon="layers" />
                  </NavLink>
                )
              }
              key="knowledge-ai"
              onClick={() => { history.push(`${path}/knowledge-ai`) }}

            >
              <NavLink onClick={toggleCollapsed} to={`${path}/knowledge-ai`}>
                {"Knowledge AI"}
              </NavLink>
            </Menu.Item>
          } */}
          {/* {Object.keys(userList).find(x => x === "Whatsapp Cards") && */}
          <SubMenu
            key="reporting"
            icon={!topMenu && <FeatherIcon icon="file-text" />}
            title="Reporting"
          >
            <Menu.Item
              key="logs">
              <Link
                onClick={toggleCollapsed}
                to={`${path}/reporting/logs`}
              >
                <FeatherIcon icon="database" style={{ marginRight: "10px" }} />
                {"Logs"}
              </Link>
            </Menu.Item>
            <Menu.Item
              key="metrics"
            >
              <Link
                onClick={toggleCollapsed}
                to={`${path}/reporting/metrics`}
              >
                <FeatherIcon icon="bar-chart-2" style={{ marginRight: "10px" }} />
                {"Metrics"}
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/tags`}>
                  <FeatherIcon icon="tag" />
                </NavLink>
              )
            }
            key="tags"
            onClick={() => { history.push(`${path}/tags`) }}

          >
            <NavLink onClick={toggleCollapsed} to={`${path}/tags`}>
              {"Tags"}
            </NavLink>
          </Menu.Item>
          {/* } */}
          {/* <SubMenu
        key="reporting"
        icon={!topMenu && <FeatherIcon icon="pie-chart" />}
        title="Reporting"
      >
        <Menu.Item key="messages">
          <NavLink 
            onClick={toggleCollapsed}
            to={`${path}/reporting/messages`}
          >
            {"Messages"}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="chatbot-success-rate">
          <NavLink 
            onClick={toggleCollapsed}
            to={`${path}/reporting/chatbot-success-rate`}
          >
            {"Chatbot Success Rate"}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="live">
          <NavLink 
            onClick={toggleCollapsed}
            to={`${path}/reporting/live`}
          >
            {"Live"}
          </NavLink>
        </Menu.Item>
      </SubMenu> */}
        </>
      }
      {
        
        path === "/superAdmin" &&
        <>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}`}>
                  <FeatherIcon icon="user" />
                </NavLink>
              )
            }
            key="users"
            onClick={() => { history.push(`${path}`) }}

          >
            <NavLink onClick={toggleCollapsed} to={`${path}`}>
              {"Users"}
            </NavLink>
          </Menu.Item>
        </>}

     
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/token-usage`}>
              <FeatherIcon icon="pie-chart" />
            </NavLink>
          )
        }
        key="token-usage"
        onClick={() => { history.push(`${path}/token-usage`) }}

      >
        <NavLink onClick={toggleCollapsed} to={`${path}/token-usage`}>
          {"Token Usage"}
        </NavLink>
      </Menu.Item> */}

    </Menu >
  );
};

SideMenu.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default SideMenu;
