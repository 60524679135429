import Cookies from "js-cookie";
import axios from "axios";
import { addCredential } from "../../redux/credentials/actionCreator";
import openNotificationWithIcon from "../../components/notification/notification";
import { OAuth2ServiceTypes } from "./OAuth2FlowServicesList";
function formatDateWithMilliseconds(expiryInSeconds) {
  const now = new Date();
  const expirationDate = new Date(now.getTime() + expiryInSeconds * 1000); // Convert seconds to milliseconds

  const year = expirationDate.getUTCFullYear();
  const month = String(expirationDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(expirationDate.getUTCDate()).padStart(2, "0");
  const hours = String(expirationDate.getUTCHours()).padStart(2, "0");
  const minutes = String(expirationDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(expirationDate.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(expirationDate.getUTCMilliseconds()).padStart(
    3,
    "0"
  );

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  return formattedDate.toString();
}
function BufferValue(value) {
  return Buffer.from(value).toString("base64");
}
const myFunctions = {
  formatDateWithMilliseconds: formatDateWithMilliseconds,
  Buffer: BufferValue,
};
function isSnakeCase(str) {
  // Check if the string contains only lowercase letters and underscores
  return /^[a-z_]+$/.test(str);
}

function snakeToCamel(str) {
  return str.replace(/_([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
}
function replaceValuesInString(inputString) {
  // Define a regular expression pattern to match values inside {{ }}
  var pattern = /\{\{([^{}]+)\}\}/g;

  // Use replace() with a callback function to replace the matched values
  var result = inputString.replace(pattern, function (match, captureGroup) {
    // Replace the matched value with the new value
    const isCustomPath = captureGroup.includes("::");
    const valuePathList = captureGroup.split("::");
    if (isCustomPath) {
      if (valuePathList[1].trim() === "params") {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get(valuePathList[0].trim());
        return code;
      }
    } else {
      return Cookies.get(captureGroup);
    }

    return;
  });
  return result;
}
function replaceValues(inputObject) {
  // Iterate over the keys of the input object
  for (var key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      // If the value is an object, recursively call replaceValues
      if (typeof inputObject[key] === "object") {
        inputObject[key] = replaceValues(inputObject[key]);
      } else {
        // If the value matches the pattern {{key}}, replace it with the corresponding value from referenceObject
        var pattern = /\{\{([^{}]+)\}\}/g;
        var match = pattern.exec(inputObject[key]);
        if (match) {
          var referenceKey = match[1];
          const isCustomPath = referenceKey.includes("::");
          const valuePathList = referenceKey.split("::");
          if (isCustomPath) {
            if (valuePathList[1].trim() === "params") {
              const urlParams = new URLSearchParams(window.location.search);
              const code = urlParams.get(valuePathList[0].trim());
              inputObject[key] = code;
            }
          } else {
            inputObject[key] = Cookies.get(referenceKey);
          }
        }
      }
    }
  }
  return inputObject;
}

const getResponseValues = (response, response_path) => {
  const response_path_list = response_path.split(".");
  let res = response;
  for (let key of response_path_list) {
    res = res[key];
  }
  return res;
};
const reformatName = (name) => {
  if (isSnakeCase(name)) return snakeToCamel(name);
  return name;
};
function extractRespName(input) {
  const regex = /\[(.*?)\]/;
  const match = input.match(regex);
  return match ? match[1] : null;
}
const getFieldValue = ({ path, functionName, name, apiRes, isApiResJson }) => {
  console.log({ path, functionName, name, apiRes, isApiResJson });

  if (path.startsWith("response")) {
    if (path.includes("[") && path.includes("]")) {
      name = extractRespName(path);
    }
    return functionName
      ? myFunctions[functionName](!isApiResJson ? apiRes : apiRes[name])
      : !isApiResJson
        ? apiRes
        : apiRes[name];
  } else if (path === "params") {
    const urlParams = new URLSearchParams(window.location.search);
    return functionName
      ? myFunctions[functionName](urlParams.get(name.trim()))
      : urlParams.get(name.trim());
  }
};
export const automateOAuth2 = ({
  url,
  dispatch,
  payload,
  response_path,
  fieldsToAdd,
  headers,
}) => {
  const credType = Cookies.get("type");
  const cookieName = Cookies.get("name");
  let newPayload = replaceValues(payload);
  console.log({ newPayload });
  let newUrl = replaceValuesInString(url);
  let newHeaderKey = "";
  const config = {
    method: "post",
    url: newUrl,
  };
  if (payload) {
    if (
      headers &&
      headers.hasOwnProperty("Content-Type") &&
      headers["Content-Type"] === "application/x-www-form-urlencoded"
    )
      config["data"] = new URLSearchParams(newPayload);
    else config["data"] = newPayload;
  }
  if (headers) {
    if (
      headers.hasOwnProperty("Authorization") &&
      headers.Authorization.includes("{{")
    ) {
      newHeaderKey = replaceValuesInString(headers.Authorization);
      console.log({ newHeaderKey });
      if (newHeaderKey.includes("||")) {
        const valueToUseAndFunc = newHeaderKey.split("||")[1];
        const authorizationType = newHeaderKey.split("||")[0];
        const valueToUse = valueToUseAndFunc.split("=>")[0];
        const funcToUse = valueToUseAndFunc.split("=>")[1];
        console.log({ valueToUse });
        newHeaderKey = authorizationType + myFunctions[funcToUse](valueToUse);
      }
      headers["Authorization"] = newHeaderKey;
      console.log({ newHeaderKey });
    }
    config["headers"] = headers;
  }
  // Make the POST request to exchange the code for tokens
  console.log({ config });
  axios(config)
    .then((response) => {
      let values;
      if (response_path) {
        values = getResponseValues(response, response_path);
      } else {
        values = response.data;
      }
      console.log({ values });
      dispatch(
        addCredential({
          credType: credType,
          credInfo: {
            Service_name: cookieName,
            service: credType,
            cred: fieldsToAdd.map((field) => {
              const fieldInfo = field.split("::");
              if (field.trim().includes("::")) {
                if (fieldInfo[1].trim().includes("=>")) {
                  const fieldPathAndFunction = fieldInfo[1].trim().split("=>");
                  return {
                    Credential_name: reformatName(fieldInfo[0]),
                    Credential_value: getFieldValue({
                      name: fieldInfo[0],
                      apiRes: values,
                      functionName: fieldPathAndFunction[1],
                      path: fieldPathAndFunction[0],
                      isApiResJson: !!response_path,
                    }),
                  };
                } else {
                  return {
                    Credential_name: reformatName(fieldInfo[0]),
                    Credential_value: getFieldValue({
                      name: fieldInfo[0],
                      apiRes: values,
                      path: fieldInfo[1],
                      isApiResJson: !!response_path,
                    }),
                  };
                }
              } else if (field.toLowerCase() === "redirecturi") {
                return {
                  Credential_name: field,
                  Credential_value:
                    process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
                };
              } else {
                return {
                  Credential_name: reformatName(field),
                  Credential_value: Cookies.get(field),
                };
              }
            }),
          },
        })
      );
    })
    .catch((error) => {
      // Handle any errors
      openNotificationWithIcon(
        "error",
        "Failed Creating Credential",
        "Please Make sure you filled correct Credentials"
      );
      console.log({ error });
    })
    .finally(() => {
      for (let key of fieldsToAdd) {
        Cookies.remove(key.trim().split("::")[0]);
      }
      Cookies.remove("new_user");
      Cookies.remove("isEditting");
      Cookies.remove("name");
      Cookies.remove("oldCred");
      Cookies.remove("random_nb");
      Cookies.remove("type");
      // You may want to remove the code from the URL for security reasons
      window.history.replaceState({}, document.title, window.location.pathname);

    });
};
export const StartOAuth2Flow = (dispatch) => {

  const credType = Cookies.get("type");
  console.log({ credType });
  if (credType && OAuth2ServiceTypes.hasOwnProperty(credType)) {
    OAuth2ServiceTypes[credType](dispatch);
  } else {
    console.log("Error:cred type does not exist");
  }
};
export const OAuth2AutheicationFlow = (dispatch) => {
  // Function to extract the authorization code from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const cookieType = Cookies.get("type");
  if (code && cookieType) {
    StartOAuth2Flow(dispatch);
  } else if (Cookies.get("type")&&!Cookies.get("continueProcess")) {
    setTimeout(() => {
      deleteAllCookiesExcept();
    }, 3000);
  }
};

// Function to delete all cookies except for specific cookies
export function deleteAllCookiesExcept() {
  const cookiesToKeep = {
    email: true,
    username: true,
    token: true,
    logedIn: true,
  };

  const allCookies = Cookies.get();
  // Loop through each cookie
  for (const cookieName in allCookies) {
    if (Object.prototype.hasOwnProperty.call(allCookies, cookieName)) {
      // Check if the current cookie is not in the list of cookies to keep
      if (!cookiesToKeep.hasOwnProperty(cookieName)) {
        // Remove the current cookie
        Cookies.remove(cookieName);
      }
    }
  }
}
