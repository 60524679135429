import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { is_loading_stories, fetch_stories, set_stories } = actions;

const getStories = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_stories(true));
    dispatch(fetch_stories(false));

    let config = {
      method: "get",
      url: process.env.REACT_APP_STORIES_URL + "/list_stories",
      headers: { Authorization: "Bearer " + authToken },
    };
    axios(config)
      .then((response) => {
        dispatch(setStories(response.data.stories));
        dispatch(is_loading_stories(false));
        openNotificationWithIcon('success', 'Load Stories', 'Stories Loaded successfully');
        dispatch(is_loading_stories(false));
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Load Stories', "Failed Loading Stories");
        console.log('Load Stories Error', error);
        dispatch(is_loading_stories(false));
      });
  };
};

const fetchStoriesToggle = () => {
  return dispatch => {
    dispatch(fetch_stories(true));
  };
};

const setStories = (newList) => {
  return dispatch => {
    dispatch(set_stories(newList));
  };
};

const deleteStoryByID = (id, clearFilteringInputs) => {
  return async (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_stories(true));

    let config = {
      method: "delete",
      url: process.env.REACT_APP_TRIGGERS_URL + "delete_trigger/" + id,
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Deleting Story", "Story Deleted Successfully");
        console.log('Deleting Story Response', JSON.stringify(response.data));
        dispatch(deleteStoryByIDLocally(id));
        dispatch(is_loading_stories(false));
        clearFilteringInputs();
      })
      .catch(function (error) {
        openNotificationWithIcon("error", "Deleting Story", "Deleting Story Was Not Successfully !");
        console.log('Deleting Story Error', error);
        dispatch(is_loading_stories(false));
      });
  };
};

const deleteStoryByIDLocally = (id) => {
  return (dispatch, getState) => {
    const { storiesList } = getState().stories;
    let newStoriesList = [...storiesList];

    newStoriesList = newStoriesList.filter(story => story.id !== id);
    dispatch(set_stories(newStoriesList));
  };
};

const deactivatedStoryByToken = (name, storyToken, clearFilteringInputs) => {
  return async (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_stories(true));
    let data = { token: storyToken };

    var config = {
      method: "post",
      url: process.env.REACT_APP_TRIGGERS_URL + 'deactivate_rpa',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        openNotificationWithIcon('success', 'Deactivate Story', "'" + name + "' Deactivated Successfully");
        dispatch(is_loading_stories(false));
        dispatch(updateDeactivatedStory(storyToken));
        clearFilteringInputs();
      })
      .catch((res) => {
        console.log('Deactivate Story Error', res);
        openNotificationWithIcon('error', 'Deactivate Story', "Failed Deactivating '" + name + "'");
        dispatch(is_loading_stories(false));
      });
  };
};

const updateDeactivatedStory = (token) => {
  return (dispatch, getState) => {
    const { storiesList } = getState().stories;
    let newStoriesList = [...storiesList];
    let rpaToUpdateIndex = newStoriesList.findIndex(story => story.token === token);

    newStoriesList[rpaToUpdateIndex] = { ...newStoriesList[rpaToUpdateIndex], status: 'Inactive' };
    dispatch(set_stories(newStoriesList));
  };
};

export { getStories, fetchStoriesToggle, setStories, deleteStoryByID, deactivatedStoryByToken };
