import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { DateRangePickerOne } from '../../datePicker/datePicker';
import { Button } from '../buttons';
import { Popover } from 'antd';
function getDate(dateString) {
  const date = new Date(dateString);

  return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });

}
const CalendarButtonPageHeader = ({ appliedDateRange, visible, setVisible, state, setState, onSelectChange, handleRangeChange, onApply, onCancel }) => {
  const calendarRef = useRef(null)
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // console.log({
      //   dropdownRef,
      //   event,
      //   visible,
      //   cond: dropdownRef.current && dropdownRef.current.contains(event.target),
      // });
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        visible
      ) {
        setVisible(false);
        setState(appliedDateRange)
      }
    };
    // console.log({ visible });
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [visible]);
  const content = (
    <div ref={calendarRef} >
      <DateRangePickerOne appliedDateRange={appliedDateRange} state={state} setState={setState} visible={visible} setVisible={setVisible} onSelectChange={onSelectChange} handleRangeChange={handleRangeChange} onApply={onApply} onCancel={onCancel} />
    </div>
  );
  return (
    <Popover placement="bottomRight" title="Search by Calendar" overlayStyle={{ zIndex: "9999" }} style={{ zIndex: "99999 !important" }} content={content} action="click" visible={visible}
    >
      <Button style={{ display: "flex" }} type="white" onClick={() => setVisible(prev => !prev)} >
        <FeatherIcon icon="calendar" size={14} />
        {getDate(state.dateRangePicker.selection.startDate)} - {getDate(state.dateRangePicker.selection.endDate)}
      </Button>
    </Popover>
  );
};

export { CalendarButtonPageHeader };
