import { Col, Popover, Row, Tooltip } from "antd";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { Button } from "../../components/buttons/buttons";
import { LoadingButton } from "../../components/loading-button/loading-button";
import {
  fetchCredentialsToggle,
  getCredentials,
  setModalName,
} from "../../redux/credentials/actionCreator";
import { CredsContent } from "./CredsFilterDropdown";
import { credentialTypes, imagesByKey } from "./credentialsData";
import { useDispatch, useSelector } from "react-redux";
import CredModal from "./credModal";

export const CredentialActions = ({
  setFilteredData,
  radioGroupValue,setRadioGroupValue
}) => {
  const { isLoadingCredentials,credentialsList,fetchCredentials } = useSelector((state) => {
    return {
      isLoadingCredentials: state.credentials.isLoadingCredentials,
      credentialsList: state.credentials.credentialsList,
      fetchCredentials: state.credentials.fetchCredentials,
    };
  });
  const [visible, setVisible] = useState(false);
  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterCredByName, setFilterCredByName] = useState("");
  const [ServiceList, setServiceList] = useState(credentialTypes);
  const dropdownRef = useRef(null);
  const dispatch=useDispatch()
  const handleChooseCredentialFromCredFilter = (value) => {
    setVisible(false);
    setFilterCredByName("");
    setServiceList(credentialTypes);
    handleChangeForCredAppFilter({
      target: { value: value },
    });
  };
  const handleChangeForCredAppFilter = (event) => {
    let { value } = event.target;
    setSearchText("");
    setRadioGroupValue(value);
    let newFilteredData = [];
    if (value === "All") newFilteredData = [...credentialsList];
    else newFilteredData = credentialsList.filter((c) => c.type === value);
    setFilteredData(newFilteredData);
  };
  const handleChangeCredListFilter = (event) => {
    const newFilteredData = credentialTypes.filter((value) =>
      value.toUpperCase().startsWith(event.target.value.toUpperCase())
    );
    setServiceList(newFilteredData);
    setFilterCredByName(event.target.value);
  };
  const handleSearch = (searchText) => {
    const newFilteredData = credentialsList.filter((value) =>
      value.credName.toUpperCase().startsWith(searchText.toUpperCase())
    );
    const title = newFilteredData.map((e) => {
      return {
        title: e.credName,
      };
    });

    setFilteredData(newFilteredData);
    setAutoCompleteList(title);
    setSearchText(searchText);
    setRadioGroupValue("All");
  };
  function clearFilteringInputs() {
    setSearchText("");
    setAutoCompleteList([]);
    setRadioGroupValue("All");
    setFilterCredByName("")
    setServiceList(credentialTypes)
  }
    //this to close the dropdown when i select a value from filter
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          visible
        ) {
          setVisible(false);
        }
      };
      document.addEventListener("click", handleOutsideClick);
  
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }, [visible]);
    useEffect(() => {
      if (fetchCredentials) {
        clearFilteringInputs();
        dispatch(getCredentials());
      } else if (credentialsList) {
        setFilteredData(credentialsList);
      }
    }, [JSON.stringify(credentialsList), fetchCredentials]);
  
  return (
    <Row gutter={15} className="justify-content-center">
      <CredModal
        // credToBeEdited={credToBeEdited}
        // setCredToBeEdited={setCredToBeEdited}
      />
      <Col span={8}>
        <div className="table-search-box">
          <AutoComplete
            onSearch={(event) => handleSearch(event)} //event here is the input value.
            onSelect={(event) => handleSearch(event)} //event here is the input value.
            onClear={() => setSearchText("")}
            dataSource={autoCompleteList}
            value={searchText}
            placeholder="Search here"
            width="100%"
            patterns
          />
        </div>
      </Col>
      <Col span={10}>
        <Popover
          placement="bottomRight"
          content={"Refresh Flows"}
          action="hover"
        >
          <LoadingButton
            toggleCondition={isLoadingCredentials}
            onClick={() => dispatch(fetchCredentialsToggle(true))}
          />
        </Popover>
      </Col>
      <Col
        span={6}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div className="settings">
          <Tooltip
            title={
              radioGroupValue === "All" ? (
                "All"
              ) : (
                <div style={{ display: "flex", gap: "10px" }}>
                  <img src={imagesByKey[radioGroupValue]} width={"25px"} />
                  <span>{radioGroupValue}</span>
                </div>
              )
            }
          >
            <Popover
              placement="bottomRight"
              content={
                <CredsContent
                  dropdownRef={dropdownRef}
                  filterCredByName={filterCredByName}
                  handleChangeCredListFilter={handleChangeCredListFilter}
                  ServiceList={ServiceList}
                  radioGroupValue={radioGroupValue}
                  handleChooseCredentialFromCredFilter={handleChooseCredentialFromCredFilter}
                />
              }
              action={["click"]}
              visible={visible}
            >
              <div
                onClick={() => setVisible((prev) => !prev)}
                style={{
                  color: "#585be1",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Link to="#" className="head-example">
                  <FeatherIcon
                    style={{ display: "flex" }}
                    icon="sliders"
                    size={20}
                  />
                </Link>
              </div>
            </Popover>
          </Tooltip>
        </div>
        <Button
          style={{
            display: "flex",
            marginLeft: "15px",
            padding: "13px 12px",
          }}
          size="middle"
          type="primary"
          disabled={isLoadingCredentials}
          onClick={() => dispatch(setModalName("newCred"))}
        >
          <FeatherIcon icon="plus" size={12} />
          {"New Credential"}
        </Button>
      </Col>
    </Row>
  );
};
