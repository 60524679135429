const actions = {
  IS_LOADING_STORIES: 'IS_LOADING_STORIES',
  FETCH_STORIES: 'FETCH_STORIES',
  SET_STORIES: 'SET_STORIES',

  is_loading_stories: (status) => {
    return {
      type: actions.IS_LOADING_STORIES,
      status: status,
    };
  },
  fetch_stories: (status) => {
    return {
      type: actions.FETCH_STORIES,
      status: status,
    };
  },
  set_stories: (data) => {
    return {
      type: actions.SET_STORIES,
      data: data,
    };
  },
};

export default actions;
