import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cards } from "../../components/cards/frame/cards-frame";
import { ChartjsHorizontalChart, ChartjsStackedChart } from "../../components/charts/chartjs";
import Heading from "../../components/heading/heading";
import { CardBarChart } from "../../container/dashboard/DashboardComponents/style";
import { getTop10FlowsFail } from "../../redux/reporting/actionCreator";
import { LoadingButton } from "../../components/loading-button/loading-button";

const TopFail = ({ }) => {
  const dispatch = useDispatch();
  const { isLoadingReporting, top10FailFlowsList } = useSelector((state) => {
    return {
      isLoadingReporting: state.reporting.isLoadingReportingFailFlows,
      fetchTop10: state.reporting.fetchTop10,
      top10FailFlowsList: state.reporting.top10FailFlowsList,
    };
  });
  useEffect(() => {
    dispatch(getTop10FlowsFail());
  }, [dispatch]);
  const topFailFlowsDatasets = top10FailFlowsList !== null && [
    {
      data: top10FailFlowsList.runs,
      backgroundColor: "#AD3278",
      hoverBackgroundColor: "#ad3278ba",
      label: "Run",
      maxBarThickness: 40,
      barThickness: 32,
    },
  ];

  return (
    <>
     
        <Cards
          isbutton={<div className="card-nav"><LoadingButton
          toggleCondition={isLoadingReporting}
          onClick={() => dispatch(getTop10FlowsFail())}
        /></div>}
          title="Top Fail Active flows"
          size="large"
        >
          {isLoadingReporting ? (
            <div className="sd-spin">
              <Spin />
            </div>
          ) : 
          top10FailFlowsList !== null && (  <CardBarChart>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="card-bar-top">
                  <p>Runs</p>
                  <Heading as="h3">
                    {top10FailFlowsList.runs.reduce(
                      (accumulator, currentValue) => accumulator + currentValue,
                      0
                    )}
                  </Heading>
                </div>
                <ul>
                {/* {top10FailFlowsList.runs.map((flow,i)=> (<li className="custom-label">
                    <span
                      style={{
                        backgroundColor: top10FailFlowsList.colors[i],
                      }}
                    />
                   {top10FailFlowsList.labels[i]}{flow}
                  </li>))} */}
                   <li className="custom-label"> <span
                      style={{
                        backgroundColor: "#AD3278",
                      }}
                    />Run</li>
                </ul>
              </div>

              <ChartjsStackedChart
                labels={top10FailFlowsList.labels}
                datasets={topFailFlowsDatasets}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                  scales: {
                    yAxes: [{
                      stacked: true,
                      ticks: {
                        beginAtZero: true, // Start the scale from 0
                        stepSize:1 
                      }
                    }],},
                  layout: {
                    padding: {
                      top: 20,
                    },
                  },
                  legend: {
                    display: false,
                    position: "top",
                    align: "end",
                    labels: {
                      boxWidth: 6,
                      display: true,
                      usePointStyle: true,
                    },
                  },
                }}
              />
            </CardBarChart>
          )}
        </Cards>
     
    </>
  );
};

export default TopFail;
