import Cookies from "js-cookie";
import actions from "./actions";
function compareDates(obj1, obj2) {
  const date1 = new Date(obj1.lastUpdate);
  const date2 = new Date(obj2.lastUpdate);

  // Compare dates first (in descending order)
  if (date1 < date2) return 1;
  if (date1 > date2) return -1;

  // If dates are equal, compare times
  const time1 = date1.getHours() * 3600 + date1.getMinutes() * 60 + date1.getSeconds();
  const time2 = date2.getHours() * 3600 + date2.getMinutes() * 60 + date2.getSeconds();
  // Compare times
  return time2 - time1;
}
const {
  MODAL_NAME,
  ADD_CREDENTIAL,
  EDIT_CREDENTIAL,
  DELETE_CREDENTIAL,
  GET_CREDENTIALS,
  FETCH_CREDENTIALS,
  IS_LOADING_CREDENTIALS,
} = actions;

const initState = {
  modalName: "none",
  credentialsList: [],
  fetchCredentials: true,
  isLoadingCredentials: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const CredentialsReducer = (state = initState, action) => {
  const { type, data, err, status } = action;
  switch (type) {
    case FETCH_CREDENTIALS:
      return { ...state, fetchCredentials: status };
    case IS_LOADING_CREDENTIALS:
      return { ...state, isLoadingCredentials: status };
    case MODAL_NAME:
      return { ...state, modalName: status };
    case ADD_CREDENTIAL:
      const doesCredExist = state.credentialsList.find(
        (c) => c.credName === data.credName
      );
      if (!doesCredExist)
        return { ...state, credentialsList: [data,...state.credentialsList] };
      else return { ...state };
    case EDIT_CREDENTIAL:
      return {
        ...state,
        credentialsList: state.credentialsList.map((c) =>
          c.Service_name === data.Service_name ? data : c
        ),
      };
    case DELETE_CREDENTIAL:
      return {
        ...state,
        credentialsList: state.credentialsList.filter(
          (c) => c.credName !== data
        ),
      };
    case GET_CREDENTIALS:
      return { ...state, credentialsList: data.slice().sort(compareDates) };
    default:
      return state;
  }
};
export default CredentialsReducer;