import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import StoriesList from '../../container/stories/stories';


const StoriesRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={StoriesList} />
    </Switch>
  );
};

export default StoriesRoutes;