import { Col, Row, Skeleton } from "antd";
import React, { Suspense } from "react";
import { Cards } from "../../components/cards/frame/cards-frame";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import TopFail from "./TopFail";
import TopPass from "./TopPass";
import "./reporting.css";
const Metrics = () => {
  
  return (
    <>
      <PageHeader ghost title="Metrics" />{" "}
      <Main>
        <Row
          justify="center"
          gutter={25}
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Col span={11}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active />
                </Cards>
              }
            >
              <TopPass />
            </Suspense>
          </Col>
          <Col span={11}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active />
                </Cards>
              }
            >
              <TopFail />
            </Suspense>
          </Col>
        </Row>
      </Main>
    </>
  );
};
export default Metrics;
