import actions from "./actions";

const {
  IS_LOADING_MODALS_AND_PROVIDERS_USAGE,
  SET_MODALS_AND_PROVIDERS_USAGE,
  IS_LOADING_FLOWS_USAGE,
  SET_FLOWS_USAGE,
  IS_LOADING_TOKEN_USAGE_PER_FLOW,
  SET_TOKEN_USAGE_PER_FLOW,
  SET_TOTAL_COST,
  IS_LOADING_TOTAL_COST,
  SET_TOP_10_TOEKN_USAGE,
  IS_LOADING_TOP_10_TOKEN_USAGE

} = actions;

const initState = {
  usagePerModalList: [],
  usagePerProvider: [],
  isLoadingModalAndProviderUsage: false,
  isLoadingFlowsUsage: false,
  tokenUsagePerFlowList: [],
  isLoadingTokenUsagePerFlow: false,
  top10TokenUsageList: [],
  isLoadingTop10TokenUsage: false,
  isLoadingTotalCost: false,
  totalCost: 0,

};

/**
 *
 * @todo impure state mutation/explaination
 */
const tokenUsageReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case IS_LOADING_TOP_10_TOKEN_USAGE:
      return {
        ...state,
        isLoadingTop10TokenUsage: status,
      };
    case SET_TOP_10_TOEKN_USAGE:
      return {
        ...state,
        top10TokenUsageList: data,
      };
    case IS_LOADING_TOTAL_COST:
      return {
        ...state,
        isLoadingTotalCost: status,
      };
    case SET_TOTAL_COST:
      return {
        ...state,
        totalCost: data,
      };
    case IS_LOADING_TOKEN_USAGE_PER_FLOW:
      return {
        ...state,
        isLoadingTokenUsagePerFlow: status,
      };
    case SET_TOKEN_USAGE_PER_FLOW:
      return {
        ...state,
        tokenUsagePerFlowList: data,
      };
    case IS_LOADING_FLOWS_USAGE:
      return {
        ...state,
        isLoadingFlowsUsage: status,
      };
    case SET_FLOWS_USAGE:
      return {
        ...state,
        usagePerFlowList: data,
      };
    case IS_LOADING_MODALS_AND_PROVIDERS_USAGE:
      return {
        ...state,
        isLoadingModalAndProviderUsage: status,
      };
    case SET_MODALS_AND_PROVIDERS_USAGE:
      return {
        ...state,
        usagePerModalList: data.models,
        usagePerProvider: data.providers,

      };

    default:
      return state;
  }
};

export default tokenUsageReducer;
