import { Spin } from "antd";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import withAdminLayout from "../../layout/withAdminLayout";
import { SignOut } from "../../redux/authentication/actionCreator";
import UserRoutes from "./userRoutes";
function parseJwt(token) {
    try {
        return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    } catch (error) {
        return {}
    }
}
const SuperAdmin = () => {
    const { path } = useRouteMatch();
    const history = useHistory()
    const authtoken = useSelector(state => state.auth.token)
    const dispatch = useDispatch()
    useEffect(() => {
        if (parseJwt(authtoken)?.accessFrom==="Admin") {
            dispatch(SignOut())
            history.push("/loginSuperadmin")
        }
    }, [])


    return (
        <Switch>
            <Suspense
                fallback={
                    <div className="spin">
                        <Spin />
                    </div>
                }
            >



                <Route path={"/superAdmin"} /* path={`${path}/users`} */ component={UserRoutes} />



            </Suspense >
        </Switch >
    );
};

export default withAdminLayout(SuperAdmin);
