import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popover } from "antd";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CredsDropDown } from "../../components/utilities/auth-info/auth-info-style";

const SearchByNodeType = ({
  value,
  onChange,
  options,
  setOptions,
  searchvalue,
  setSearchValue,
  handleChangeFilter,
}) => {
  const [visible, setVisible] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef=useRef(null)
  useEffect(() => {
      const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        visible
      ) {
        setVisible(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [visible,inputRef]);

  const CredsContent = (
    <CredsDropDown>
      <div
        ref={dropdownRef}
        className="settings-dropdown"
        style={{
          overflow: "auto",
          overflowX: "hidden",
          maxHeight: "300px",
          minWidth: "230px",
        }}
      >
        <div
          style={{
            margin: "5px",
            padding:"3px",
            marginBottom:"10px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "6px",
            flexWrap: "wrap",
          }}
        >
         <span style={{fontSize:"medium"}}> Current Filter:</span>
          {Object.keys(value).map((node) => {
            return node === "All" ? (
              <span>All</span>
            ) : (
              <img
                style={{
                  marginLeft: "5px",
                }}
                width="25px"
                height={"25px"}
                src={`/components-icons/${node}.png`}
              />
            );
          })}
        </div>
        <Input
          placeholder="Search"
          autoFocus
          ref={inputRef}
          suffix={<SearchOutlined />}
          style={{
            height: "40px",
            marginRight: "10px",
            marginBottom: "10px",
            padding: "9px 11px",
            width: "95%",
          }}
          value={searchvalue}
          onChange={handleChangeFilter}
        />
        <ul className="settings-dropdown-links">
          {options.map((elt) => {
            return (
              <li key={elt} style={value[elt] ? { background: "#F8F9FB" } : {}}>
                {" "}
                <Link
                  to="#"
                  onClick={() => {
                    if (elt === "All") {
                      onChange({"All":true});
                    } else if (value.hasOwnProperty(elt)) {
                      const newValue = { ...value };
                      delete newValue[elt];
                      if (newValue.hasOwnProperty("All"))
                        delete newValue["All"];
                      onChange(newValue);
                    } else {
                      const newValue = { ...value };
                      if (newValue.hasOwnProperty("All"))
                        delete newValue["All"];
                      onChange({ ...newValue, [elt]: true });
                    }
                  }}
                >
                  <div
                    style={{
                      paddingLeft: value[elt] ? "20px" : "",
                    }}
                  >
                    {elt !== "All" && (
                      <img
                        style={{
                          marginRight: "10px",
                        }}
                        width="25px"
                        height={"25px"}
                        src={`/components-icons/${elt}.png`}
                      />
                    )}
                    <span style={value[elt] ? { color: "#AD3278" } : {}}>
                      {elt}
                    </span>
                    {value[elt] && (
                      <span
                        style={{
                          color: '"#AD3278',
                          textAlign: "right",
                          marginLeft: "10px",
                        }}
                      >
                        ✔
                      </span>
                    )}
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </CredsDropDown>
  );

  return (
    <Popover
      placement="bottomRight"
      content={CredsContent}
      action={["click"]}
      visible={visible}
    >
      <Button
        size="small"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "15px",
          fontSize: "medium",
          gap: "10px",
        }}
        type="white"
        onClick={() => setVisible((prev) => !prev)}
      >
        <span> Filter By App </span>
        <FeatherIcon icon="filter" size={14} />
      </Button>
    </Popover>
  );
};
export default SearchByNodeType;
