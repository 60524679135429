import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { /* NavLink, */ Link, useRouteMatch } from "react-router-dom";
import { changeBotsType } from "../redux/chatbots/actionCreator";
import { TopMenuStyle } from "./style";

const TopMenu = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const active = document.querySelector(".strikingDash-top-menu a.active");
    const activeDefault = () => {
      const megaMenu = active.closest(".megaMenu-wrapper");
      const hasSubMenuLeft = active.closest(".has-subMenu-left");
      if (!megaMenu) {
        active.closest("ul").previousSibling.classList.add("active");
        if (hasSubMenuLeft)
          hasSubMenuLeft.closest("ul").previousSibling.classList.add("active");
      } else {
        active
          .closest(".megaMenu-wrapper")
          .previousSibling.classList.add("active");
      }
    };
    window.addEventListener("load", active && activeDefault);
    return () => window.removeEventListener("load", activeDefault);
  }, []);

  const addParentActive = (event) => {
    document.querySelectorAll(".parent").forEach((element) => {
      element.classList.remove("active");
    });

    const hasSubMenuLeft = event.currentTarget.closest(".has-subMenu-left");
    const megaMenu = event.currentTarget.closest(".megaMenu-wrapper");
    if (!megaMenu) {
      event.currentTarget.closest("ul").previousSibling.classList.add("active");
      if (hasSubMenuLeft)
        hasSubMenuLeft.closest("ul").previousSibling.classList.add("active");
    } else {
      event.currentTarget
        .closest(".megaMenu-wrapper")
        .previousSibling.classList.add("active");
    }
  };

  const addItemActive = (event) => {
    document.querySelectorAll(".parent").forEach((element) => {
      element.classList.remove("active");
    });

    event.currentTarget.classList.add("active");
  };

  return (
    <TopMenuStyle>
      <div className="strikingDash-top-menu">
        <ul>
          {/* <li>
            <Link
              className="parent"
              onClick={addItemActive}
              to={`${path}/deployments`}
            >
              {"Deployments"}
            </Link>
          </li> */}
          {/* <li>
            <Link
              className="parent"
              onClick={addItemActive}
              to={`${path}/stories`}
            >
              {"Stories"}
            </Link>
          </li> */}
          <li>
            <Link
              className="parent"
              onClick={addItemActive}
              to={`${path}/credentials`}
            /* to={`${path}/rpas`} */
            >
              {"Credentials"}
            </Link>
          </li>
          <li>
            <Link
              className="parent"
              onClick={addItemActive}
              to={`${path}`}
            /* to={`${path}/rpas`} */
            >
              {"Flows"}
            </Link>
          </li>
          {/* <li>
            <Link className="parent" onClick={addItemActive} to={`${path}`}>
              {"Dashboard"}
            </Link>
          </li> */}
          <li className="has-subMenu">
            <Link to="#" className="parent">
              {"Chatbots"}
            </Link>
            <ul className="subMenu">
              <li>
                <Link
                  onClick={(event) => { addParentActive(event); dispatch(changeBotsType("Web")); }}
                  to={`${path}/chatbot`}
                >
                  {"Web Bots"}
                </Link>
                <Link
                  onClick={(event) => { addParentActive(event); dispatch(changeBotsType("Teams")); }}
                  to={`${path}/chatbot`}
                >
                  {"Teams Bots"}
                </Link>
                <Link
                  onClick={(event) => { addParentActive(event); dispatch(changeBotsType("Slack")); }}
                  to={`${path}/chatbot`}
                >
                  {"Slack Bots"}
                </Link>
                <Link
                  onClick={(event) => { addParentActive(event); dispatch(changeBotsType("Workplace")); }}
                  to={`${path}/chatbot`}
                >
                  {"Workplace Bots"}
                </Link>
                <Link
                  onClick={(event) => { addParentActive(event); dispatch(changeBotsType("Whatsapp")); }}
                  to={`${path}/chatbot`}
                >
                  {"Whatsapp Bots"}
                </Link>
                <Link
                  onClick={(event) => { addParentActive(event); dispatch(changeBotsType("Facebook")); }}
                  to={`${path}/chatbot`}
                >
                  {"Facebook Bots"}
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link
              className="parent"
              onClick={addItemActive}
              to={`${path}/cards`}
            >
              {"Adaptive Cards"}
            </Link>
          </li>

          <li>
            <Link
              className="parent"
              onClick={addItemActive}
              to={`${path}/whatsapp-cards`}
            >
              {"Whatsapp Cards"}
            </Link>
          </li>
          <li>
            <Link
              className="parent"
              onClick={addItemActive}
              to={`${path}/knowledge-ai`}
            >
              {"Knowledge AI"}
            </Link>
          </li>
          <li>
            <Link
              className="parent"
              onClick={addItemActive}
              to={`${path}/reporting`}
            >
              {"Reporting"}
            </Link>
          </li>

          {/* <li className="has-subMenu">
            <Link to="#" className="parent">
              {"Reporting"}
            </Link>
            <ul className="subMenu">
              <li>
                <NavLink
                  onClick={addParentActive}
                  to={`${path}/reporting/messages`}
                >
                  {"Messages"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={addParentActive}
                  to={`${path}/reporting/chatbot-success-rate`}
                >
                  {"Chatbot Success Rate"}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={addParentActive}
                  to={`${path}/reporting/live`}
                >
                  {"Live"}
                </NavLink>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </TopMenuStyle>
  );
};

export default TopMenu;
