import actions from './actions';

const {
  IS_LOADING_RESPONSE_KEY_VAULT_DATA, FETCH_RESPONSE_KEY_VAULT_DATA, SHOW_KEY_VAULT_MODAL, SET_KEY_VAULT_DATA, IS_NEW_USER,
  SHOW_KEY_VAULT_MODAL_AWS, SET_KEY_VAULT_DATA_AWS, IS_LOADING_RESPONSE_KEY_VAULT_DATA_AWS, FETCH_RESPONSE_KEY_VAULT_DATA_AWS, IS_NEW_USER_AWS
} = actions;

const initialIamKeyVaultData = [
  { label: 'Client ID', name: 'azureClientId', value: '', version: "" },
  { label: 'Client Secret', name: 'azureClientSecret', value: '', version: "" },
  { label: 'Tanent ID', name: 'azureClientTenantId', value: '', version: "" },
  { label: 'Vault', name: 'azureClientVault', value: '', version: "" },
];

const initialIamKeyVaultDataAws = [
  { label: 'Access Key Id', value: '', name: "" },
  { label: 'Secret Access Key', value: '', name: "" },
  { label: 'Region', value: '', name: "" },
  { label: 'Secret Name', value: '', name: "" },
];

const initState = {
  showKeyVaultModal: false,
  keyVaultIamDataAzure: [...initialIamKeyVaultData],
  // keyVaultAdminData: [...initialAdminKeyVaultData],
  isLoadingResponseKeyVault: false,
  fetchResponseKeyVaultData: true,
  isNewUser: false,

  showKeyVaultModalAws: false,
  keyVaultIamDataAws: [...initialIamKeyVaultDataAws],
  // keyVaultAdminData: [...initialAdminKeyVaultData],
  isLoadingResponseKeyVaultAws: false,
  fetchResponseKeyVaultDataAws: true,
  isNewUserAws: false

};

/**
 *
 * @todo impure state mutation/explaination
 */
const keyVaultReducer = (state = initState, action) => {
  const { type, status, iamKeyVaultData, iamKeyVaultDataAws /* , keyVaultAdminData  */ } = action;
  switch (type) {
    case SHOW_KEY_VAULT_MODAL:
      return {
        ...state,
        showKeyVaultModal: status,
      };
    case SET_KEY_VAULT_DATA:
      return {
        ...state,
        keyVaultIamDataAzure: iamKeyVaultData,
      };
    case IS_LOADING_RESPONSE_KEY_VAULT_DATA:
      return {
        ...state,
        isLoadingResponseKeyVault: status,
      };
    case FETCH_RESPONSE_KEY_VAULT_DATA:
      return {
        ...state,
        fetchResponseKeyVaultData: status,
      };
    case IS_NEW_USER:
      return {
        ...state,
        isNewUser: status,
      };
 
    case SHOW_KEY_VAULT_MODAL_AWS:
      return {
        ...state,
        showKeyVaultModalAws: status,
      };
    case SET_KEY_VAULT_DATA_AWS:
      return {
        ...state,
        keyVaultIamDataAws: iamKeyVaultDataAws,
      };
    case IS_LOADING_RESPONSE_KEY_VAULT_DATA_AWS:
      return {
        ...state,
        isLoadingResponseKeyVaultAws: status,
      };
    case FETCH_RESPONSE_KEY_VAULT_DATA_AWS:
      return {
        ...state,
        fetchResponseKeyVaultDataAws: status,
      };
    case IS_NEW_USER_AWS:
      return {
        ...state,
        isNewUserAws: status,
      };
    default:
      return state;
  }
};

export default keyVaultReducer;