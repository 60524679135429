import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CredsDropDown } from "../../components/utilities/auth-info/auth-info-style";
import { imagesByKey } from "./credentialsData";

export const CredsContent = ({
  dropdownRef,
  filterCredByName,
  handleChangeCredListFilter,
  ServiceList,
  radioGroupValue,
  handleChooseCredentialFromCredFilter,
}) => (
  <CredsDropDown>
    <div
      ref={dropdownRef}
      className="settings-dropdown"
      style={{
        overflow: "auto",
        overflowX: "hidden",
        maxHeight: "300px",
        minWidth: "200px",
      }}
    >
      <Input
        placeholder="Search"
        suffix={<SearchOutlined />}
        style={{
          height: "40px",
          marginRight: "10px",
          marginBottom: "10px",
          padding: "9px 11px",
          width: "95%",
        }}
        value={filterCredByName}
        onChange={handleChangeCredListFilter}
      />
      <ul className="settings-dropdown-links">
        {ServiceList.map((value) => {
          let img;
          try {
            img = imagesByKey[value];
          } catch (error) {
            img = imagesByKey["noImage"];
          }
          return (
            <li
              key={value}
              style={
                value === radioGroupValue ? { background: "#ad327805" } : {}
              }
            >
              {" "}
              <Link to="#" onClick={() => handleChooseCredentialFromCredFilter(value)}>
                <div
                  style={{
                    paddingLeft: value === radioGroupValue ? "20px" : "",
                  }}
                >
                  {value !== "All" && (
                    <img
                      style={{
                        marginRight: "10px",
                      }}
                      width="25px"
                      height={"25px"}
                      src={img}
                    />
                  )}
                  <span
                    style={
                      value === radioGroupValue ? { color: "#ad3278" } : {}
                    }
                  >
                    {value}
                  </span>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  </CredsDropDown>
);
