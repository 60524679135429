import React, { /* lazy, */ Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

import Login from '../container/profile/authentication/overview/SignIn';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import SignUP from '../container/profile/authentication/overview/Signup';
// import SignUP from '../container/profile/authentication/overview/Signup';

let token;
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const NotFound = () => {

  return (token ? <Redirect to={`/SignUp?token=${token}`} /> : <Redirect to="/" />);
};
const FrontendRoutes = () => {

  let query = useQuery();
  token = query.get("token");
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`/SignUp`} exact component={SignUP} />
        <Route exact path="/" component={Login} />


        {/* <Route exact path="/" component={Login} /> */}
        <Route exact path="*" component={NotFound} />
      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
