import React from "react";
import { Row, Col } from "antd";
import { Aside, Content } from "./overview/style";
// import Heading from '../../../components/heading/heading';
import "./imageAnimation.css"

const AuthLayout = (WraperContent,superAdmin) => {
  return () => {
    return (
      <Row style={{flexDirection:superAdmin&&"row-reverse"}}>
        <Col xxl={13} xl={12} lg={12} md={8} xs={24}>
          <Aside
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="imageAnimation"
              src={require("./ziwo.webp")}
              alt=""
              width={"50%"}
              height={"50%"}
              style={{ objectFit: "contain",position:"relative" }}
            />
          </Aside>
        </Col>

        <Col
          xxl={11}
          xl={12}
          lg={12}
          md={16}
          xs={24}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
