import React, { useEffect, useRef, useState } from 'react';
import { Popover, Select, Space } from 'antd';
import { FlowsDropDown } from '../../components/utilities/auth-info/auth-info-style';
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const filterData = ["All", "Active", "ChatBot", "Inactive", "scheduler", "Webhook"];

const FilterFlows = ({ radioGroupValue, handleChangeForFilterTriggerTypeAndStatus }) => {
    const [visible, setVisible] = useState(false)
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setVisible(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const flowsContent = (
        <FlowsDropDown>
            <div
                className="settings-dropdown"
                style={{ overflow: "auto", overflowX: "hidden", minHeight: "200px", width: "100px" }}
            >
                <ul className="settings-dropdown-links">
                    {filterData.map((value) => {
                        // let img
                        // try {
                        //     img = require("./" + value + ".png")
                        // } catch (error) {
                        // img = require("./noImage.png")
                        // }
                        return (
                            <li
                                key={value}
                                style={
                                    value === radioGroupValue ? { background: "#F8F9FB" } : {}
                                }
                            >
                                {" "}
                                <Link
                                    to="#"
                                    onClick={() => {
                                        handleChangeForFilterTriggerTypeAndStatus({
                                            target: { value: value },
                                        })
                                        setVisible(false)
                                    }
                                    }
                                >
                                    <div
                                        style={{
                                            paddingLeft: value === radioGroupValue ? "20px" : "",
                                        }}
                                    >
                                        {/* {value !== "All" && (
                                            <img
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                width="25px"
                                                height={"25px"}
                                                src={img}
                                            />
                                        )} */}
                                        <span
                                            style={
                                                value === radioGroupValue ? { color: "#AD3278" } : {}
                                            }
                                        >
                                            {value}
                                        </span>
                                    </div>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </FlowsDropDown>
    );

    return (
        <div className="settings">
            <Popover
                placement="bottomRight"
                content={flowsContent}
                action={["click"]}
                visible={visible}
            >
                <div ref={dropdownRef} onClick={() => setVisible(true)} style={{ color: "#585be1", cursor: "pointer", display: "flex", justifyContent: "space-evenly" }}>
                    <Link to="#" className="head-example">
                        <FeatherIcon style={{ display: "flex" }} icon="sliders" size={20} />
                    </Link>
                </div>
            </Popover>
        </div>
    );
};
export default FilterFlows;