import { Col, Input, Row, Skeleton } from "antd";
import { addDays } from "date-fns";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { CalendarButtonPageHeader } from "../../components/buttons/calendar-button/calendar-button";
import { Cards } from "../../components/cards/frame/cards-frame";
import Heading from "../../components/heading/heading";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { getModalsUsage } from "../../redux/token-usage/actionCreator";
import { CardBarChart } from "../dashboard/DashboardComponents/style";
import { getDate } from "../new-reporting/reportingData";
import FilterByAINode from "./FilterByAINode";
import TokenUsagePerModel from "./TokenUsagePerModel";
import TokenUsagePerProvider from "./TokenUsagePerProvider";
const { Search } = Input

const StyledSearch = styled(Input.Search)`
  width: 300px;
  border-radius: 8px;
  
  .ant-input {
  height:32px;
  }

  .ant-input-search {
    height:32px;
  }
`;
const initialState = {
    datePickerInternational: null,
    dateRangePicker: {
        selection: {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: "selection",
        },
    },
};
let timeOutId
const TokenUsagePerModelAndProvider = ({ flowsData }) => {
    const dispatch = useDispatch();
    const [AINodeValue, setAINodeValue] = useState('All');
    const [flowName, setFlowName] = useState('');
    const [dateRange, setDateRange] = useState(initialState);
    const [appliedDateRange, setAppliedDateRange] = useState(initialState);
    const [calendarVisible, setCalendarVisible] = useState(false);

    const { isLoadingModalAndProviderUsage, usagePerModalList, usagePerProvider } = useSelector(state => {
        return {
            isLoadingModalAndProviderUsage: state.tokenUsage.isLoadingModalAndProviderUsage,
            usagePerModalList: state.tokenUsage.usagePerModalList,
            usagePerProvider: state.tokenUsage.usagePerProvider,

        };
    });
    const onSelectChange = (selectedRowKey) => {
        setDateRange({ ...dateRange, selectedRowKeys: selectedRowKey });
    };
    const handleRangeChange = (which) => {
        setDateRange({
            ...dateRange,
            dateRangePicker: {
                ...dateRange.dateRangePicker,
                ...which,
            },
        });
    };
    const onApply = () => {
        setAppliedDateRange(dateRange)
        dispatch(getModalsUsage(
            getDate(dateRange.dateRangePicker.selection.startDate),
            getDate(dateRange.dateRangePicker.selection.endDate),
            flowName,
            AINodeValue
        )
        )

        setCalendarVisible(false);
    };
    const onCancel = () => { };
    const handleChangeForFilterByAINode = (event) => {
        let { value } = event.target;
        setAINodeValue(value)
        dispatch(getModalsUsage(
            getDate(dateRange.dateRangePicker.selection.startDate),
            getDate(dateRange.dateRangePicker.selection.endDate),
            flowName,
            value
        )
        )
    }

    useEffect(() => {
        dispatch(getModalsUsage(
            getDate(dateRange.dateRangePicker.selection.startDate),
            getDate(dateRange.dateRangePicker.selection.endDate),
            flowName,
            AINodeValue
        )
        )
    }, []);// eslint-disable-line


    const onSearch = (value, _e, info) => {
        setFlowName(value)
        clearTimeout(timeOutId)
        timeOutId = setTimeout(() =>
            dispatch(getModalsUsage(
                getDate(dateRange.dateRangePicker.selection.startDate),
                getDate(dateRange.dateRangePicker.selection.endDate),
                value,
                AINodeValue
            )
            ), 500)
    };
    const onChange = (e) => {
        const newValue=e.target.value
        setFlowName(newValue)
        clearTimeout(timeOutId)
        timeOutId = setTimeout(() =>
            dispatch(getModalsUsage(
                getDate(dateRange.dateRangePicker.selection.startDate),
                getDate(dateRange.dateRangePicker.selection.endDate),
                newValue,
                AINodeValue
            )
            ), 500)
    }

    return (
        <>

            <Cards
                isbutton={
                    <div className="card-nav">
                        <LoadingButton
                            toggleCondition={isLoadingModalAndProviderUsage}
                            onClick={() => dispatch(getModalsUsage(
                                getDate(dateRange.dateRangePicker.selection.startDate),
                                getDate(dateRange.dateRangePicker.selection.endDate),
                                flowName,
                                AINodeValue
                            )
                            )
                            }
                        />
                    </div>
                }
                title="Token Usage per models & providers"
                size="large"
            >
                <Row gutter={[15, 15]} >
                    <Col>
                        <CalendarButtonPageHeader
                            visible={calendarVisible}
                            setVisible={setCalendarVisible}
                            key="1"
                            onApply={onApply}
                            onCancel={onCancel}
                            state={dateRange}
                            setState={setDateRange}
                            onSelectChange={onSelectChange}
                            handleRangeChange={handleRangeChange}
                            appliedDateRange={appliedDateRange}
                        />
                    </Col>
                    <FilterByAINode AINodeValue={AINodeValue} handleChangeForFilterByAINode={handleChangeForFilterByAINode} />
                    <Col>
                        <StyledSearch
                            placeholder="Flow name"
                            size="small"
                            onSearch={onSearch}
                            onChange={onChange}
                            style={{
                                width: 200,
                                height: 32
                            }}
                        />
                    </Col>
                    {(flowName !== "" || AINodeValue !== "All") &&
                        <Col style={{ display: "inline-flex" }}>
                            <span style={{ marginRight: "4px" }}>
                                Showing Results:</span>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                                {flowName && <span>Flow Name:{flowName}</span>}
                                {AINodeValue !== "All" && <span>AI Node: {AINodeValue}</span>}
                            </div>
                        </Col>}
                </Row>
                <Row >
                    {usagePerModalList !== null && (
                        <CardBarChart>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    marginTop: "10px"
                                }}
                            >
                                <div className="card-bar-top">
                                    <p>Token</p>
                                    <Heading as="h3">
                                        {usagePerModalList.reduce(
                                            (accumulator, currentValue) => {
                                                return accumulator + currentValue.tokenUsage
                                            },
                                            0
                                        )}
                                    </Heading>
                                </div>
                                <div className="card-bar-top">
                                    <p>Cost</p>
                                    <Heading as="h3">
                                        {Object.keys(usagePerProvider).reduce(
                                            (accumulator, currentValue) => {
                                                return accumulator + usagePerProvider[currentValue].cost
                                            },
                                            0
                                        ).toFixed(5)}$
                                    </Heading>
                                </div>
                                <ul>
                                </ul>
                            </div>
                        </CardBarChart>)}
                </Row>
                <Charts />
            </Cards>

        </>
    );
};

export default TokenUsagePerModelAndProvider;
const Charts = () => {
    return (<Row gutter={[15, 15]} >
        <Col xxl={12} lg={24} xs={24}>
            <Suspense
                fallback={
                    <Cards headless>
                        <Skeleton active />
                    </Cards>
                }
            >
                <TokenUsagePerModel />
            </Suspense>
        </Col>
        <Col xxl={12} lg={24} xs={24}>
            <Suspense
                fallback={
                    <Cards headless>
                        <Skeleton active />
                    </Cards>
                }
            >
                <TokenUsagePerProvider />
            </Suspense>
        </Col>
    </Row>)
}
