import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cards } from "../../components/cards/frame/cards-frame";
import {
  ChartjsStackedChart,
} from "../../components/charts/chartjs";
import Heading from "../../components/heading/heading";
import { CardBarChart } from "../../container/dashboard/DashboardComponents/style";
import { getTop10FlowsPass } from "../../redux/reporting/actionCreator";
import { LoadingButton } from "../../components/loading-button/loading-button";

const TopPass = ({ flowsData }) => {
  const dispatch = useDispatch();
  const { isLoadingReporting, top10PassFlowsList } = useSelector((state) => {
    return {
      isLoadingReporting: state.reporting.isLoadingReportingPassFlows,
      fetchTop10: state.reporting.fetchTop10,
      top10FailFlowsList: state.reporting.top10FailFlowsList,
      top10PassFlowsList: state.reporting.top10PassFlowsList,
    };
  });
  useEffect(() => {
    dispatch(getTop10FlowsPass());
  }, [dispatch]);
  const topPassFlowsDatasets = top10PassFlowsList !== null && [
    {
      data: top10PassFlowsList.runs,
      backgroundColor: "#AD3278",
      hoverBackgroundColor: "#ad3278ba",
      label: "Run",
      maxBarThickness: 40,
      barThickness: 32,
    },
  ];

  return (
    <>
      
        <Cards
          isbutton={<div className="card-nav"><LoadingButton
          toggleCondition={isLoadingReporting}
          onClick={() => dispatch(getTop10FlowsPass())}
        /></div>}
          title="Top Pass Active flows"
          size="large"
        >
          {isLoadingReporting ? (
            <div className="sd-spin">
              <Spin />
            </div>
          ) : 
            top10PassFlowsList !== null && (  <CardBarChart>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="card-bar-top">
                  <p>Runs</p>
                  <Heading as="h3">
                    {top10PassFlowsList.runs.reduce(
                      (accumulator, currentValue) => accumulator + currentValue,
                      0
                    )}
                  </Heading>
                </div>
                <ul>
                 {/* {top10PassFlowsList.runs.map((flow,i)=> (<li className="custom-label">
                    <span
                      style={{
                        backgroundColor: top10PassFlowsList.colors[i],
                      }}
                    />
                   {flow}
                  </li>))} */}
                  <li className="custom-label"> <span
                      style={{
                        backgroundColor: "#AD3278",
                      }}
                    />Run</li>
                </ul>
              </div>

              <ChartjsStackedChart
                labels={top10PassFlowsList.labels}
                datasets={topPassFlowsDatasets}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                  scales: {
                    yAxes: [{
                      stacked: true,
                      ticks: {
                        beginAtZero: true,// Start the scale from 0
                        stepSize:1 
                      }
                    }],},
                  layout: {
                    padding: {
                      top: 20,
                    },
                  },
                  legend: {
                    display: false,
                    position: "top",
                    align: "end",
                    labels: {
                      boxWidth: 6,
                      display: true,
                      usePointStyle: true,
                    },
                  },
                }}
              />
            </CardBarChart> )}
        </Cards>
     
    </>
  );
};

export default TopPass;
