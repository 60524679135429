import actions from './actions';

const { IS_LOADING_USERS, FETCH_USERS, SET_USERS,
    IS_LOADING_FLOWS_DETAILS, FETCH_FLOWS_DETAILS, SET_FLOWS_DETAILS
} = actions;

const initState = {
    usersList: [],
    fetchUsers: true,
    isLoadingUsers: false,
    flowsDetails: {},
    fetchFlowsDetails: true,
    isLoadingFlowsDetails: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const UsersReducer = (state = initState, action) => {
    const { type, data, status } = action;
    switch (type) {
        case IS_LOADING_USERS:
            return {
                ...state,
                isLoadingUsers: status,
            };
        case FETCH_USERS:
            return {
                ...state,
                fetchUsers: status,
            };
        case SET_USERS:
            data.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));

            return {
                ...state,
                usersList: data,
            };
        case IS_LOADING_FLOWS_DETAILS:
            return {
                ...state,
                isLoadingFlowsDetails: status,
            };
        case FETCH_FLOWS_DETAILS:
            return {
                ...state,
                fetchFlowsDetails: status,
            };
        case SET_FLOWS_DETAILS:

            return {
                ...state,
                flowsDetails: data,
            };
        default:
            return state;
    }
};

export default UsersReducer;