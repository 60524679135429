import React, { Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import RPAsRoutes from "./rpasRoutes";
// import DashboardRoutes from "./dashboardRoutes";
import CardsRoutes from "./cardsRoutes";
import KnowledgeAIRoutes from "./knowledgeAIRoutes";
import chatbotRoutes from "./chatbotRoutes";
// import ReportingRoutes from "./reportingRoutes";z

import withAdminLayout from "../../layout/withAdminLayout";
import StoriesRoutes from "./storiesRoutes";
import DeploymentRoute from "./deploymentRoute";
import TransactRoute from "./transactRoutes";
import { useSelector } from "react-redux";
import CredentialsRoute from "./credentialsRoutes";
import whtasappCardsRoute from "./whatsappCardsRoutes";
import ReportingRoutes from "./reportingRoutes";
import { decrypt } from "../../container/profile/authentication/overview/util";
import TagsRoute from "./tagsRoutes";
import TokenUsage from "../../container/token-usage/TokenUsage";
import DataSetRoutes from "./data-set-routes";

const Admin = () => {
  const { path } = useRouteMatch();
  // const { userList, } = useSelector(state => {
  //   return {
  //     userList: state.userDetails.userList,
  //   };
  // });
  // console.log({ userList });
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {
          // userType !== "superAdministrator" &&
          path === "/dashboard" &&
          <>
            <Route path={`${path}`} /* path={`${path}/rpas`} */ component={RPAsRoutes} />
            <Route path={`${path}/credentials`} component={CredentialsRoute} />

        {/* <Route path={path} component={DashboardRoutes} /> */}
        <Route path={`${path}/chatbot`} component={chatbotRoutes} />
        {/* <Route path={`${path}/cards`} component={CardsRoutes} /> */}
        {/* <Route path={`${path}/knowledge-ai`} component={KnowledgeAIRoutes} /> */}
        {/* <Route path={`${path}/whatsapp-cards`} component={whtasappCardsRoute} /> */}
        {/* <Route path={`${path}/reporting`} component={ReportingRoutes} /> */}
        <Route path={`${path}/stories`} component={StoriesRoutes} />
        <Route path={`${path}/tags`} component={TagsRoute} />

        {/*<Route path={`${path}/deployments`} component={DeploymentRoute} />
        <Route path={`${path}/stories`} component={StoriesRoutes} />
        {/* <Route path={`${path}/deployments`} component={DeploymentRoute} />
        <Route path={`${path}/transact`} component={TransactRoute} /> */}

            <Route path={`${path}/reporting`} component={ReportingRoutes} />
          </>
        }

      </Suspense >
    </Switch >
  );
};

export default withAdminLayout(Admin);
