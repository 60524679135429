import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const {
  is_loading_data_set, fetch_data_set, set_data_set,
  is_loading_docs, fetch_docs, set_docs, is_loading_get_docs
} = actions;

const getDataSet = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_data_set(true));
    dispatch(fetch_data_set(false));

    let config = {
      method: "get",
      url: process.env.REACT_APP_DATA_MATRIX_URL + "cloud/datamatrix/index/list",
      headers: { Authorization: "Bearer " + authToken },
    };

    axios(config)
      .then((response) => {
        dispatch(setDataSet(response.data));
        dispatch(is_loading_data_set(false));
        openNotificationWithIcon('success', 'Load Data Set', 'Data Set Loaded Successfully');
      })
      .catch((error) => {
        console.log("Load Data Set Error", error);
        openNotificationWithIcon('error', 'Load Data Set', 'Failed Loading Data Set');
        dispatch(is_loading_data_set(false));
      });
  };
};
const getDocs = (data) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_get_docs(true))
    dispatch(is_loading_docs(true));
    dispatch(fetch_docs(false));

    let config = {
      method: "post",
      url: process.env.REACT_APP_DATA_MATRIX_URL + "cloud/datamatrix/doc/list",
      headers: { Authorization: "Bearer " + authToken },
      data: data
    };

    axios(config)
      .then((response) => {
        dispatch(setDocs(response.data));
        dispatch(is_loading_docs(false));
        dispatch(is_loading_get_docs(false))
        openNotificationWithIcon('success', 'Load Docs', 'Docs Loaded Successfully');
      })
      .catch((error) => {
        console.log("Load Data Set Error", error);
        openNotificationWithIcon('error', 'Load Data Set', 'Failed Loading Docs');
        dispatch(is_loading_docs(false));
        dispatch(is_loading_get_docs(false))

      });
  };
};

const addDataSetToDB = (data) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_data_set(true));
    console.log({ data });

    var config = {
      method: "post",
      url: process.env.REACT_APP_DATA_MATRIX_URL + "cloud/datamatrix/index/create",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Adding Data Set", response.data.Message);
        dispatch(addDataSetLocally({ ...data, index_name: data.index_name, created_at: "now", index_size: "227b" }));
        dispatch(is_loading_data_set(false));
      })
      .catch(function (error) {
        console.log('Add Data Set error', error);
        openNotificationWithIcon("error", "Adding Data Set", "Adding Data Set Was Not Successfully !");
        dispatch(is_loading_data_set(false));
      });
  };
};
const addDocsToDB = (data) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_docs(true));

    var config = {
      method: "post",
      url: process.env.REACT_APP_DATA_MATRIX_URL + "cloud/datamatrix/doc/insert",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.Message) {
          dispatch(getDocs({
            index_name: data.vector_store.indexName
          }))
          openNotificationWithIcon("success", "Adding Docs", "Docs added Successfully");
          // dispatch(is_loading_docs(false));
        }
        else {
          openNotificationWithIcon("error", "Adding Docs", response.data.Error);
          dispatch(is_loading_docs(false));
        }
      })
      .catch(function (error) {
        console.log('Add Docs error', error);
        openNotificationWithIcon("error", "Adding Docs", "Adding Docs Was Not Successfully !");
        dispatch(is_loading_docs(false));
      });
  };
};

const addDataSetLocally = (newDataSet) => {
  return (dispatch, getState) => {
    const { dataSetList } = getState().dataSet;

    let newDataSetList = [newDataSet, ...dataSetList];
    dispatch(set_data_set(newDataSetList));
  };
};
const addDocsLocally = (newDoc) => {
  return (dispatch, getState) => {
    const { docsList } = getState().dataSet;

    let newDocList = [...docsList, newDoc];
    dispatch(set_docs(newDocList));
  };
};

const fetchDataSetToggle = () => {
  return dispatch => {
    dispatch(fetch_data_set(true));
  };
};
const fetchDocsToggle = () => {
  return dispatch => {
    dispatch(fetch_docs(true));
  };
};

const setDataSet = (newList) => {
  return dispatch => {
    dispatch(set_data_set(newList));
  };
};
const setDocs = (newList) => {
  return dispatch => {
    dispatch(set_docs(newList));
  };
};

const deleteDataSetByID = (index_name, clearFilteringInputs) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_data_set(true));

    let config = {
      method: "DELETE",
      url: process.env.REACT_APP_DATA_MATRIX_URL + "cloud/datamatrix/index/delete",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      data: {
        index_name
      }
    };

    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Deleting Data Set", response.data?.Message);
        dispatch(deleteDataSetByIDLocally(index_name));
        dispatch(is_loading_data_set(false));
        // clearFilteringInputs();
      })
      .catch(function (error) {
        openNotificationWithIcon("error", "Deleting Data Set", "Deleting Data Set Was Not Successfully !");
        console.log("Deleting Data Set Error", error.data);
        dispatch(is_loading_data_set(false));
      });
  };
};
const deleteDocByID = (data, clearFilteringInputs) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_docs(true));

    let config = {
      method: "DELETE",
      url: process.env.REACT_APP_DATA_MATRIX_URL + "cloud/datamatrix/doc/delete",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Deleting Doc", response.data?.Message);
        dispatch(deleteDocByIDLocally(data.source));
        dispatch(is_loading_docs(false));
        // clearFilteringInputs();
      })
      .catch(function (error) {
        openNotificationWithIcon("error", "Deleting Doc", "Deleting Doc Was Not Successfully !");
        console.log("Deleting Data Set Error", error.data);
        dispatch(is_loading_docs(false));
      });
  };
};
const deleteDataSetByIDLocally = (index_name) => {
  return (dispatch, getState) => {
    const { dataSetList } = getState().dataSet;
    let newDataSetList = [...dataSetList];

    newDataSetList = newDataSetList.filter(dataSet => dataSet.index_name !== index_name);
    dispatch(set_data_set(newDataSetList));
  };
};
const deleteDocByIDLocally = (source) => {
  return (dispatch, getState) => {
    const { docsList } = getState().dataSet;
    let newDocsList = [...docsList];

    newDocsList = newDocsList.filter(doc => doc.source !== source);
    dispatch(set_docs(newDocsList));
  };
};

export { getDocs, getDataSet, addDocsToDB, addDataSetToDB, fetchDocsToggle, fetchDataSetToggle, deleteDocByID, deleteDataSetByID };