import { ConfigProvider } from "antd";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader/root";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ProtectedRoute from "./components/utilities/protectedRoute";
import config from "./config/config";
import store from "./redux/store";
import Admin from "./routes/admin/allRoutes";
import SuperAdmin from "./routes/superAdmin/allRoutes";
import Auth from "./routes/auth";
import "./static/css/style.css";
import Cookies from "js-cookie";
import { getUserDetails } from "./redux/user-details/actionCreator";
import Loader from "./loader";
import axios from "axios";
import { SignOut } from "./redux/authentication/actionCreator";
import SignInSuperAdmin from "./container/profile/authentication/overview/SignInSuperAdmin";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import { decrypt } from "./container/profile/authentication/overview/util";
const { theme } = config;
const NotFound = () => {
  return (<Redirect to="/" />)
}
const expiredMessage = (message) => {
  if (message === "Signature expired" || message === "Invalid token" || message === "enter a correct Authorization bearer")
    return true
  return false
}
const CheckIfUserTokenExpired = (response) => {
  if (typeof response === "object" && response.hasOwnProperty("message") && expiredMessage(response.message))
    return true
  return false

}
const ProviderConfig = () => {
  const dispatch = useDispatch();

  const { rtl, topMenu, darkMode, isLoggedIn, firstElement } = useSelector(
    (state) => {
      return {
        darkMode: state.ChangeLayoutMode.data,
        rtl: state.ChangeLayoutMode.rtlData,
        topMenu: state.ChangeLayoutMode.topMenu,
        isLoggedIn: state.auth.login,
        firstElement: state.userDetails.firstElement,
      };
    }
  );

  const [path, setPath] = useState(window.location.pathname);
  const [token, setToken] = useState(Cookies.get("token"));

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        if (CheckIfUserTokenExpired(response.data))
          dispatch(SignOut())
        return response;
      },
      (error) => {
        // Handle response error
        console.log({ error });
        if (error.hasOwnProperty("response") && error.response && CheckIfUserTokenExpired(error.response.data))
          dispatch(SignOut())
        return Promise.reject(error);
      }
    );
  }, [])
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  useEffect(() => {
    if (Cookies.get("token") && Cookies.get("token").trim()) {
      setToken(Cookies.get("token"));
      dispatch(getUserDetails(Cookies.get("token", history)));
    }
    // eslint-disable-next-line no-return-assign
  }, [Cookies.get("token")]);
  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? (
            <Switch>
              <Route exact path="/loginSuperAdmin" component={SignInSuperAdmin} />
              <Route path="/" component={Auth} />
            </Switch>
          ) : (
            <Switch>
              <ProtectedRoute path="/dashboard" component={Admin} />

              <ProtectedRoute path="/superAdmin" component={SuperAdmin} />
              <Route path="/loginSuperAdmin" component={SignInSuperAdmin} />
              <Route path="*" component={NotFound} />
            </Switch>
          )}
          {isLoggedIn &&
            (path === process.env.PUBLIC_URL ||
              path === `${process.env.PUBLIC_URL}/`) && (
              <Redirect
                to={
                  firstElement === "Stories"
                    ? "/dashboard/stories"
                    : firstElement === "Deployments"
                      ? "/dashboard/deployments"
                      : firstElement === "Flows"
                        ? "/dashboard"
                        // : firstElement === "Knowledge AI"
                        //   ? "/dashboard/knowledge-ai"
                        : firstElement === "Chatbots"
                          ? "/dashboard/chatbot"
                          // : firstElement === "Adaptive Cards"
                          //   ? "/dashboard/cards"
                          // : firstElement === "Whatsapp Cards"
                          //   ? "/dashboard/whatsapp-cards"
                          // : firstElement === "Reporting"
                          //   ? "/dashboard/reporting"
                          : "/dashboard"
                }
              />
            )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  const hardRefreshPerformed = sessionStorage.getItem("hardRefreshPerformedAdminUI");
  const clearCacheAndReload = () => {
    console.log(
      "Clearing cache and hard reloading...",
    );

    if (caches) {
      // deleting saved cache one by one

      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }

    // after deleting cached data hard reload the site
    sessionStorage.setItem("hardRefreshPerformedAdminUI", "true");
    window.location.reload(true);
  };

  // Check if a hard refresh has been performed

  if (!hardRefreshPerformed) {
    // Set a flag to indicate that a hard refresh has been performed

    // Perform a hard refresh
    setTimeout(() => {
      console.log("innnn");
      clearCacheAndReload();
    }, 2000)


  }
  console.log({ hardRefreshPerformed });
  return (
    <Provider store={store}>
      {hardRefreshPerformed === null ? <Loader /> :
        <ProviderConfig />
      }
    </Provider>
  );
}

export default hot(App);
