import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { is_loading_knowledge_ai, fetch_knowledge_ai, set_knowledge_ai } = actions;
// const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM5IiwibmFtZSI6IkppcHNhIiwicm9sZSI6ImNsaWVudCIsImlhdCI6MTYzNjEwODcwMywiZXhwIjoxNjY3NjQ0NzAzfQ.lnikRRTe39g5RSbHKU91lM_5Aed3VfmUpMF-a7TtDdo';

const getKnowledgeAIs = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_knowledge_ai(true));
    dispatch(fetch_knowledge_ai(false));

    let config = {
      method: "get",
      // url: process.env.REACT_APP_KB_URL + "get_kbs",
      url: "kb_prod_url",
      headers: { Authorization: "Bearer " + authToken },
    };

    axios(config)
      .then((response) => {
        dispatch(setKnowledgeAIs(response.data));
        dispatch(is_loading_knowledge_ai(false));
        openNotificationWithIcon('success', 'Load Knowledge AIs', 'Knowledge AIs Loaded Successfully');
      })
      .catch((error) => {
        console.log("Load KBs Error", error);
        openNotificationWithIcon('error', 'Load Knowledge AIs', 'Failed Loading Knowledge AIs');
        dispatch(is_loading_knowledge_ai(false));
      });
  };
};

const addKnowledgeAIToDB = (description, urlToSave, handleCancelButtonClicked) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_knowledge_ai(true));

    var data = JSON.stringify({ description: description, url: urlToSave });

    var config = {
      method: "post",
      url: process.env.REACT_APP_KB_URL + "add_kb",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Saving KB", "KB Saved Successfully");
        let { kb_id } = response.data;
        handleCancelButtonClicked();
        dispatch(addKnowledgeAILocally({ description, url: urlToSave, id: kb_id }));
        dispatch(is_loading_knowledge_ai(false));
      })
      .catch(function (error) {
        console.log('Add KB error', error);
        openNotificationWithIcon("error", "Saving KB", "Saving KB Was Not Successfully !");
        dispatch(is_loading_knowledge_ai(false));
      });
  };
};

const addKnowledgeAILocally = (newKB) => {
  return (dispatch, getState) => {
    const { knowledgeAIsList } = getState().knowledgeAI;

    let newKnowledgeAIList = [...knowledgeAIsList, newKB];
    dispatch(set_knowledge_ai(newKnowledgeAIList));
  };
};

const fetchKnowledgeAIsToggle = () => {
  return dispatch => {
    dispatch(fetch_knowledge_ai(true));
  };
};

const setKnowledgeAIs = (newList) => {
  return dispatch => {
    dispatch(set_knowledge_ai(newList));
  };
};

const deleteKnowledgeAIByID = (id, clearFilteringInputs) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_knowledge_ai(true));
    const data = JSON.stringify({ id: id });

    let config = {
      method: "post",
      url: process.env.REACT_APP_KB_URL + "delete_kb",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Deleting KB", "KB Deleted Successfully");
        dispatch(deleteKnowledgeAIByIDLocally(id));
        dispatch(is_loading_knowledge_ai(false));
        clearFilteringInputs();
      })
      .catch(function (error) {
        openNotificationWithIcon("error", "Deleting KB", "Deleting KB Was Not Successfully !");
        console.log("Deleting KB Error", error.data);
        dispatch(is_loading_knowledge_ai(false));
      });
  };
};

const deleteKnowledgeAIByIDLocally = (id) => {
  return (dispatch, getState) => {
    const { knowledgeAIsList } = getState().knowledgeAI;
    let newKnowledgeAIList = [...knowledgeAIsList];

    newKnowledgeAIList = newKnowledgeAIList.filter(kb => kb.id !== id);
    dispatch(set_knowledge_ai(newKnowledgeAIList));
  };
};

export { getKnowledgeAIs, addKnowledgeAIToDB, fetchKnowledgeAIsToggle, setKnowledgeAIs, deleteKnowledgeAIByID };