const actions = {
    IS_LOADING_MODALS_AND_PROVIDERS_USAGE: "IS_LOADING_MODALS_AND_PROVIDERS_USAGE",
    SET_MODALS_AND_PROVIDERS_USAGE: "SET_MODALS_AND_PROVIDERS_USAGE",
    SET_PROVIDERS_USAGE: "SET_PROVIDERS_USAGE",
    IS_LOADING_FLOWS_USAGE: "IS_LOADING_FLOWS_USAGE",
    SET_FLOWS_USAGE: "SET_FLOWS_USAGE",
    IS_LOADING_TOKEN_USAGE_PER_FLOW: "IS_LOADING_TOKEN_USAGE_PER_FLOW",
    SET_TOKEN_USAGE_PER_FLOW: "SET_TOKEN_USAGE_PER_FLOW",
    SET_TOTAL_COST: "SET_TOTAL_COST",
    IS_LOADING_TOTAL_COST: "IS_LOADING_TOTAL_COST",
    SET_TOP_10_TOEKN_USAGE: "SET_TOP_10_TOEKN_USAGE",
    IS_LOADING_TOP_10_TOKEN_USAGE: "IS_LOADING_TOP_10_TOKEN_USAGE",
    set_top_10_token_usage: (data) => {
        return {
            type: actions.SET_TOP_10_TOEKN_USAGE,
            data: data,
        };
    },
    is_loading_top_10_token_usage: (status) => {
        return {
            type: actions.IS_LOADING_TOP_10_TOKEN_USAGE,
            status: status,
        };
    },
    set_total_cost: (data) => {
        return {
            type: actions.SET_TOTAL_COST,
            data: data,
        };
    },
    is_loading_total_cost: (status) => {
        return {
            type: actions.IS_LOADING_TOTAL_COST,
            status: status,
        };
    },
    is_loading_token_usage_per_flow: (status) => {
        return {
            type: actions.IS_LOADING_TOKEN_USAGE_PER_FLOW,
            status: status,
        };
    },
    set_token_usage_per_flow: (data) => {
        return {
            type: actions.SET_TOKEN_USAGE_PER_FLOW,
            data: data,
        };
    },
    is_loading_flows_usage: (status) => {
        return {
            type: actions.IS_LOADING_FLOWS_USAGE,
            status: status,
        };
    },
    set_flows_usage: (data) => {
        return {
            type: actions.SET_FLOWS_USAGE,
            data: data,
        };
    },
    is_loading_modals_usage: (status) => {
        return {
            type: actions.IS_LOADING_MODALS_AND_PROVIDERS_USAGE,
            status: status,
        };
    },
    set_modals_and_providers_usage: (data) => {
        return {
            type: actions.SET_MODALS_AND_PROVIDERS_USAGE,
            data: data,
        };
    },
    set_providers_usage: (data) => {
        return {
            type: actions.SET_PROVIDERS_USAGE,
            data: data,
        };
    },
};

export default actions;
