const actions = {
  IS_LOADING_ADAPTIVE_CARDS: 'IS_LOADING_ADAPTIVE_CARDS',
  FETCH_ADAPTIVE_CARDS: 'FETCH_ADAPTIVE_CARDS',
  SET_ADAPTIVE_CARDS: 'SET_ADAPTIVE_CARDS',
  IS_EDITING_ADAPTIVE_CARD: 'IS_EDITING_ADAPTIVE_CARD',

  is_loading_adaptive_cards: (status) => {
    return {
      type: actions.IS_LOADING_ADAPTIVE_CARDS,
      status: status,
    };
  },
  fetch_adaptive_cards: (status) => {
    return {
      type: actions.FETCH_ADAPTIVE_CARDS,
      status: status,
    };
  },
  set_adaptive_cards: (data) => {
    return {
      type: actions.SET_ADAPTIVE_CARDS,
      data: data,
    };
  },
  is_editing_adaptive_cards: (data) => {
    return {
      type: actions.IS_EDITING_ADAPTIVE_CARD,
      data: data,
    };
  },
};

export default actions;
