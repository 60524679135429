import actions from "./actions";

const {
  IS_LOADING_USER_DETAILS,
  FETCH_USER_DETAILS,
  SET_USER_DETAILS,
  SET_FIRST_ELEMENT,
} = actions;

const initState = {
  userList: {},
  fetchUser: true,
  isLoadingUser: true,
  firstElement: "",
};

/**
 *
 * @todo impure state mutation/explaination
 */
const userDetailsReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case IS_LOADING_USER_DETAILS:
      return {
        ...state,
        isLoadingUser: status,
      };
    case FETCH_USER_DETAILS:
      return {
        ...state,
        fetchUser: status,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userList: data,
      };
    case SET_FIRST_ELEMENT:
      return {
        ...state,
        firstElement: data,
      };
    default:
      return state;
  }
};

export default userDetailsReducer;
