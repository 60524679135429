export function validatePassword(password) {
    const errors = [];

    // Minimum length of 8 characters
    if (password.length < 8) {
        errors.push("Password must be at least 8 characters long.");
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
        errors.push("Password must contain at least one uppercase letter.");
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
        errors.push("Password must contain at least one lowercase letter.");
    }

    // Check for at least one digit
    if (!/[0-9]/.test(password)) {
        errors.push("Password must contain at least one number.");
    }

    // Check for at least one special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        errors.push("Password must contain at least one special character.");
    }

    return {
        isValid: errors.length === 0,
        errors: errors
    };
}
export function validateEmail(email) {
    // Regular expression for validating an email
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    // Test if the email matches the pattern
    return emailPattern.test(email);
  }