import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { show_upload_file_modal, is_loading_uploaded_files, fetch_uploaded_files, set_uploaded_files } = actions;

const showUploadFileModalToggle = (status) => {
  return (dispatch, getState) => {
    dispatch(show_upload_file_modal(status));
  };
};

const getUploadedFiles = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_uploaded_files(true));
    dispatch(fetch_uploaded_files(false));

    let config = {
      method: "get",
      url: process.env.REACT_APP_CARDS_URL + "images/get_image_names",
      headers: { Authorization: "Bearer " + authToken },
    };

    axios(config)
      .then((response) => {
        dispatch(setUploadedFiles(response.data));
        openNotificationWithIcon('success', 'Load Uploaded Files', 'Files Loaded Successfully');
        dispatch(is_loading_uploaded_files(false));
      })
      .catch((error) => {
        console.log("Load Uploaded File Error", error);
        openNotificationWithIcon('error', 'Load Uploaded Files', 'Failed Loading Files !');
        dispatch(is_loading_uploaded_files(false));
      });
  };
};

const fetchUploadedFilesToggle = () => {
  return dispatch => {
    dispatch(fetch_uploaded_files(true));
  };
};

const setUploadedFiles = (newList) => {
  return dispatch => {
    dispatch(set_uploaded_files(newList));
  };
};

const uploadFileToServer = (file, clearFilteringInputs) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    if (file) {
      dispatch(is_loading_uploaded_files(true));
      // let file = event.target.file //Or  event.fileList[0]
      // let file = event.target.files[0];

      const formData = new FormData();

      formData.append("file", file);
      formData.append("name", file.name);

      let config = {
        method: "post",
        url: process.env.REACT_APP_CARDS_URL + "images/api/uploads",
        headers: {
          Authorization: "Bearer " + authToken,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      axios(config)
        .then((response) => {
          openNotificationWithIcon("success", "Upload File", "File Uploaded Successfully");
          dispatch(addUploadedFileLocallyLocally({ image_name: file.name, url: '/img/' + response.data.image_name, }));
          dispatch(is_loading_uploaded_files(false));
        })
        .catch((error) => {
          console.log('Upload File Error', error)
          let errorData = error.response && error.response.data && error.response.data.err
            ? error.response.data.err
            : false;
          console.log('Upload Error errorData', error.response)

          if (errorData && errorData.includes("already exists")) {
            openNotificationWithIcon("info", "Upload File", "File Already Exists");
          }
          else if (errorData && errorData.includes("Size")) {
            openNotificationWithIcon("error", "Upload File", "Failed Uploading File (Size greater than 20 MB)");
          }
          else {
            openNotificationWithIcon("error", "Upload File", "Failed Uploading File !");
          }

          dispatch(is_loading_uploaded_files(false));
        });
    }
    clearFilteringInputs();

  }

};

const addUploadedFileLocallyLocally = (newFile) => {
  return (dispatch, getState) => {
    const { uploadedFilesList } = getState().uploadFiles;
    let newUploadedFileList = [...uploadedFilesList];

    newUploadedFileList = [...newUploadedFileList, newFile];
    dispatch(set_uploaded_files(newUploadedFileList));
  };
};

const deleteFileFromServer = (fileName, clearFilteringInputs) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_uploaded_files(true));

    let config = {
      method: "delete",
      url: process.env.REACT_APP_CARDS_URL + "images/delete_image/" + fileName,
      headers: {
        Authorization: "Bearer " + authToken,
      },
    };

    axios(config)
      .then((response) => {
        openNotificationWithIcon("success", "Delete File", "File Deleted Successfully");
        console.log('Delete File Response', response);
        dispatch(is_loading_uploaded_files(false));
        dispatch(deleteFileLocally(fileName));
        clearFilteringInputs();
      })
      .catch((error) => {
        openNotificationWithIcon("error", "Delete File", "Failed Deleting File !");
        console.log('Delete File Error', error);
        dispatch(is_loading_uploaded_files(false));
      });
  };
};

const deleteFileLocally = (fileName) => {
  return (dispatch, getState) => {
    const { uploadedFilesList } = getState().uploadFiles;
    let newUploadedFileList = [...uploadedFilesList];

    newUploadedFileList = newUploadedFileList.filter(file => file.image_name !== fileName);
    dispatch(set_uploaded_files(newUploadedFileList));
  };
};



export { showUploadFileModalToggle, getUploadedFiles, fetchUploadedFilesToggle, setUploadedFiles, uploadFileToServer, deleteFileFromServer };
