import { Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Cards } from "../../components/cards/frame/cards-frame";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main, TableWrapper, TopToolBox } from "../styled";
import { OAuth2AutheicationFlow } from "./CredOAuth2";
import { CredentialActions } from "./CredentialActions";
import { convertFilteredDataToHTML } from "./TableRow";
import Cookies from "js-cookie";

const columns = [
  { title: "Cred name", dataIndex: "credName" },
  { title: "Service", dataIndex: "service" },
  { title: "Last update", dataIndex: "lastUpdate" },
  { title: "Action", dataIndex: "action" },
];
const Credentials = () => {
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [radioGroupValue, setRadioGroupValue] = useState("All");

  useEffect(() => {
    OAuth2AutheicationFlow(dispatch);
  }, []);
  const filteredDataAsHtml = convertFilteredDataToHTML({ filteredData, dispatch, setRadioGroupValue });

  return (
    <>
      <PageHeader ghost title="Credentials" />
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={24}>
              <TopToolBox>
                <CredentialActions setFilteredData={setFilteredData} radioGroupValue={radioGroupValue} setRadioGroupValue={setRadioGroupValue} />
              </TopToolBox>
            </Col>
          </Row>
          <Row style={{ margin: "10px" }} align="end"><b>Total:&nbsp;</b>{filteredData.length}</Row>

          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-order table-responsive">
                <Table
                  columns={columns}
                  dataSource={filteredDataAsHtml}
                  pagination={{
                    pageSize: 5,
                    showSizeChanger: false,
                    total: filteredDataAsHtml.length,
                  }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};
export default Credentials;
