import React/* , { lazy } */ from "react";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import { Route, useRouteMatch ,Redirect} from "react-router-dom";
import Logs from "../../container/new-reporting/Logs";
import Metrics from "../../container/new-reporting/Metrics";
const ReportingRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}/logs`} component={Logs} />
            <Route exact path={`${path}/metrics`} component={Metrics} />
        </Switch>
    );
};

export default ReportingRoutes;
