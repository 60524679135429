const actions = {
    IS_LOADING_WHATSAPP_CARDS: 'IS_LOADING__CARDS',
    FETCH_WHATSAPP_CARDS: 'FETCH_WHATSAPP_CARDS',
    SET_WHATSAPP_CARDS: 'SET_WHATSAPP_CARDS',
    IS_EDITING_WHATSAPP_CARD: 'IS_EDITING_WHATSAPP_CARD',

    is_loading_whatsapp_cards: (status) => {
        return {
            type: actions.IS_LOADING_WHATSAPP_CARDS,
            status: status,
        };
    },
    fetch_whatsapp_cards: (status) => {
        return {
            type: actions.FETCH_WHATSAPP_CARDS,
            status: status,
        };
    },
    set_whatsapp_cards: (data) => {
        return {
            type: actions.SET_WHATSAPP_CARDS,
            data: data,
        };
    },
    is_editing_whatsapp_cards: (data) => {
        return {
            type: actions.IS_EDITING_WHATSAPP_CARD,
            data: data,
        };
    },
};

export default actions;
