import { Table } from "antd";
import FeatherIcon from "feather-icons-react";
import React, { useState } from "react";
import { Button } from "../../components/buttons/buttons";
import { ModalStyled } from "../../components/modals/styled";
import { NodeOutputModal } from "./NodeOutputModal";
import "./reporting.css";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Status",
    key: "state",
    dataIndex: "state",
  },
  {
    title: "Output",
    key: "Output",
    dataIndex: "Output",
  },
];
const ReportingModal = ({ flowDetails, setFlowDetails }) => {
  const [nodeOutput, setNodeOutput] = useState("");
  const [nodeNameAndCounter, setNodeNameAndCounter] = useState("");
  let nodeOutputList = [];
  let onlyStatus = false;
  Object.keys(flowDetails).forEach((el) => {
    if (el === "flow_status") onlyStatus = flowDetails[el];
    nodeOutputList.push({
      nodeName: el,
      nodeStatus:
        JSON.parse(flowDetails[el]).Status ||
        JSON.parse(flowDetails[el]).status,
      output:el.toLowerCase()==="error"?flowDetails[el]:
        JSON.parse(flowDetails[el]).Output ||
        JSON.parse(flowDetails[el]).Error ||
        JSON.parse(flowDetails[el]).error||JSON.parse(flowDetails[el]).Output,
    });
  });
  const onCancel = () => {
    setFlowDetails(false);
  };
  const convertFilteredNestedDataToHTML = () => {
    const newFilteredDataAsHtml = [];
    nodeOutputList.map((value, key) => {
      const { nodeName, nodeStatus, date } = value;
      return newFilteredDataAsHtml.push({
        name: (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {nodeName !== "error" && (
              <img
                style={{ marginRight: "10px" }}
                width="25px"
                height={"25px"}
                src={`/components-icons/${nodeName.replace(/\d/g, "")}.png`}
              />
            )}
            <span>{nodeName}</span>
          </div>
        ),
        state:
          nodeStatus === "FAIL" ? (
            <span className={`status ${"error"}`}>{nodeStatus}</span>
          ) : nodeStatus === "PASS" ? (
            <span className={`status ${"Success"}`}>{nodeStatus}</span>
          ) : (
            <span className={`status ${"warning"}`}>{nodeStatus}</span>
          ),

        Output: (
          <div style={{ position: "relative" }}>
            {/* Button to toggle popover */}
            <Button
              size="small"
              type="white"
              onClick={() => {
                if (nodeName !== nodeNameAndCounter) {
                  setNodeNameAndCounter(nodeName);
                  setNodeOutput(JSON.stringify(value.output, null, 2));
                } else {
                  setNodeNameAndCounter(false);
                  setNodeOutput("");
                }
              }}
            >
              <FeatherIcon
                icon={nodeName !== nodeNameAndCounter ? "code" : "skip-back"}
                size={14}
              />
            </Button>
          </div>
        ),
      });
    });
    return newFilteredDataAsHtml;
  };
  let filteredNestedDataToHTML = convertFilteredNestedDataToHTML();
  const getResultStatus = (status) => {
    return status === '"ABORTED"' ? (
      <span className={`status ${"error"}`}>{"ABORTED"}</span>
    ) : status === '"PASS"' ? (
      <span className={`status ${"Success"}`}>{"PASS"}</span>
    ) : (
      <span className={`status ${"error"}`}>{"FAIL"}</span>
    );
  };
  return (
    <>
      <ModalStyled
        title={
          <div>Flow Result {onlyStatus && getResultStatus(onlyStatus)}</div>
        }
        visible={flowDetails !== false}
        onCancel={onCancel}
        footer={null}
        className={"modal"}
        style={
          nodeOutput
            ? { transform: "translateX(-50%)", transition: "left 1.5s ease" }
            : null
        }
      >
        {nodeOutput && (
          <NodeOutputModal
            nodeName={nodeNameAndCounter}
            nodeOutput={nodeOutput}
            setNodeOutput={setNodeOutput}
            setNodeNameAndCounter={setNodeNameAndCounter}
          />
        )}
        {!onlyStatus && (
          <Table
            columns={columns}
            dataSource={filteredNestedDataToHTML}
            pagination={{
              pageSize: 5,
              showSizeChanger: false,
              total: filteredNestedDataToHTML.length,
            }}
          />
        )}
      </ModalStyled>
    </>
  );
};

export default ReportingModal;
