const actions = {
  IS_LOADING_REPORTING: 'IS_LOADING_REPORTING',
  FETCH_REPORTING: 'FETCH_REPORTING',
  SET_REPORTING: 'SET_REPORTING',
  SHOW_DELETE_MODAL: "SHOW_DELETE_MODAL",
  SHOW_MODAL: "SHOW_MODAL",
  SET_REPORTING_TOP_10_PASS:"SET_REPORTING_TOP_10_PASS",
  FETCH_TOP_10:"FETCH_TOP_10",
  SET_REPORTING_TOP_10_FAIL:"SET_REPORTING_TOP_10_FAIL",
  IS_LOADING_REPORTING_PASS:"IS_LOADING_REPORTING_PASS",
  IS_LOADING_REPORTING_FAIL:"IS_LOADING_REPORTING_FAIL",

  is_loading_reporting: (status) => {
    return {
      type: actions.IS_LOADING_REPORTING,
      status: status,
    };
  },
  is_loading_reporting_pass_flows: (status) => {
    return {
      type: actions.IS_LOADING_REPORTING_PASS,
      status: status,
    };
  },
  is_loading_reporting_fail_flows: (status) => {
    return {
      type: actions.IS_LOADING_REPORTING_FAIL,
      status: status,
    };
  },
  fetch_reporting: (status) => {
    return {
      type: actions.FETCH_REPORTING,
      status: status,
    };
  },
  fetch_reporting_top_10: (status) => {
    return {
      type: actions.FETCH_TOP_10,
      status: status,
    };
  },
  set_reporting: (data) => {
    return {
      type: actions.SET_REPORTING,
      data: data,
    };
  },
  set_reporting_top_10_pass: (data) => {
    return {
      type: actions.SET_REPORTING_TOP_10_PASS,
      data: data,
    };
  },
  set_reporting_top_10_fail: (data) => {
    return {
      type: actions.SET_REPORTING_TOP_10_FAIL,
      data: data,
    };
  },
  show_delete_modal: (status) => {
    return {
      type: actions.SHOW_DELETE_MODAL,
      status: status,
    };
  },
  show_modal: (status) => {
    return {
      type: actions.SHOW_MODAL,
      status: status,
    };
  },
};

export default actions;
