const actions = {
  IS_LOADING_RPAS: 'IS_LOADING_RPAS',
  FETCH_RPAS: 'FETCH_RPAS',
  SET_RPAS: 'SET_RPAS',
  SHOW_DELETE_MODAL: "SHOW_DELETE_MODAL",

  is_loading_rpas: (status) => {
    return {
      type: actions.IS_LOADING_RPAS,
      status: status,
    };
  },
  fetch_rpas: (status) => {
    return {
      type: actions.FETCH_RPAS,
      status: status,
    };
  },
  set_rpas: (data) => {
    return {
      type: actions.SET_RPAS,
      data: data,
    };
  },
  show_delete_modal: (status) => {
    return {
      type: actions.SHOW_DELETE_MODAL,
      status: status,
    };
  },
};

export default actions;
