export const initialOptions = ["All",
  "AirTable",
  "Asana",
  "BambooHr",
  "Brevo",
  "ClickToCall",
  "ClickToIVR",
  "ClickToTTS",
  "Clockify",
  "Discord",
  "Dropbox",
  "Excel",
  "Freshdesk",
  "Github",
  "Gmail",
  "GoogleCalendar",
  "GoogleContacts",
  "GoogleDocs",
  "GoogleDrive",
  "GoogleSheets",
  "Grafana",
  "HubSpot",
  "JSONBuilder",
  "JiraSoftware",
  "MailChimp",
  "MailerLite",
  "MissedCalls",
  "Monday",
  "MySQL",
  "Notion",
  "Odoo",
  "Onedrive",
  "OpenAI",
  "Outlook",
  "Pipedrive",
  "Postgres",
  "QuickBase",
  "Quickbooks",
  "RunCode",
  "SSHWithCommand",
  "Salesforce",
  "Shopify",
  "Slack",
  "Stripe",
  "Telegram",
  "Todo",
  "Trello",
  "WhatsappTemplate",
  "Wordpress",
  "Xero",
  "Zendesk",
  "ZiwoReports",
  "ZohoCRM",
  "Zoom"
];
export const getDate = (inputDateString) => {
  // Create a new Date object from the input date string
  const inputDate = new Date(inputDateString);
  // Extract date components
  const year = inputDate.getFullYear(); // Get the full year (e.g., 2024)
  const month = inputDate.getMonth() + 1; // Get the month (0-11), adding 1 to make it (1-12)
  const day = inputDate.getDate(); // Get the day of the month (1-31)
  // Format the date in the desired format
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
  // Output the formatted date
  return formattedDate;
};
export function formatDate(inputDate) {
  const dateObject = new Date(inputDate);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const day = dateObject
    .getDate()
    .toString()
    .padStart(2, "0");
  const hours = dateObject
    .getHours()
    .toString()
    .padStart(2, "0");
  const minutes = dateObject
    .getMinutes()
    .toString()
    .padStart(2, "0");
  // Create the formatted date string
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
  return formattedDate;
}
