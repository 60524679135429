import React, { useEffect } from 'react';
import { Collapse, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../../components/loading-button/loading-button';
import { getCredentials } from '../../../redux/credentials/actionCreator';
import Dynamic from './Dynamic';
import WebPageLoaderUrls from './WebPageLoaderUrls';
import UploadButton from './UploadButton';

const { Option } = Select;
const getKeysToDeleteRec = (arr) => {
    let keysToDelete = []
    arr.forEach(elm => {
        keysToDelete.push(elm.variableName)
        if (elm.options && elm.options.hasOwnProperty(elm.value)) {
            keysToDelete = [...keysToDelete, ...getKeysToDeleteRec(elm.options[elm.value])]
        }
    })
    return keysToDelete
}
const getKeysToUpdateRec = (arr) => {
    let keysToUpdate = {}
    arr.forEach(elm => {
        keysToUpdate = { ...keysToUpdate, [elm.variableName]: elm.value }
        if (elm.options && elm.options.hasOwnProperty(elm.value)) {
            keysToUpdate = { ...keysToUpdate, ...getKeysToUpdateRec(elm.options[elm.value]) }
        }
    })
    return keysToUpdate
}

const generateUI = (dispatch, onChangeAuto, elements, state, credentialsList, isLoadingCredentials) => {
    return Array.isArray(elements) && elements.map((element, index) => {
        switch (element.type) {
            case 'dropdown':
                return (
                    <Form.Item
                        label={element.label}
                        key={index}
                        style={{ margin: "10px 0px 0px 0px" }}
                    >
                        <Select
                            value={state[element.variableName]}
                            onChange={(value) => {
                                let keysToDelete = []
                                let keysToUpdate = {}
                                if (element.options) {
                                    keysToDelete = getKeysToDeleteRec(element.options[state[element.variableName]])
                                    keysToUpdate = getKeysToUpdateRec(element.options[value])
                                }
                                onChangeAuto(element.variableName, value, keysToDelete, keysToUpdate);
                            }}
                        >
                            {element.list.map((item, idx) => (
                                <Option key={idx} value={item.value}>
                                    {item.option}
                                </Option>
                            ))}
                        </Select>
                        {element.options
                            && state[element.variableName]
                            && generateUI(dispatch, onChangeAuto, element.options[state[element.variableName]], state, credentialsList, isLoadingCredentials)}
                    </Form.Item>
                );
            case 'textfield':
                return (
                    <Form.Item
                        label={element.label}
                        key={index}
                        style={{ margin: "10px 0px 0px 0px" }}
                    >
                        {
                            element.numberField ?
                                <InputNumber
                                    value={state[element.variableName]}
                                    onChange={(e) => onChangeAuto(element.variableName, e.target.value)}
                                /> :
                                <Input
                                    value={state[element.variableName]}
                                    placeholder={element.placeholder}
                                    onChange={(e) => onChangeAuto(element.variableName, e.target.value)}
                                />
                        }

                    </Form.Item>
                );
            case 'apicred':
                return (
                    <Form.Item
                        label={element.label}
                        style={{ margin: "10px 0px -30px 0px" }}
                        key={index}
                    >
                        {/* <Typography style={{ margin: "10px 0px" }}>Credentials</Typography> */}
                        <Row>
                            <Select
                                style={{ width: "87%" }}
                                value={state[element.variableName]}
                                onChange={(value) => onChangeAuto(element.variableName, value)}
                            >
                                {credentialsList &&
                                    credentialsList.filter(elm => elm.type === element.credType).map((item, idx) => (
                                        <Option key={idx} value={item.credName}>
                                            {item.credName}
                                        </Option>
                                    ))}
                            </Select>
                            <LoadingButton
                                toggleCondition={isLoadingCredentials}
                                onClick={() => dispatch(getCredentials())}
                            />
                        </Row>
                    </Form.Item>
                )
            case 'accordion':
                return (
                    <Collapse
                        key={index}
                        style={{ margin: "10px 0px" }}>
                        <Collapse.Panel header={element.label} key={element.variableName}>
                            <Form.Item
                                label={element.label}
                            >
                                <Input
                                    value={state[element.variableName]}
                                    placeholder={element.placeholder}
                                    onChange={(e) => onChangeAuto(element.variableName, e.target.value)}
                                />
                            </Form.Item>
                        </Collapse.Panel>
                    </Collapse>
                )
            case 'dynamic':
                return (
                    <Dynamic
                        key={index}
                        label={element.label}
                        variableName={element.variableName}
                        required={element.required}
                        state={state}
                        onChangeAuto={onChangeAuto}
                    />
                );
            case 'webPageLoader':
                return (
                    <WebPageLoaderUrls
                        key={index}
                        label={element.label}
                        variableName={element.variableName}
                        required={element.required}
                        state={state}
                        onChangeAuto={onChangeAuto}
                    />
                );
            case 'upload':
                return (
                    <UploadButton
                        key={index}
                        onChangeAuto={onChangeAuto}
                        variableName={element.variableName}
                        required={element.required}
                        accept={element.accept}
                        state={state}
                    />
                )

            default:
                return null;
        }
    });
};
const initializeState = (config) => {
    const setInitialState = (elements) => {
        let state = {};
        elements.forEach(element => {
            state[element.variableName] = element.value;
            if (element.options && element.options.hasOwnProperty(element.value)) {
                state = { ...state, ...setInitialState(element.options[element.value]) };
            }
        });
        return state;
    };
    return setInitialState(config);
};
const validationFunctions = {
    apicred: (item, state) => {
        if (state.hasOwnProperty(item.variableName) && state[item.variableName] === "None" && item.required) {
            return false;
        }
        return true;
    },
    textfield: (item, state) => {
        if (state.hasOwnProperty(item.variableName) && !state[item.variableName].trim() && item.required) {
            return false
        }
        return true
    },
    upload: (item, state) => {
        if (item.required && state.hasOwnProperty(item.variableName)) {
            const values = state[item.variableName];
            console.log({ aa: Object.values(values).every(value => value.trim()) });

            return Object.values(values).every(value => value.trim());
        }
        return true;
    },
    // dynamic: (item, state) => {

    //     if (item.required && state[item.variableName]) {
    //         const values = state[item.variableName];
    //         if (values.length === 0) {
    //             return false;
    //         }
    //         return values.every(elt => elt.trim());
    //     }
    //     return true;
    // }
}
export const validateArray = (apiRes, state) => {
    let valid = true;
    const validateItem = (item) => {

        if (validationFunctions[item.type]) {
            valid = validationFunctions[item.type](item, state) && valid;
        }
        if (item.options && item.options.hasOwnProperty(state[item.variableName])) {
            item.options[state[item.variableName]].forEach((nestedItem) => validateItem(nestedItem));
        }
    };

    apiRes.forEach((item) => {
        validateItem(item);
    });

    return valid;
};
const Automation = ({ config, autoState, setAutoState }) => {
    const dispatch = useDispatch();
    const { credentialsList, isLoadingCredentials } = useSelector((state) => {
        return {
            isLoadingCredentials: state.credentials.isLoadingCredentials,
            credentialsList: state.credentials.credentialsList,
        };
    });
    const onChangeAuto = (variableName, value, keysToDelete, keysToUpdate) => {
        setAutoState(prevState => {
            const updatedState = { ...prevState };
            if (keysToDelete && keysToDelete.length > 0) {
                keysToDelete.forEach(key => {
                    delete updatedState[key];
                });
            }
            updatedState[variableName] = value;
            return { ...updatedState, ...keysToUpdate };
        });
    }
    console.log({ autoState });
    useEffect(() => {
        setAutoState(initializeState(config));
    }, [config]);
    return (
        <Form layout="vertical">
            {generateUI(dispatch, onChangeAuto, config, autoState, credentialsList, isLoadingCredentials)}
        </Form>
    );
};

export default Automation;
