const actions = {
  MODAL_NAME: "MODAL_NAME",
  ADD_CREDENTIAL: "ADD_CREDENTIAL",
  EDIT_CREDENTIAL: "EDIT_CREDENTIAL",
  DELETE_CREDENTIAL: "DELETE_CREDENTIAL",
  GET_CREDENTIALS: "GET_CREDENTIALS",
  IS_LOADING_CREDENTIALS: "IS_LOADING_CREDENTIALS",
  FETCH_CREDENTIALS: "FETCH_CREDENTIALS",
  is_loading_credentials: (status) => {
    return {
      type: actions.IS_LOADING_CREDENTIALS,
      status: status
    }
  },
  fetch_credentials: (status) => {
    return {
      type: actions.FETCH_CREDENTIALS,
      status: status
    }
  },
  set_modal_name: (status) => {
    return {
      type: actions.MODAL_NAME,
      status: status,
    };
  },
  add_credential: (data) => {
    return {
      type: actions.ADD_CREDENTIAL,
      data: data,
    }
  },
  edit_credential: (data) => {
    return {
      type: actions.EDIT_CREDENTIAL,
      data: data,
    }

  },
  delete_credential: (data) => {
    return {
      type: actions.DELETE_CREDENTIAL,
      data: data
    }
  },
  set_credentials: (data) => {
    return {
      type: actions.GET_CREDENTIALS,
      data: data

    }

  }
}
export default actions;
