import actions from './actions';

const { IS_LOADING_KNOWLEDGE_AIS, FETCH_KNOWLEDGE_AIS, SET_KNOWLEDGE_AIS } = actions;

const initState = {
  knowledgeAIsList: [],
  fetchKnowledgeAIs: true,
  isLoadingKnowledgeAIs: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const knowledgeAIReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case IS_LOADING_KNOWLEDGE_AIS:
      return {
        ...state,
        isLoadingKnowledgeAIs: status,
      };
    case FETCH_KNOWLEDGE_AIS:
      return {
        ...state,
        fetchKnowledgeAIs: status,
      };
    case SET_KNOWLEDGE_AIS:
      data.sort((a, b) => (a.description.toUpperCase() > b.description.toUpperCase()) ? 1 : ((b.description.toUpperCase() > a.description.toUpperCase()) ? -1 : 0));

      return {
        ...state,
        knowledgeAIsList: data,
      };
    default:
      return state;
  }
};

export default knowledgeAIReducer;