import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { is_loading_reporting,is_loading_reporting_fail_flows,is_loading_reporting_pass_flows, fetch_reporting, set_reporting, show_delete_modal, show_modal,set_reporting_top_10_fail,set_reporting_top_10_pass,fetch_reporting_top_10 } = actions;
function generateRandomColor() {
  // Generate random values for red, green, and blue
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Format the RGB values into a CSS color string
  const color = `rgb(${red}, ${green}, ${blue})`;

  return color;
}

const getReporting = (start_date,end_date,status,flow_name) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_reporting(true));
    dispatch(fetch_reporting(false));
    let config = {
      method: 'post',
      url: process.env.REACT_APP_ELASTIC_SEARCH_URL+'elastic/result/get_results',
      headers: {
        Authorization: "Bearer " + authToken,
      },
      data: {
      start_date,end_date,status,flow_name
}
    };

    axios(config)
      .then((response) => {
        dispatch(setReporting(response.data.documents));
        dispatch(is_loading_reporting(false));
        openNotificationWithIcon('success', 'Load Reporting', 'Reporting Loaded successfully');
        dispatch(is_loading_reporting(false));
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Load Reporting', "Failed Loading Reporting");
        console.log('Load Reporting Error', error);
        dispatch(is_loading_reporting(false));
      });
  };
};
const getTop10FlowsPass = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_reporting_pass_flows(true));
    dispatch(fetch_reporting_top_10(false));
    let config = {
      method: 'get',
      url: process.env.REACT_APP_ELASTIC_SEARCH_URL+'elastic/result/top_pass',
      headers: {
        Authorization: "Bearer " + authToken,
      },
      
    };

    axios(config)
      .then((response) => {
        let flowsData={runs:[],labels:[],colors:[]}
        response.data.data.forEach(r=>{
          if(r.pass_count>0)
          flowsData={...flowsData,labels:[...flowsData.labels,r.flow_name],runs:[...flowsData.runs,r.pass_count],colors:[...flowsData.colors,generateRandomColor()]}
        })
        dispatch(set_reporting_top_10_pass(flowsData));
        dispatch(is_loading_reporting_pass_flows(false));
        openNotificationWithIcon('success', 'Load Top 10 Pass', 'Top 10 flows Loaded successfully');
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Load Reporting', "Failed Loading Reporting");
        console.log('Load Reporting Error', error);
        dispatch(is_loading_reporting_pass_flows(false));
      });
  };
};
const getTop10FlowsFail = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_reporting_fail_flows(true));
    dispatch(fetch_reporting_top_10(false));
    let config = {
      method: 'get',
      url: process.env.REACT_APP_ELASTIC_SEARCH_URL+'elastic/result/top_fail',
      headers: {
        Authorization: "Bearer " + authToken,
      },
      
    };

    axios(config)
      .then((response) => {
        let flowsData={runs:[],labels:[],colors:[]}
        response.data.data.forEach(r=>{
          if(r.fail_count>0)
          flowsData={...flowsData,labels:[...flowsData.labels,r.flow_name],runs:[...flowsData.runs,r.fail_count],colors:[...flowsData.colors,generateRandomColor()]}
        })
        dispatch(set_reporting_top_10_fail(flowsData));
        dispatch(is_loading_reporting_fail_flows(false));
        openNotificationWithIcon('success', 'Load Top 10 Fail', 'Top 10 flows Loaded successfully');
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Load Reporting', "Failed Loading Reporting");
        console.log('Load Reporting Error', error);
        dispatch(is_loading_reporting_fail_flows(false));
      });
  };
};
const fetchReportingToggle = () => {
  return dispatch => {
    dispatch(fetch_reporting(true));
  };
};
const showDeleteToggle = (status) => {
  return (dispatch, getState) => {
    dispatch(show_delete_modal(status));
  };
};
const setShowModal = (status) => {
  return (dispatch, getState) => {
    dispatch(show_modal(status));
  };
};

const setReporting = (newList) => {
  return dispatch => {
    dispatch(set_reporting(newList));
  };
};




export { getReporting, fetchReportingToggle, setReporting, showDeleteToggle, setShowModal,getTop10FlowsFail,getTop10FlowsPass };
