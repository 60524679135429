import actions from './actions';

const { IS_LOADING_REPORTING,IS_LOADING_REPORTING_FAIL,IS_LOADING_REPORTING_PASS, FETCH_REPORTING,FETCH_TOP_10, SET_REPORTING,SET_REPORTING_TOP_10_PASS,SET_REPORTING_TOP_10_FAIL, SHOW_DELETE_MODAL, SHOW_MODAL } = actions;

const initState = {
  reportingList: [],
  top10PassFlowsList:null,
  top10FailFlowsList:null,
  fetchTop10:true,
  fetchReporting: true,
  isLoadingReporting: false,
  isLoadingReportingFailFlows:false,
  isLoadingReportingPassFlows:false,
  showDeleteModal: false,
  showModal: false,

};

/**
 *
 * @todo impure state mutation/explaination
 */
const reportingReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case IS_LOADING_REPORTING:
      return {
        ...state,
        isLoadingReporting: status,
      };
      case IS_LOADING_REPORTING_FAIL:
      return {
        ...state,
        isLoadingReportingFailFlows: status,
      };
      case IS_LOADING_REPORTING_PASS:
      return {
        ...state,
        isLoadingReportingPassFlows: status,
      };
    case FETCH_REPORTING:
      return {
        ...state,
        fetchReporting: status,
      };
      case FETCH_TOP_10:
      return {
        ...state,
        fetchTop10: status,
      };
    case SET_REPORTING:
      // data.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
      return {
        ...state,
        reportingList: data.slice().sort((a, b) => new Date(b.date) - new Date(a.date)).map(elm => {
          if (!elm.hasOwnProperty("flow_name")) {
            return { ...elm, flow_name: "Draft" }
          }
          if (!elm.hasOwnProperty("End")) {
            return { ...elm, End: {} }
          }
          else {
            return { ...elm }
          }
        })
      };
      case SET_REPORTING_TOP_10_PASS:
        return {
          ...state,
          top10PassFlowsList: data
        };
        case SET_REPORTING_TOP_10_FAIL:
          return {
            ...state,
            top10FailFlowsList: data
          };
    case SHOW_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: status,
      };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: status,
      };
    default:
      return state;
  }
};

export default reportingReducer;