import { Alert, Input } from "antd";
import FeatherIcon from "feather-icons-react";
import React /* useState */ from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Popover } from "../../popup/popup";
import { InfoWraper, /* NavAuth, */ UserDropDwon } from "./auth-info-style";
// import { Dropdown } from '../../dropdown/dropdown';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import { useState } from "react";
import { SignOut, changePassword } from "../../../redux/authentication/actionCreator";
import Heading from "../../heading/heading";
import { ModalStyled } from "../../modals/styled";
import { ReactComponent as Key } from './key.svg';
import { validatePassword } from "../../../functions";


const AuthInfo = (props) => {
  const dispatch = useDispatch();
  // const [state, setState] = useState({ flag: 'english', });
  // const { flag } = state;

  const { username, email } = useSelector((state) => {
    return {
      username: state.auth.username,
      email: state.auth.email,
    };
  });
  const [open, setOpen] = useState(false)

  const SigningOut = (event) => {
    event.preventDefault()
    dispatch(SignOut())
  };
  const userContent = (
    <UserDropDwon>
      <ChangePasswordModal open={open} setOpen={setOpen} />
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {/* <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" /> */}
          <figcaption>
            <Heading as="h5">{username}</Heading>
            <p>{email}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="#">
              <FeatherIcon icon="user" /> {"Profile"}
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => {
              setOpen(true)
            }}>
              <Key /> {"Change Password"}
            </Link>
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> {"Settings"}
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> {"Billing"}
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> {"Activity"}
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> {"Help"}
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SigningOut} to="#">
          <FeatherIcon icon="log-out" />
          {"Sign Out"}
        </Link>
      </div>
    </UserDropDwon>
  );

  /* const onFlagChangeHandle = value => {
    setState({ ...state, flag: value, });
  };
 */

  /*  const country = (
     <NavAuth>
       <Link onClick={() => onFlagChangeHandle('english')} to="#">
         <img src={require('../../../static/img/flag/english.png')} alt="" />
         <span>English</span>
       </Link>
       <Link onClick={() => onFlagChangeHandle('germany')} to="#">
         <img src={require('../../../static/img/flag/germany.png')} alt="" />
         <span>Germany</span>
       </Link>
       <Link onClick={() => onFlagChangeHandle('spain')} to="#">
         <img src={require('../../../static/img/flag/spain.png')} alt="" />
         <span>Spain</span>
       </Link>
       <Link onClick={() => onFlagChangeHandle('turky')} to="#">
         <img src={require('../../../static/img/flag/turky.png')} alt="" />
         <span>Turky</span>
       </Link>
     </NavAuth>
   );
  */

  return (
    <InfoWraper>
      {/* <Message />
      <Notification /> */}
      {/* <Support /> */}
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
        <Link to="#" className="head-example">
        <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
        </Link>
        </Dropdown>
        </div>
      */}
      {/* <Settings {...props} /> */}
      {/* <SwitchMode {...props} /> */}
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            {/* <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" /> */}
            <div
              style={{
                color: "white",
                borderRadius: "50%",
                width: "45px",
                height: "45px",
                background: "#AD3278",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "19px",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              {Cookies.get("username")?.charAt(0)}
            </div>
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
const ChangePasswordModal = ({ setOpen, open }) => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const onOk = () => {
    const result = validatePassword(password.trim());
    if (!result.isValid) {
      console.log("Password is invalid");
      return setError(result.errors);
    }
    setIsLoading(true)
    dispatch(changePassword(password.trim())).then(res => {
      if (res) {
        setOpen(false)
        setError(false)
        setPassword("")
      }else{
        setError(["Changing password failed try again."]);
      }
      setIsLoading(false)

    })
  };
  const onCancel = () => {
    setError(false)
    setOpen(false);
    setPassword("")
  };
  return (
    <ModalStyled
      title={
        <div>Change Password </div>
      }
      visible={open}
      onCancel={onCancel}
      confirmLoading={isLoading}
      onOk={onOk}
      className={"modal"}
    >
      <Input.Password
        className="form-control"
        placeholder="New password"
        value={password}

        onChange={(event) => { setPassword(event.target.value) }}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <p style={{ fontSize: "0.7rem", color: "#6799fd", marginTop: "3px", marginLeft: "3px" }}>Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character</p>
      {error &&
        <Alert style={{ marginTop: "10px", whiteSpace:"pre-line" }} type="error" message={error.join("\n")} banner />}


    </ModalStyled>
  );
};