import actions from './actions';

const { SHOW_UPLOAD_FILE_MODAL, IS_LOADING_UPLOADED_FILES, FETCH_UPLOADED_FILES, SET_UPLOADED_FILES } = actions;

const initState = {
  showUploadFileModal: false,
  uploadedFilesList: [],
  fetchUploadedFilesList: true,
  isLoadingUploadedFiles: true,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const uploadFilesReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case SHOW_UPLOAD_FILE_MODAL:
      return {
        ...state,
        showUploadFileModal: status,
      };
    case IS_LOADING_UPLOADED_FILES:
      return {
        ...state,
        isLoadingUploadedFiles: status,
      };
    case FETCH_UPLOADED_FILES:
      return {
        ...state,
        fetchUploadedFilesList: status,
      };
    case SET_UPLOADED_FILES:
      data.sort((a, b) => (a.image_name.toUpperCase() > b.image_name.toUpperCase()) ? 1 : ((b.image_name.toUpperCase() > a.image_name.toUpperCase()) ? -1 : 0));
      return {
        ...state,
        uploadedFilesList: data,
      };
    default:
      return state;
  }
};

export default uploadFilesReducer;