import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Card, Row, Col, Popconfirm, Typography, Switch, Tooltip, Avatar } from "antd";
import FeatherIcon from "feather-icons-react";
import { deactivatedRPAByToken } from "../../redux/rpas/actionCreator";
import { Button } from "../../components/buttons/buttons";
import classes from "./style.module.css"
import { ButtonFlowCardStyled } from "../../components/buttons/styled";
import { timeAgo } from "../credentials/TableRow";
// const { Meta } = Card;

function truncateString(inputString, maxLength) {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.substring(0, maxLength) + "...";
  }
}

const FlowCard = ({
  flowName,
  status,
  handleNewOrEditActionClick,
  token,
  deleteFlow,
  id,
  trigger_type,
  flow_type,
  flow_apps,
  tags,
  date,
  clearFilteringInputs
}) => {
  return (
    <Col xs={24} sm={24} md={24} lg={12} xxl={8}>
      <Card
        className={classes.card}
        style={{
          minWidth: 250,
          border: "2px solid #e5e4e4",
          position: "relative"
          // minHeight: 200
        }}
      // actions={actions}
      >
        <Row style={{ display: "flex" }}>
          <Col span={4}>
            <div
              style={{
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
              }}
            >
              <img src={`./components-icons/${flow_type}.png`} style={{ width: "45px" }} alt="" />
            </div>
          </Col>
          <Col span={10} style={{ display: "flex", alignItems: "center" }}>
            <Avatar.Group maxCount={3} maxStyle={{ margin: "4px 10px", width: "2.2em", height: "2.2em", display: "flex", alignItems: "center", justifyContent: "center", background: "#ad3278", fontSize: "0.6rem" }} >
              {flow_apps && flow_apps.map((elm, ind) => {
                return (
                  <Avatar style={{ width: "1.8em", height: "1.8em", marginLeft: ind === 0 ? "-10px" : "5px" }} shape="square" src={`./components-icons/${elm}.png`} />
                )
              })}
            </Avatar.Group>
          </Col>
          <Col span={10} align="end" style={{ display: "flex", flexDirection: "column" }}>
            {date && (
              <Typography.Text type="secondary">
                {timeAgo(date)}
              </Typography.Text>
            )}
            <div
              style={{
                background: "white",
                width: "1.8em",
                height: "1.8em",
                borderRadius: "10px",
                marginTop: "4px"
              }}
            >
              {status === "Active" ?
                <Tooltip placement="left" title={"Active"}>
                  <img src={"./activated.png"} style={{ width: "1.8em", position: "absolute", left: "80%" }} alt="" />
                </Tooltip>

                :
                <Tooltip placement="left" title={"InActive"}>
                  <img src={"./inactive.png"} style={{ transform: "rotate(170deg)", width: "1.8em", position: "absolute", left: "80%" }} alt="" />
                </Tooltip>
              }
            </div>
            {/* {status === "Active" &&
              <div >
                <span>
                  {" "}
                  {status === "Invalid" ? (
                    <span className={`status ${"error"}`}>{status}</span>
                  ) : status === "Active" ? (
                    <span className={`status ${"Success"}`}>{status}</span>
                  ) : (
                    <span className={`status ${"warning"}`}>{status}</span>
                  )}
                </span>
              </div>
            } */}
          </Col>
        </Row>
        <Row
          style={{ marginTop: "15px", display: "flex", alignItems: "center" }}
        >
          <span
            style={{
              maxWidth: "100%",
              maxHeight: "20px",
              fontSize: "19px",
              fontWeight: 600,
            }}
          >
            <Tooltip
              title={flowName.length > 25 ? flowName : null}
              placement="topRight"
            >
              {truncateString(flowName, 25)}
            </Tooltip>
          </span>
        </Row>
        <Row style={{ marginTop: "25px" }}>
          <Col span={18} >
            {tags && tags.slice(0, 3).map((elm, ind) => {
              return (
                <Tooltip placement="bottom" title={elm}>
                  <Typography className={classes.tagStyle}>{truncateString(elm, 7)}</Typography>
                </Tooltip>
              )
            })}
            {tags && tags.length > 3 &&
              <Avatar className={classes.restStyle}>
                +{tags.length - 3}
              </Avatar>
            }
          </Col>
          <Col span={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: 'end' }}  >
            <div style={{ display: 'flex' }} className="table-actions">
              <ButtonFlowCardStyled style={{ border: "none" }} className="btn-icon" type="info" shape="circle" onClick={() => handleNewOrEditActionClick(token)}>
                <FeatherIcon icon="maximize-2" size={16} />
              </ButtonFlowCardStyled>
              <Popconfirm
                icon={
                  <CloseCircleOutlined style={{ color: "rgb(173, 50, 120)" }} />
                }
                placement="top"
                title="Delete this Flow"
                onConfirm={() => {
                  deleteFlow(status, id)
                }}
                okText="Yes"
                cancelText="No"
              >
                <ButtonFlowCardStyled style={{ border: "none" }} className="btn-icon" type="info" shape="circle">
                  <FeatherIcon icon="trash-2" size={16} />
                </ButtonFlowCardStyled>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Card>
    </Col >
  );
};
export default FlowCard;
