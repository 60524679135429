const actions = {
  IS_LOADING_USER_DETAILS: 'IS_LOADING_USER_DETAILS',
  FETCH_USER_DETAILS: 'FETCH_USER_DETAILS',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  SET_FIRST_ELEMENT: 'SET_FIRST_ELEMENT',

  is_loading_user_details: (status) => {
    return {
      type: actions.IS_LOADING_USER_DETAILS,
      status: status,
    };
  },
  fetch_user_details: (status) => {
    return {
      type: actions.FETCH_USER_DETAILS,
      status: status,
    };
  },
  set_user_details: (data) => {
    return {
      type: actions.SET_USER_DETAILS,
      data: data,
    };
  },
  set_first_element: (data) => {
    return {
      type: actions.SET_FIRST_ELEMENT,
      data: data,
    };
  },
};

export default actions;
