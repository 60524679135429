import actions from './actions';

const {
  IS_LOADING_DATA_SET, FETCH_DATA_SET, SET_DATA_SET,
  IS_LOADING_DOCS, FETCH_DOCS, SET_DOCS, IS_LOADING_GET_DOCS
} = actions;

const initState = {
  dataSetList: [],
  fetchDataSet: true,
  isLoadingDataSet: false,
  docsList: [],
  fetchDocs: true,
  isLoadingDocs: false,
  isLoadingGetDocs: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const DataSetReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case IS_LOADING_GET_DOCS:
      return {
        ...state,
        isLoadingGetDocs: status,
      };
    case IS_LOADING_DATA_SET:
      return {
        ...state,
        isLoadingDataSet: status,
      };
    case FETCH_DATA_SET:
      return {
        ...state,
        fetchDataSet: status,
      };
    case SET_DATA_SET:
      // data.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));

      return {
        ...state,
        dataSetList: data,
      };
    case IS_LOADING_DOCS:
      return {
        ...state,
        isLoadingDocs: status,
      };
    case FETCH_DOCS:
      return {
        ...state,
        fetchDocs: status,
      };
    case SET_DOCS:
      // data.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));

      return {
        ...state,
        docsList: data,
      };
    default:
      return state;
  }
};

export default DataSetReducer;