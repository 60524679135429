import actions from './actions';

const { IS_LOADING_BOTS, FETCH_BOTS, SET_BOTS, SELECTED_TYPE } = actions;

const initState = {
  botsList: [],
  fetchBots: true,
  isLoadingBots: true,
  selectedType: "Whatsapp",
};

/**
 *
 * @todo impure state mutation/explaination
 */
const chatbotsReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case IS_LOADING_BOTS:
      return {
        ...state,
        isLoadingBots: status,
      };
    case FETCH_BOTS:
      return {
        ...state,
        fetchBots: status,
      };
    case SET_BOTS:
      data.sort((a, b) => (a.flow_name.toUpperCase() > b.flow_name.toUpperCase()) ? 1 : ((b.flow_name.toUpperCase() > a.flow_name.toUpperCase()) ? -1 : 0));
      return {
        ...state,
        botsList: data,
      };
    case SELECTED_TYPE:
      return {
        ...state,
        selectedType: status,
      };
    default:
      return state;
  }
};

export default chatbotsReducer;