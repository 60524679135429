import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { is_loading_whatsapp_cards, fetch_whatsapp_cards, set_whatsapp_cards, is_editing_whatsapp_cards } = actions;

const getWhatsappCards = () => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        dispatch(is_loading_whatsapp_cards(true));
        dispatch(fetch_whatsapp_cards(false));

        let config = {
            method: "get",
            url: process.env.REACT_APP_CARDS_URL + "card/get_cards",
            headers: { Authorization: "Bearer " + authToken },
        };

        axios(config)
            .then((response) => {
                dispatch(setWhatsappCards(response.data));
                openNotificationWithIcon('success', 'Load Whatsapp Cards', 'Whatsapp Cards Loaded Successfully');
                dispatch(is_loading_whatsapp_cards(false));
            })
            .catch((error) => {
                console.log('Whatsapp Cards Error', error);
                openNotificationWithIcon('error', 'Load Whatsapp Cards', 'Failed Loading Whatsapp Cards !');
                dispatch(is_loading_whatsapp_cards(false));
            });
    };
};

const fetchWhatsappCardsToggle = () => {
    return dispatch => {
        dispatch(fetch_whatsapp_cards(true));
    };
};

const setWhatsappCards = (newList) => {
    return dispatch => {
        dispatch(set_whatsapp_cards(newList));
    };
};

const deleteWhatsappCardByName = (cardname, clearFilteringInputs) => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        dispatch(is_loading_whatsapp_cards(true));
        let config = {
            method: "delete",
            url: process.env.REACT_APP_CARDS_URL + "card/delete_card/" + cardname,
            headers: { Authorization: "Bearer " + authToken },
        };

        axios(config)
            .then(function (response) {
                openNotificationWithIcon("success", "Deleting Card", "Card Deleted Successfully");
                dispatch(deleteWhatsappCardByNameLocally(cardname));
                dispatch(is_loading_whatsapp_cards(false));
                clearFilteringInputs();
            })
            .catch(function (error) {
                openNotificationWithIcon("error", "Deleting Card", "Deleting Card Was Not Successfully !");
                dispatch(is_loading_whatsapp_cards(false));
                console.log(error);
            });
    };
};

const saveOrUpdateWhatsappCard = (newWhatsappCard, variables, handleCancelButtonClicked) => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        dispatch(is_loading_whatsapp_cards(true));
        const { isEditingWhatsappCard } = getState().whatsappCards;
        const { cardid, cardname, cardDetails } = newWhatsappCard;

        // let newCardPayload = { ...newBody, version: version };
        let newCard = {
            card_type: "whatsappCard",
            name: cardname.trim(),
            card: cardDetails,
            variables: variables && variables.length > 0 ? variables : null,
        };

        if (isEditingWhatsappCard) {
            newCard = {
                ...newCard,
                // id: cardItem.cardid
                id: cardid
            };
        }
        // console.log("newCard", newCard);
        let config = isEditingWhatsappCard
            ? {
                method: "put",
                url: process.env.REACT_APP_CARDS_URL + "card/update/",
                data: newCard,
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            }
            : {
                method: "post",
                url: process.env.REACT_APP_CARDS_URL + "card/add_card/",
                data: newCard,
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            };


        axios(config)
            .then((response) => {
                openNotificationWithIcon("success", "Saving Card", "Card Saved Successfully");
                handleCancelButtonClicked();
                dispatch(fetch_whatsapp_cards(true))
                let { card_id } = response.data;
                isEditingWhatsappCard
                    ? dispatch(updateWhatsappCardLocally({ ...newWhatsappCard, variables }))
                    : dispatch(addWhatsappCardLocally({ ...newWhatsappCard, variables, cardid: card_id }));
                dispatch(is_loading_whatsapp_cards(false));
                dispatch(isEditingWhatsappCardToggled(false));
            })
            .catch((error) => {
                console.log("Saving Card Error", error);
                openNotificationWithIcon("error", "Saving Card", "Failed Saving Card !");
                dispatch(is_loading_whatsapp_cards(false));
            });
    };
};

const addWhatsappCardLocally = (newWhatsappCard) => {
    return (dispatch, getState) => {
        console.log("Saving Card L");
        const { whatsappCardsList } = getState().whatsappCards;
        let newWhatsappCardsList = [...whatsappCardsList];

        newWhatsappCardsList = [...newWhatsappCardsList, newWhatsappCard];
        dispatch(set_whatsapp_cards(newWhatsappCardsList));
    };
};


const updateWhatsappCardLocally = (newWhatsappCard) => {
    return (dispatch, getState) => {

        const { cardid } = newWhatsappCard;
        const { whatsappCardsList } = getState().whatsappCards;
        let newWhatsappCardsList = [...whatsappCardsList];
        let newCardIndex = newWhatsappCardsList.findIndex(card => card.cardid === cardid);
        newWhatsappCardsList[newCardIndex] = newWhatsappCard;

        dispatch(set_whatsapp_cards(newWhatsappCardsList));
    };
};

const deleteWhatsappCardByNameLocally = (cardname) => {
    return (dispatch, getState) => {
        const { whatsappCardsList } = getState().whatsappCards;
        let newWhatsappCardsList = [...whatsappCardsList];

        newWhatsappCardsList = newWhatsappCardsList.filter(card => card.cardname !== cardname);
        dispatch(set_whatsapp_cards(newWhatsappCardsList));
    };
};

const isEditingWhatsappCardToggled = (data) => {
    return (dispatch, getState) => {
        dispatch(is_editing_whatsapp_cards(data));
    };
};



export { getWhatsappCards, fetchWhatsappCardsToggle, setWhatsappCards, deleteWhatsappCardByName, saveOrUpdateWhatsappCard, isEditingWhatsappCardToggled };
