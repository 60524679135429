import { Col, Row, Pagination, Empty } from "antd";
import FeatherIcon from "feather-icons-react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { Button } from "../../components/buttons/buttons";
import { LoadingButton } from "../../components/loading-button/loading-button";
import openNotificationWithIcon from "../../components/notification/notification";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Popover } from "../../components/popup/popup";
import { deleteRPAByID, fetchRPAsToggle, getRPAs } from "../../redux/rpas/actionCreator";
import { Main, TopToolBox } from "../styled";
import DeleteModal from "../delete-modal/delete-modal"
import FlowCard from "./FlowCard";
import FlowsFilter from "./FlowsFilter";
import { Cards } from "../../components/cards/frame/cards-frame";

const RPAs = () => {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    const [autoCompleteList, setAutoCompleteList] = useState([]);
    const [radioGroupValue, setRadioGroupValue] = useState('All');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // const triggerTypeAndStatusFilterKey = ["Active", "Inactive", "Invalid", "All", "ChatBot", "IMAP", "Webhook"];

    const { isLoadingRPAs, fetchRPAs, rpasList, keyVaultIamDataAzure, keyVaultIamDataAws/* , keyVaultAdminData */, email, token } = useSelector(state => {
        return {
            isLoadingRPAs: state.rpas.isLoadingRPAs,
            fetchRPAs: state.rpas.fetchRPAs,
            rpasList: state.rpas.rpasList,
            keyVaultIamDataAzure: state.keyVault.keyVaultIamDataAzure,
            keyVaultIamDataAws: state.keyVault.keyVaultIamDataAws,
            email: state.auth.email,
            token: state.auth.token

            // keyVaultAdminData: state.keyVault.keyVaultAdminData,
        };
    });

    useEffect(() => {
        if (fetchRPAs) {
            clearFilteringInputs();
            dispatch(getRPAs());
        }
        else {
            setFilteredData(rpasList.sort((a, b) => new Date(b.date_modified) - new Date(a.date_modified)));
        }
    }, [fetchRPAs, rpasList]);// eslint-disable-line

    const handleSearch = (searchText) => {
        const newFilteredData = rpasList.filter((value) =>
            value.name.toUpperCase().startsWith(searchText.toUpperCase())
            || value.flow_type.toUpperCase().startsWith(searchText.toUpperCase())
        );

        const title = newFilteredData.map((e) => {
            return {
                title: e.name,
            };
        });

        setFilteredData(newFilteredData.sort((a, b) => new Date(b.date_modified) - new Date(a.date_modified)));
        setAutoCompleteList(title);
        setRadioGroupValue('All');
        setSearchText(searchText);
    };
    const handleChangeForFilterTriggerTypeAndStatus = (event) => {
        let { value } = event.target;
        // let value = event;
        setSearchText('');
        setRadioGroupValue(value);

        let newFilteredData = [];
        if (value === "All") {
            newFilteredData = [...rpasList];
        }
        else if (value === 'ChatBot' || value === 'Webhook') {
            value = value + 'Trigger';
            newFilteredData = rpasList.filter((rpa) => rpa.flow_type === value);
            // newFilteredData = rpasList.filter((rpa) => rpa.trigger_type === value && !rpa.authentication.hasOwnProperty("type"));
        }
        else if (value === "scheduler") {
            newFilteredData = rpasList.filter((rpa) => rpa.flow_type === "CronJobTrigger");
            // newFilteredData = rpasList.filter((rpa) => rpa.authentication?.type === value);
        }
        else {// value = 'Active' || value === 'Inactive' || value === 'Invalid'
            newFilteredData = rpasList.filter((rpa) => rpa.status === value);
        }

        setFilteredData(newFilteredData.sort((a, b) => new Date(b.date_modified) - new Date(a.date_modified)));
    };

    function clearFilteringInputs() {
        setSearchText('');
        setRadioGroupValue('All');
        setAutoCompleteList([]);
    };

    const handleNewOrEditActionClick = (rpaToken) => {
        if (rpaToken) {
            // Cookies.set('rpaToken', rpaToken);

            Cookies.set('azureKeyVaultIamData', keyVaultIamDataAzure);
            Cookies.set('keyVaultIamDataAws', keyVaultIamDataAws);

            Cookies.set('email', email);

            // Cookies.set('keyVaultAdminData', keyVaultAdminData);
            window.open(`${process.env.REACT_APP_RPA_DESIGNER_LINK}/${rpaToken} `/* , "RPA Designer" */);
            // window.open('http://localhost:3001/rpa-designer/flow/'+rpaToken, "RPA Designer");
        } else {

            Cookies.set('azureKeyVaultIamData', keyVaultIamDataAzure);
            Cookies.set('keyVaultIamDataAws', keyVaultIamDataAws);

            Cookies.set('email', email);
            Cookies.set('token', token);


            // Cookies.set('keyVaultAdminData', keyVaultAdminData);
            window.open(`${process.env.REACT_APP_RPA_DESIGNER_LINK}/`/* , "RPA Designer" */);
            // window.open('http://localhost:3000/rpa-designer/', "RPA Designer");
        }
    };

    const deleteFlow = (status, id) => {
        if (status === 'Active') {
            openNotificationWithIcon('error', "Please deactivate flow before deletion !");
        }
        else {
            dispatch(deleteRPAByID(id, clearFilteringInputs));

        }



    };
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const itemsPerPage = 12;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const totalItems = filteredData ? filteredData.length : 0;
    return (
        <>
            <DeleteModal />

            <PageHeader ghost title="Flows" />
            <Main>
                <Cards headless>
                    <Row gutter={15} >
                        <Col xs={24} style={{ margin: "auto", background: "white", marginBottom: "15px", borderRadius: "12px" }}>
                            <TopToolBox>
                                <Row gutter={15} className="justify-content-center">
                                    <Col span={10}>
                                        <div className="table-search-box">
                                            <AutoComplete
                                                onSearch={event => handleSearch(event)} //event here is the input value.
                                                onSelect={event => handleSearch(event)} //event here is the input value.
                                                onClear={() => setSearchText("")}
                                                dataSource={autoCompleteList}
                                                value={searchText}
                                                placeholder="Search here"
                                                width="100%"
                                                patterns
                                            />
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <Popover placement="bottomRight" content={"Refresh Flows"} action="hover">
                                            <LoadingButton
                                                toggleCondition={isLoadingRPAs}
                                                onClick={() => dispatch(fetchRPAsToggle())}
                                            />
                                        </Popover>
                                    </Col>
                                    <Col span={8} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

                                        <FlowsFilter radioGroupValue={radioGroupValue}
                                            handleChangeForFilterTriggerTypeAndStatus={handleChangeForFilterTriggerTypeAndStatus} />
                                        <Button size="middle"
                                            style={{ display: "flex", marginLeft: "15px", padding: "13px 12px" }}
                                            type="primary" disabled={isLoadingRPAs} onClick={() => handleNewOrEditActionClick()}>
                                            <FeatherIcon icon="plus" size={12} />
                                            {"New Flow"}
                                        </Button>

                                    </Col>
                                </Row>
                            </TopToolBox>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: "10px" }} align="end"><b>Total:&nbsp;</b>{filteredData.length}</Row>
                </Cards>
                {filteredData.length === 0 && <Empty style={{ marginTop: "20px" }} />}
                <Row gutter={[15, 15]} >
                    {filteredData && filteredData.slice(startIndex, endIndex).map((data, ind) => {
                        return (
                            <FlowCard
                                key={ind}
                                flowName={data.name}
                                status={data.status}
                                id={data.id}
                                token={data.token}
                                flow_apps={data.flow_apps}
                                tags={data.tags}
                                flow_type={data.flow_type === "" ? "noTrigger" : data.flow_type}
                                trigger_type={data.authentication.hasOwnProperty("type") ? "CronJobTrigger" : data.trigger_type === 'ChatBotTrigger' ? "ChatbotTrigger" : data.trigger_type === 'WebhookTrigger' ? "WebhookTrigger" : ""}
                                handleNewOrEditActionClick={handleNewOrEditActionClick}
                                deleteFlow={deleteFlow}
                                date={data.date_modified}
                                clearFilteringInputs={clearFilteringInputs}
                            />
                        )
                    })}
                </Row>
                <Row align="end" style={{ marginTop: "20px", marginBottom: "10px" }}>
                    {totalItems > itemsPerPage && (
                        <Pagination
                            style={{ marginTop: '16px', textAlign: 'center' }}
                            defaultCurrent={1}
                            total={totalItems}
                            pageSize={itemsPerPage}
                            showSizeChanger={false}
                            onChange={onPageChange}
                        />
                    )}
                </Row>
            </Main >
        </>
    );
};

export default RPAs;