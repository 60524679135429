import React from "react";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import { Route, useRouteMatch } from "react-router-dom";
import Bots from "../../container/chatbot/Bots";

const ChatbotRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} component={Bots} />
    </Switch>
  );
};

export default ChatbotRoutes;
