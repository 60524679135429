import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';

const DeleteModal = () => {
    const {
        showDeleteModal
    } = useSelector(state => {
        return {
            showDeleteModal: state.rpas.showDeleteModal,

        };
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal title="Basic Modal" open={showDeleteModal}
                type="primary"
                width={'66%'}
                footer={null}

            >
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Modal>
        </>
    );
};

export default DeleteModal;