import React, { useEffect, useRef, useState } from 'react'
import { TableWrapper } from '../styled'
import { Alert, Button, Popconfirm, Popover, Row, Spin, Table, Typography } from 'antd'
import { MenuOutlined, CloseCircleOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { timeAgo } from '../credentials/TableRow';
import classes from "./Users.module.css"
import { accessUser, deleteUserByID } from '../../redux/users/actionCreator';
import { useDispatch } from 'react-redux';
function truncateString(inputString, maxLength) {
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.substring(0, maxLength) + "....";
    }
}
const UsersTable = ({ clickedCard, setClickedCard, setModalToOpen, filteredData }) => {
    const columns = [
        { title: "User ID", dataIndex: "userId" },
        { title: "Name", dataIndex: "name" },
        { title: "Email", dataIndex: "email" },
        { title: "Created At", dataIndex: "createdAt" },
        { title: "Status", dataIndex: "status" },
        { title: "Role", dataIndex: "role" },
        { title: "Instance Name", dataIndex: "instanceName" },
        { title: "Action", dataIndex: "action" },
    ];
    const [visible, setVisible] = useState(false)
    const [isAccessingUser, setIsAccessingUser] = useState(false)
    const [popConfirmVisible, setPopConfirmVisible] = useState(false)
    const dispatch = useDispatch()
    const popOverRef = useRef(null)
    const popConfirmRef = useRef(null)
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                popOverRef.current &&
                !popOverRef.current.contains(event.target) &&
                visible
            ) {
                setVisible(false);
            }
            if (
                popConfirmRef.current &&
                !popConfirmRef.current.contains(event.target) &&
                popConfirmVisible
            ) {
                setPopConfirmVisible(false);
            }
        };
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [visible, popConfirmVisible]);
    const PopOverContent = () => (
        <div
            ref={popOverRef}
        >
            <Row className={classes.popOverRow}
                onClick={() => {
                    setModalToOpen("changeInstanceModal")
                }}>
                <FeatherIcon size={18} icon="edit-2" />
                <Typography>Change Instance Name</Typography>
            </Row>
            <Row className={classes.popOverRow}
                onClick={() => {
                    setModalToOpen("changeStatusModal")
                }}>
                <FeatherIcon size={18} icon="user-check" />
                <Typography>Change Status</Typography>
            </Row>
            <Row className={classes.popOverRow}
                onClick={() => {
                    setModalToOpen("changeRoleModal")
                }}>
                <FeatherIcon size={18} icon="settings" />
                <Typography>Change Role</Typography>
            </Row>
            <Row className={classes.popOverRow}
                onClick={() => {
                    setModalToOpen("changePasswordModal")
                }}>
                <FeatherIcon size={18} icon="lock" />
                <Typography>Change Password</Typography>
            </Row>
            <Row className={classes.popOverRow}
                onClick={() => {
                    setIsAccessingUser(true)
                    dispatch(accessUser(clickedCard.id,setIsAccessingUser))
                }}>
                <FeatherIcon size={18} icon="user" />
                <Typography>Access user account {isAccessingUser && <Spin />}</Typography>
            </Row>
            <Row className={classes.popOverRow}>
                <Popconfirm
                    visible={popConfirmVisible}
                    icon={""}
                    placement="left"
                    title={
                        <div
                            ref={popConfirmRef}
                        >
                            <Alert style={{ maxWidth: "250px", height: "70px", fontSize: '0.75rem', marginBottom: "10px" }} type="info" showIcon
                                message="All data associated with this user will be permanently deleted." />
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px"
                            }}>
                                <CloseCircleOutlined style={{ color: "rgb(173, 50, 120)" }} />
                                Delete this user
                            </div>
                        </div>
                    }
                    onConfirm={() => {
                        setVisible(false)
                        dispatch(deleteUserByID(clickedCard.id))
                    }}
                    onCancel={() => {
                        setVisible(false)
                        setPopConfirmVisible(false)
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <div className={classes.delete} onClick={() => {
                        setPopConfirmVisible(true)
                    }}>
                        <FeatherIcon size={18} icon="trash-2" />
                        <Typography style={{ color: "#ad3278" }}>Delete User</Typography>
                    </div>
                </Popconfirm>
            </Row>
        </div>
    )
    const convertFilteredDataToHTML = () => {
        const newFilteredDataAsHtml = [];
        filteredData.map((elm, ind) => {
            const { id, name, email, created_at, role, status, ziwo_instance_name } = elm;
            return newFilteredDataAsHtml.push({
                key: ind,
                userId: <span className="order-id">{id}</span>,
                name: <span className="order-id">{name}</span>,
                email: <span className="order-id">{email}</span>,
                createdAt: <span className="order-id">{created_at.slice(0, -3)}</span>,
                role: <span className="order-id">{role}</span>,
                status:
                    <span
                        className={`status ${status === "Active" ? "Success" : "error"}`}>
                        {status}
                    </span>,
                instanceName:
                    <Popover placement="top" action="hover"
                        content={<div style={{ color: "#AD3278" }}>{ziwo_instance_name}</div>}
                    > <span className="order-id">
                            {truncateString(ziwo_instance_name, 15)}
                        </span>
                    </Popover>,
                action: (
                    <div>
                        <Popover
                            visible={visible === ind + 1}
                            placement="bottomLeft" content={<PopOverContent />} trigger="click">
                            <MenuOutlined style={{ fontSize: "20px" }}
                                onClick={() => {
                                    setVisible(ind + 1)
                                    setClickedCard(elm)
                                }}
                            />
                        </Popover>
                    </div>
                ),
            });
        });

        return newFilteredDataAsHtml;
    };
    const filteredDataAsHtml = convertFilteredDataToHTML()
    return (
        <TableWrapper className="table-order table-responsive">
            <Table
                columns={columns}
                dataSource={filteredDataAsHtml}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                    total: filteredDataAsHtml.length,
                }}
            />
        </TableWrapper>
    )
}

export default UsersTable