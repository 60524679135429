import { Main, TopToolBox } from "../styled";
import { Col, Row, Typography } from "antd";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { Button } from "../../components/buttons/buttons";
import { Cards } from "../../components/cards/frame/cards-frame";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Popover } from "../../components/popup/popup";
import UsersCards from "./UserCards";
import { fetchUsersToggle, getFlowDetails, getUsers } from "../../redux/users/actionCreator";
import classes from "./Users.module.css"
import FilterUsers from "./usersFilter";
import UsersTable from "./UsersTable";
import AddUserModal from "./addUserModal";
import UsersModal from "./usersModal";

const Users = () => {

    const dispatch = useDispatch();


    const [searchText, setSearchText] = useState("");
    const [autoCompleteList, setAutoCompleteList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [modalToOpen, setModalToOpen] = useState("")
    const [radioGroupValue, setRadioGroupValue] = useState('All');
    const [clickedCard, setClickedCard] = useState({})


    const { isLoadingUsers, fetchUsers, usersList, flowsDetails } = useSelector(state => {
        return {
            isLoadingUsers: state.users.isLoadingUsers,
            fetchUsers: state.users.fetchUsers,
            usersList: state.users.usersList,
            flowsDetails: state.users.flowsDetails
        };
    });

    useEffect(() => {
        if (fetchUsers) {
            dispatch(getUsers());
            dispatch(getFlowDetails())
            clearFilteringInputs();
        }
        else {
            setFilteredData(usersList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));

        }
    }, [fetchUsers, usersList]);// eslint-disable-line

    const handleSearch = (searchText) => {
        const data = usersList.filter((value) =>
            value.name.toUpperCase().includes(searchText.toUpperCase())
            || value.email.toUpperCase().includes(searchText.toUpperCase())
            || value.role.toUpperCase().includes(searchText.toUpperCase())
            || value.ziwo_instance_name.toUpperCase().includes(searchText.toUpperCase())
        );
        setFilteredData(data);
        const title = data.map((e) => {
            return {
                title: e.name,
            };
        });
        setAutoCompleteList(title);
        setSearchText(searchText);
        setRadioGroupValue('All');

    };
    const handleChangeForFilterTriggerTypeAndStatus = (event) => {
        let { value } = event.target;
        setSearchText('');
        setRadioGroupValue(value);

        let newFilteredData = [];
        if (value === "All") {
            newFilteredData = [...usersList];
        }
        else if (value === 'Active') {
            newFilteredData = usersList.filter((user) => user.status === value);
        }
        else if (value === 'InActive') {
            newFilteredData = usersList.filter((user) => user.status === value);
        }
        else if (value === 'Admin') {
            newFilteredData = usersList.filter((user) => user.role === value);
        }
        else if (value === 'Member') {
            newFilteredData = usersList.filter((user) => user.role === value);
        }

        setFilteredData(newFilteredData.sort((a, b) => new Date(b.date_modified) - new Date(a.date_modified)));
    };
    function clearFilteringInputs() {
        setSearchText('');
        setAutoCompleteList([]);
        setRadioGroupValue('All');

    }

    return (
        <>
            <PageHeader ghost title={"Users"} />
            <Main>
                <Cards headless>
                    <Row gutter={15}>
                        <Col xs={24}>
                            <TopToolBox>
                                <Row gutter={15} className="justify-content-center">
                                    <Col span={10}>
                                        <div className="table-search-box">
                                            <AutoComplete
                                                onSearch={event => handleSearch(event)} //event here is the input value.
                                                onSelect={event => handleSearch(event)} //event here is the input value.
                                                onClear={() => setSearchText("")}
                                                dataSource={autoCompleteList}
                                                value={searchText}
                                                placeholder="Search here"
                                                width="100%"
                                                patterns
                                            />
                                        </div>
                                    </Col>
                                    <Col span={2}>
                                        <Popover placement="bottomRight" content={"Refresh Users"} action="hover">
                                            <LoadingButton
                                                toggleCondition={isLoadingUsers}
                                                onClick={() => dispatch(fetchUsersToggle())}
                                            />
                                        </Popover>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right', display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                        <div style={{ marginRight: "10px" }}>
                                            <FilterUsers radioGroupValue={radioGroupValue}
                                                handleChangeForFilterTriggerTypeAndStatus={handleChangeForFilterTriggerTypeAndStatus} />
                                        </div>
                                        <Button style={{ display: "flex", padding: "15px 12px" }} size="small" type="primary"
                                            disabled={isLoadingUsers}
                                            onClick={() => setModalToOpen("addUserModal")}
                                        >
                                            <FeatherIcon icon="plus" size={12} />
                                            {"Add User"}
                                        </Button>
                                    </Col>
                                </Row>
                            </TopToolBox>
                        </Col>
                    </Row>

                    <Row>
                        <Typography><span className={classes.bold}>Total Flows Number: </span>{flowsDetails.total_number_of_flows}</Typography>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Typography><span className={classes.bold}>Total Active Flows: </span>{flowsDetails.total_number_of_active_flows}</Typography>
                        </Col>
                        <Col span={12} align="end">
                            <Typography><span className={classes.bold}>Total Users: </span>{filteredData.length}</Typography>
                        </Col>
                    </Row>
                </Cards>
                <AddUserModal modalToOpen={modalToOpen} setModalToOpen={setModalToOpen} />
                <UsersModal modalToOpen={modalToOpen} setModalToOpen={setModalToOpen} cardData={clickedCard} />
                <UsersTable modalToOpen={modalToOpen} setModalToOpen={setModalToOpen} filteredData={filteredData}
                    setClickedCard={setClickedCard} clickedCard={clickedCard}
                />
                {/* <UsersCards modalToOpen={modalToOpen} setModalToOpen={setModalToOpen} filteredData={filteredData} /> */}
            </Main>
        </>
    );
};

export default Users;


