import Axios from "axios";
import Cookies from "js-cookie";
import openNotificationWithIcon from "../../components/notification/notification";
import actions from "./actions";
function lightenRgbColor(rgb, percent) {
    // Extract the RGB components
    const [r, g, b] = rgb.match(/\d+/g).map(Number);

    // // Convert RGB to HSL
    // const hsl = rgbToHsl(r, g, b);

    // // Increase lightness by the given percentage
    // hsl.l += (percent / 100);
    // if (hsl.l > 1) hsl.l = 1; // Cap lightness at 1

    // // Convert HSL back to RGB
    // const newRgb = hslToRgb(hsl.h, hsl.s, hsl.l);

    return `rgb(${r} ${g} ${b} \/ ${percent})`;
}

function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }
    return { h, s, l };
}

function hslToRgb(h, s, l) {
    let r, g, b;
    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 3) return q;
            if (t < 1 / 2) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
    };
}

function generateRandomColor() {
    // Generate random values for red, green, and blue
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Format the RGB values into a CSS color string
    const color = `rgb(${red}, ${green}, ${blue})`;

    return color;
}
const {
    is_loading_modals_usage,
    set_modals_and_providers_usage,
    is_loading_flows_usage,
    set_flows_usage,
    set_providers_usage,
    is_loading_token_usage_per_flow,
    set_token_usage_per_flow,
    set_total_cost,
    is_loading_total_cost,
    is_loading_top_10_token_usage,
    set_top_10_token_usage
} = actions;

let cancelTokenSource
const getModalsUsage = (start_date, end_date, flowName, AINode) => {
    return (dispatch, getState) => {
        console.log({ start_date, end_date });
        const authToken = getState().auth.token;
        const userId = Cookies.get("userId");
        dispatch(is_loading_modals_usage(true));
        if (cancelTokenSource) {
            cancelTokenSource.cancel("Request canceled due to new input");
        }
        // Create a new CancelToken source
        cancelTokenSource = Axios.CancelToken.source();

        let config = {
            cancelToken: cancelTokenSource.token,
            method: "post",
            url: process.env.REACT_APP_TEST_ELASTIC_LANGCHAIN + "tokens_per_model",
            headers: { Authorization: "Bearer " + authToken },
            data: {
                // required
                "user_id": userId,
                "start_date": start_date,
                "end_date": end_date,
                // optionals
                "flowName": flowName || undefined,
                "AINode": AINode !== "All" ? AINode : undefined,

            }
        };

        Axios(config)
            .then((response) => {
                const models = []
                let provs = {}
                response.data?.data?.forEach((d, i) => {
                    if (provs[d.provider])
                        provs = { ...provs, [d.provider]: { ...provs[d.provider], tokenUsage: provs[d.provider].tokenUsage + d.tokenUsage, cost: provs[d.provider].cost + d.cost } }
                    else
                        provs = { ...provs, [d.provider]: { tokenUsage: d.tokenUsage, cost: d.cost, color: generateRandomColor() } }

                    models.push({ ...d, color: generateRandomColor() })
                })
                dispatch(set_modals_and_providers_usage({ models: models, providers: provs }));
                dispatch(is_loading_modals_usage(false));
                openNotificationWithIcon('success', 'Load Providers & Modals Usage', 'Providers & Modals Usage Loaded Successfully');
            })
            .catch((error) => {
                console.log("Load Providers & Modals Usage Error", error);
                openNotificationWithIcon('error', 'Load Providers & Modals Usage', 'Failed Loading Providers & Modals Usage');
                dispatch(is_loading_modals_usage(false));
            });

    };
};

const getTokenUsagePerFlow = ({ start_date, end_date, flowName }) => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        const userId = Cookies.get("userId");
        dispatch(is_loading_token_usage_per_flow(true));
        let config = {
            method: "post",
            url: process.env.REACT_APP_TEST_ELASTIC_LANGCHAIN + "tokens_per_flow",
            headers: { Authorization: "Bearer " + authToken },
            data: {
                user_id: userId,
                start_date,
                end_date,
                flowName
            }
        };

        Axios(config)
            .then((response) => {
                dispatch(set_token_usage_per_flow(
                    response.data?.AINodes.map(elm => ({
                        ...elm,
                        backgroundColor: generateRandomColor()
                    }))
                ))
                dispatch(is_loading_token_usage_per_flow(false));
                openNotificationWithIcon('success', 'Load Token Usage per flow', 'Token Usage per flow Loaded Successfully');
            })
            .catch((error) => {
                console.log("Load Token Usage per flow Error", error);
                openNotificationWithIcon('error', 'Load Token Usage per flow', 'Failed Loading Token Usage per flow');
                dispatch(is_loading_token_usage_per_flow(false));
            });
    }
};
const getTop10TokenUsage = ({ start_date, end_date }) => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        const userId = Cookies.get("userId");
        dispatch(is_loading_top_10_token_usage(true));
        let config = {
            method: "post",
            url: process.env.REACT_APP_TEST_ELASTIC_LANGCHAIN + "top_10_flows",
            headers: { Authorization: "Bearer " + authToken },
            data: {
                user_id: userId,
                start_date,
                end_date,
            }
        };

        Axios(config)
            .then((response) => {
                dispatch(set_top_10_token_usage(response.data.Flows))
                dispatch(is_loading_top_10_token_usage(false));
                openNotificationWithIcon('success', 'Load Top 10', 'Top 10 Loaded Successfully');
            })
            .catch((error) => {
                console.log("Load Top 10 Error", error);
                openNotificationWithIcon('error', 'Load Top 10', 'Failed Loading Top 10');
                dispatch(is_loading_top_10_token_usage(false));
            });
    }
};
const getTotalCost = () => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        const userId = Cookies.get("userId");
        dispatch(is_loading_total_cost(true));
        let config = {
            method: "post",
            url: process.env.REACT_APP_TEST_ELASTIC_LANGCHAIN + "total_cost",
            headers: { Authorization: "Bearer " + authToken },
            data: { user_id: userId }
        };

        Axios(config)
            .then((response) => {
                dispatch(set_total_cost(response.data?.totalCost))
                dispatch(is_loading_total_cost(false));
                openNotificationWithIcon('success', 'Total Cost', 'Total Cost Loaded Successfully');
            })
            .catch((error) => {
                console.log("Total Cost Error", error);
                openNotificationWithIcon('error', 'Total Cost', 'Failed Loading Total Cost');
                dispatch(is_loading_total_cost(false));
            });
    }
}

export {
    getModalsUsage, getTokenUsagePerFlow, getTop10TokenUsage, getTotalCost
};

