import actions from './actions';

const { IS_LOADING_STORIES, FETCH_STORIES, SET_STORIES } = actions;

const initState = {
  storiesList: [],
  fetchStories: true,
  isLoadingStories: true,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const storiesReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case IS_LOADING_STORIES:
      return {
        ...state,
        isLoadingStories: status,
      };
    case FETCH_STORIES:
      return {
        ...state,
        fetchStories: status,
      };
    case SET_STORIES:
      data && data.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
      return {
        ...state,
        storiesList: data,
      };
    default:
      return state;
  }
};

export default storiesReducer;