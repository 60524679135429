import React from 'react';
import propTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Auth from '../../routes/auth';

const ProtectedRoute = ({ component, path }) => {
  const isLoggedIn = useSelector(state => state.auth.login);
  if (isLoggedIn && Cookies.get('token')) {
    return <Route component={component} path={path} />;
  } else {
    return <Route path="/" component={Auth} />;
  }
  // return isLoggedIn && Cookies.get('token') ? <Route component={component} path={path} /> : <Route path="/" component={Auth} />;
};

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
