import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { is_loading_rpas, fetch_rpas, set_rpas, show_delete_modal } = actions;

const getRPAs = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_rpas(true));
    dispatch(fetch_rpas(false));

    let config = {
      method: "get",
      url: process.env.REACT_APP_TRIGGERS_URL + "get_triggers",
      headers: { Authorization: "Bearer " + authToken },
    };
    axios(config)
      .then((response) => {
        dispatch(setRPAs(response.data.triggers));
        dispatch(is_loading_rpas(false));
        openNotificationWithIcon('success', 'Load RPAs', 'RPAs Loaded successfully');
        dispatch(is_loading_rpas(false));
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Load RPAs', "Failed Loading RPAs");
        console.log('Load RPAs Error', error);
        dispatch(is_loading_rpas(false));
      });
  };
};

const fetchRPAsToggle = () => {
  return dispatch => {
    dispatch(fetch_rpas(true));
  };
};
const showDeleteToggle = (status) => {
  return (dispatch, getState) => {
    dispatch(show_delete_modal(status));
  };
};

const setRPAs = (newList) => {
  return dispatch => {
    dispatch(set_rpas(newList));
  };
};

const deleteRPAByID = (id, clearFilteringInputs) => {
  return async (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_rpas(true));

    let config = {
      method: "delete",
      url: process.env.REACT_APP_TRIGGERS_URL + "delete_trigger/" + id,
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Deleting RPA", "RPA Deleted Successfully");
        console.log('Deleting RPA Response', JSON.stringify(response.data));
        dispatch(deleteRPAByIDLocally(id));
        dispatch(is_loading_rpas(false));
        clearFilteringInputs();
        dispatch(showDeleteToggle(false))

      })
      .catch(function (error) {
        openNotificationWithIcon("error", "Deleting RPA", "Deleting RPA Was Not Successfully !");
        console.log('Deleting RPA Error', error);
        dispatch(is_loading_rpas(false));
        dispatch(showDeleteToggle(false))

      });
  };
};

const deleteRPAByIDLocally = (id) => {
  return (dispatch, getState) => {
    const { rpasList } = getState().rpas;
    let newRPAsList = [...rpasList];

    newRPAsList = newRPAsList.filter(rpa => rpa.id !== id);
    dispatch(set_rpas(newRPAsList));
  };
};

const deactivatedRPAByToken = (name, rpaToken, clearFilteringInputs) => {
  return async (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_rpas(true));
    let data = { token: rpaToken };

    var config = {
      method: "post",
      url: process.env.REACT_APP_TRIGGERS_URL + 'deactivate_rpa',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        openNotificationWithIcon('success', 'Deactivate Rpa', "'" + name + "' Deactivated Successfully");
        dispatch(is_loading_rpas(false));
        dispatch(updateDeactivatedRPA(rpaToken));
        clearFilteringInputs();
      })
      .catch((res) => {
        console.log('Deactivate RPA Error', res);
        openNotificationWithIcon('error', 'Deactivate Rpa', "Failed Deactivating '" + name + "'");
        dispatch(is_loading_rpas(false));
      });
  };
};

const updateDeactivatedRPA = (token) => {
  return (dispatch, getState) => {
    const { rpasList } = getState().rpas;
    let newRPAsList = [...rpasList];
    let rpaToUpdateIndex = newRPAsList.findIndex(rpa => rpa.token === token);

    newRPAsList[rpaToUpdateIndex] = { ...newRPAsList[rpaToUpdateIndex], status: 'Inactive' };
    dispatch(set_rpas(newRPAsList));
  };
};

export { getRPAs, fetchRPAsToggle, setRPAs, deleteRPAByID, deactivatedRPAByToken, showDeleteToggle };
