const actions = {
    IS_LOADING_USERS: 'IS_LOADING_USERS',
    FETCH_USERS: 'FETCH_USERS',
    SET_USERS: 'SET_USERS',
    IS_LOADING_FLOWS_DETAILS: 'IS_LOADING_FLOWS_DETAILS',
    FETCH_FLOWS_DETAILS: 'FETCH_FLOWS_DETAILS',
    SET_FLOWS_DETAILS: 'SET_FLOWS_DETAILS',

    is_loading_users: (status) => {
        return {
            type: actions.IS_LOADING_USERS,
            status: status,
        };
    },
    fetch_users: (status) => {
        return {
            type: actions.FETCH_USERS,
            status: status,
        };
    },
    set_users: (data) => {
        return {
            type: actions.SET_USERS,
            data: data,
        };
    },
    is_loading_flows_details: (status) => {
        return {
            type: actions.IS_LOADING_FLOWS_DETAILS,
            status: status,
        };
    },
    fetch_flows_details: (status) => {
        return {
            type: actions.FETCH_FLOWS_DETAILS,
            status: status,
        };
    },
    set_flows_details: (data) => {
        return {
            type: actions.SET_FLOWS_DETAILS,
            data: data,
        };
    },
};

export default actions;
