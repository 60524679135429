import { Alert, Col, Empty, Input, Row, Spin } from "antd";
import { addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { CalendarButtonPageHeader } from "../../components/buttons/calendar-button/calendar-button";
import { Cards } from "../../components/cards/frame/cards-frame";
import {
    ChartjsBarChart
} from "../../components/charts/chartjs";
import Heading from "../../components/heading/heading";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { CardBarChart } from "../../container/dashboard/DashboardComponents/style";
import { getTokenUsagePerFlow } from "../../redux/token-usage/actionCreator";
import { getDate } from "../new-reporting/reportingData";
const { Search } = Input

const StyledSearch = styled(Input.Search)`
  width: 300px;
  border-radius: 8px;
  
  .ant-input {
  height:32px;
  }

  .ant-input-search {
    height:32px;
  }
`;

const initialState = {
    datePickerInternational: null,
    dateRangePicker: {
        selection: {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: "selection",
        },
    },
};
let timeOutId
const TokenUsagePerFlow = () => {
    const dispatch = useDispatch();
    const { isLoadingTokenUsagePerFlow, tokenUsagePerFlowList } = useSelector(state => {
        return {
            isLoadingTokenUsagePerFlow: state.tokenUsage.isLoadingTokenUsagePerFlow,
            tokenUsagePerFlowList: state.tokenUsage.tokenUsagePerFlowList,
        };
    });
    const [dateRange, setDateRange] = useState(initialState);
    const [appliedDateRange, setAppliedDateRange] = useState(initialState);
    const [calendarVisible, setCalendarVisible] = useState(false);
    const [flowNameVisible, setFlowNameVisible] = useState(false);
    const [flowName, setFlowName] = useState("");
    const onSelectChange = (selectedRowKey) => {
        setDateRange({ ...dateRange, selectedRowKeys: selectedRowKey });
    };
    const handleRangeChange = (which) => {
        setDateRange({
            ...dateRange,
            dateRangePicker: {
                ...dateRange.dateRangePicker,
                ...which,
            },
        });
    };
    const onApply = () => {
        setAppliedDateRange(dateRange);
        dispatch(
            getTokenUsagePerFlow(
                {
                    start_date: getDate(dateRange.dateRangePicker.selection.startDate),
                    end_date: getDate(dateRange.dateRangePicker.selection.endDate),
                    flowName
                }
            )
        );
        setCalendarVisible(false);
        setFlowNameVisible(false)
    };
    const onCancel = () => { };
    function clearFilteringInputs() {
    }
    useEffect(() => {
        dispatch(getTokenUsagePerFlow(
            {
                start_date: getDate(dateRange.dateRangePicker.selection.startDate),
                end_date: getDate(dateRange.dateRangePicker.selection.endDate),
                flowName
            }))
    }, []);// eslint-disable-line

    const onSearch = (value, _e, info) => {
        setFlowName(value)
        clearTimeout(timeOutId)
        timeOutId = setTimeout(() =>
            dispatch(getTokenUsagePerFlow(
                {
                    start_date: getDate(dateRange.dateRangePicker.selection.startDate),
                    end_date: getDate(dateRange.dateRangePicker.selection.endDate),
                    flowName: value
                })), 500)
    };
    const onChange = (e) => {
        const newValue = e.target.value
        setFlowName(newValue)
        clearTimeout(timeOutId)
        timeOutId = setTimeout(() =>
            dispatch(getTokenUsagePerFlow(
                {
                    start_date: getDate(dateRange.dateRangePicker.selection.startDate),
                    end_date: getDate(dateRange.dateRangePicker.selection.endDate),
                    flowName: newValue
                })), 500)
    }
    return (
        <>

            <Cards
                isbutton={<div className="card-nav"><LoadingButton
                    toggleCondition={isLoadingTokenUsagePerFlow}
                    onClick={() => dispatch(getTokenUsagePerFlow(
                        {
                            start_date: getDate(dateRange.dateRangePicker.selection.startDate),
                            end_date: getDate(dateRange.dateRangePicker.selection.endDate),
                            flowName
                        }))}
                /></div>}
                title="Token Usage Per Flow"
                size="large"
            >
                <Row gutter={[15, 15]} >
                    <Col>
                        <CalendarButtonPageHeader
                            visible={calendarVisible}
                            setVisible={setCalendarVisible}
                            key="1"
                            onApply={onApply}
                            onCancel={onCancel}
                            state={dateRange}
                            setState={setDateRange}
                            onSelectChange={onSelectChange}
                            handleRangeChange={handleRangeChange}
                            appliedDateRange={appliedDateRange}
                        />
                    </Col>
                    <Col>
                        {/* <SearchByFlowName
                            onApply={onApply}
                            visible={flowNameVisible} setVisible={setFlowNameVisible}
                            flowName={flowName} setFlowName={setFlowName} /> */}
                        <StyledSearch
                            placeholder="Flow name"
                            size="small"
                            onSearch={onSearch}
                            onChange={onChange}
                            style={{
                                width: 200,
                                height: 32
                            }}
                        />
                    </Col>
                </Row>
                {isLoadingTokenUsagePerFlow ? (
                    <div className="sd-spin">
                        <Spin />
                    </div>
                ) :
                    tokenUsagePerFlowList.length == 0 ? <>
                        <Empty />
                        <Alert type="info" showIcon
                            style={{ background: "#2c99ff15", width: "55%", margin: "15px auto 0px auto" }}
                            message={"Ensure the flow name does exist and contains a LangChain node."} />
                    </> :
                        tokenUsagePerFlowList !== null && (<CardBarChart>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className="card-bar-top">
                                    <p>Token</p>
                                    <Heading as="h3">
                                        {tokenUsagePerFlowList.reduce(
                                            (accumulator, currentValue) => {
                                                return accumulator + currentValue.totalTokens
                                            }, 0)}
                                    </Heading>
                                </div>
                            </div>

                            <ChartjsBarChart
                                {...{
                                    height: 200,
                                    labels: tokenUsagePerFlowList.map(elm => elm.AINode),
                                    datasets: [{
                                        label: "Token Usage",
                                        data: tokenUsagePerFlowList.map(elm => elm.totalTokens),
                                        // backgroundColor: "#72AFDD"
                                        backgroundColor: tokenUsagePerFlowList.map(elm => elm.backgroundColor)
                                    }],
                                    options: {
                                        maintainAspectRatio: true,
                                        responsive: true,
                                        legend: {
                                            labels: {
                                                fontSize: 12,
                                                fontColor: '#182b49',
                                            },
                                        },
                                        scales: {
                                            yAxes: [
                                                {
                                                    stacked: true,
                                                    gridLines: {
                                                        color: '#e5e9f2',
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        fontSize: 12,
                                                        fontColor: '#182b49',
                                                    },
                                                },
                                            ],
                                            xAxes: [
                                                {
                                                    stacked: true,
                                                    gridLines: {
                                                        // display: false,
                                                    },

                                                    ticks: {
                                                        beginAtZero: true,
                                                        fontSize: 12,
                                                        fontColor: '#182b49',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                }}
                            />
                        </CardBarChart>)}
            </Cards>

        </>
    );
};

export default TokenUsagePerFlow;
