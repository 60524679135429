import axios from 'axios';
import actions from './actions';
import Cookies from 'js-cookie';
import openNotificationWithIcon from '../../components/notification/notification';
import { credsThatNeedRedirects } from '../../container/credentials/credentialsData';
const getDate=(lastUpdate)=>{
    const date = new Date(lastUpdate);
// Get the year, month, day, hour, minute, and second components of the date
const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month because it's zero-based
const day = String(date.getDate()).padStart(2, '0');
const hour = String(date.getHours()).padStart(2, '0');
const minute = String(date.getMinutes()).padStart(2, '0');
const second = String(date.getSeconds()).padStart(2, '0');

// Create the formatted date string
return `${year}-${month}-${day}`;
}

const { set_modal_name, add_credential, edit_credential, delete_credential, set_credentials, fetch_credentials, is_loading_credentials } = actions;
const getCredentials = () => {
    return (dispatch, getState) => {
        dispatch(is_loading_credentials(true))
        dispatch(fetch_credentials(false))
        const authToken = getState().auth.token;
        let config = {
            method: "get",
            url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/azure/credential/list`,
            headers: {
                Authorization: "Bearer " + authToken, "Content-Type": "application/json",
            },
        };

        axios(config)
            .then((response) => {
                const { data, error } = response
                if (!data?.error) {
                    let credList=data
                //    for(let key in data){
                //         for(let cred of data[key]){
                //             credList=[...credList,{...cred,type:key,lastUpdate:cred.lastUpdate}]
                //         }
                //    }
                    dispatch(set_credentials(credList))
                    openNotificationWithIcon('success', 'Load Credentials', 'Credentials Loaded successfully');
                }
                else {
                    openNotificationWithIcon('error', 'Load Credentials', 'The User has no credentials');
                }
                dispatch(is_loading_credentials(false))
            })
            .catch((error) => {
                openNotificationWithIcon('error', 'Load Credentials', "Failed Loading Credentials");
                console.log('Load Credentials Error', error);
                dispatch(is_loading_credentials(false))

            });
    }
}
// const addCredential = ({ name, clientID, clientSecret, username, new_user }) => {
//     return (dispatch, getState) => {
//         const authToken = getState().auth.token;
//         let data = {
//             username, new_user, credentials: {
//                 [`ClientId-${name}`]: clientID, [`ClientSecret-${name}`]: clientSecret
//             }
//         }
//         let config = {
//             method: "post",
//             url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/azure/cred/keyvault/AddKeys`,
//             headers: {
//                 Authorization: "Bearer " + authToken, "Content-Type": "application/json",
//             },
//             data: data

//         };
//         axios(config)
//             .then((response) => {
//                 // const { } = response.data
//                 openNotificationWithIcon('success', 'Created Credential', 'Credential Created successfully');
//             })
//             .catch((error) => {
//                 openNotificationWithIcon('error', 'Created Credential', "Failed Creating Credential");
//             });
//     }
// }
const addCredential = ({ username, new_user, credInfo,credType }) => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        dispatch(is_loading_credentials(true))
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-GB'); // Use 'en-US' for month/day/year format

        let credentials
        console.log({credInfo});
        const rdmNum = Math.round((Math.random() * 4000))
        credInfo.cred.forEach((c) => {
            if(c.Credential_value)
             credentials = { ...credentials, [`${c.Credential_name}-${credInfo.Service_name}--${credInfo.service}---${rdmNum.toString()}`]: c.Credential_value.toString() } })
        let data = {
            credentials: credentials
        }
        let config = {
            method: "post",
            url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/azure/credential/create`,
            headers: {
                Authorization: "Bearer " + authToken, "Content-Type": "application/json",
            },
            data: data

        };
        axios(config)
            .then((response) => {
                dispatch(add_credential({ vaults: Object.keys(credentials), lastUpdate: new Date(),type:credType,credName:credInfo.Service_name }))
                console.log({credsThatNeedRedirects});
                // if(!credsThatNeedRedirects.hasOwnProperty(credType))
                dispatch(is_loading_credentials(false))
                openNotificationWithIcon('success', 'Create Credential', 'Credential Created successfully');
            })
            .catch((error) => {
                openNotificationWithIcon('error', 'Create Credential', "Failed Creating Credential");
                console.log('add Credential Error', error);
                // if(!credsThatNeedRedirects.hasOwnProperty(credType))
                dispatch(is_loading_credentials(false))
            });
    }
}

const deleteCredential = ({ name }) => {
    return (dispatch, getState) => {
        dispatch(is_loading_credentials(true))
        dispatch(fetch_credentials(false))
        const authToken = getState().auth.token;
        let config = {
            method: "post",
            url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/azure/credential/delete`,
            headers: {
                Authorization: "Bearer " + authToken, "Content-Type": "application/json",
            },
            data: { credential:name}
        };
        axios(config)
            .then((response) => {
                const { data } = response
                dispatch(delete_credential(name))
                openNotificationWithIcon('success', 'Delete Credential', 'Credential Delete successfully');
                dispatch(is_loading_credentials(false))
            })
            .catch((error) => {
                openNotificationWithIcon('error', 'Delete Credential', "Failed Deleting Credential");
                console.log('Delete Credential Error', error);
                dispatch(is_loading_credentials(false))

            });
    }
}
const editCredential = ({ username, new_user, credInfo, oldCredInfo,credType }) => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        dispatch(is_loading_credentials(true))
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-GB'); // Use 'en-US' for month/day/year format
        let credentials
        credInfo.cred.forEach((c) => { credentials = { ...credentials, [c.Credential_name]: c.Credential_value } })
        let data = {
            username, new_user, credentials: credentials
        }
        let config = {
            method: "post",
            url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/azure/cred/keyvault/AddKeys`,
            headers: {
                Authorization: "Bearer " + authToken, "Content-Type": "application/json",
            },
            data: data

        };
        axios(config)
            .then((response) => {
                delete credInfo.service
                // dispatch(edit_credential({ Service_name: credInfo.Service_name, cred: credInfo.cred }))
                dispatch(edit_credential({ ...credInfo, cred: credInfo.cred.map((c, i) => c.Credential_value !== oldCredInfo.cred[i].Credential_value ? { ...c, Last_update: formattedDate } : c) }))
                openNotificationWithIcon('success', 'Edited Credential', 'Credential Edited successfully');
                if(credType!=="Google"&&credType!=="SalesForce")
                   dispatch(is_loading_credentials(false))
            })
            .catch((error) => {
                openNotificationWithIcon('error', 'Edited Credential', "Failed Editting Credential");
                if(credType!=="Google"&&credType!=="SalesForce"&&credType!=="Zoho"&&credType!=="Zoom")
                dispatch(is_loading_credentials(false))
                console.log('edit Credential Error', error);
            });
    }
}
const setModalName = (status) => {
    return dispatch => {
        dispatch(set_modal_name(status));
    };
};
const setCredentials = (data) => {
    return dispatch => {
        dispatch(set_credentials(data));
    };
};
const fetchCredentialsToggle = (status) => {
    return dispatch => {
        dispatch(fetch_credentials(status));
    };
};

export { setModalName, getCredentials, addCredential, deleteCredential, editCredential, fetchCredentialsToggle, setCredentials };