import actions from './actions';

const { IS_LOADING_ADAPTIVE_CARDS, FETCH_ADAPTIVE_CARDS, SET_ADAPTIVE_CARDS ,IS_EDITING_ADAPTIVE_CARD} = actions;

const initState = {
  adaptiveCardsList: [],
  fetchAdaptiveCards: true,
  isLoadingAdaptiveCards: false,
  isEditingAdaptiveCard: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const adaptiveCardsReducer = (state = initState, action) => {
  const { type, data, status } = action;
  switch (type) {
    case IS_LOADING_ADAPTIVE_CARDS:
      return {
        ...state,
        isLoadingAdaptiveCards: status,
      };
    case FETCH_ADAPTIVE_CARDS:
      return {
        ...state,
        fetchAdaptiveCards: status,
      };
    case SET_ADAPTIVE_CARDS:
      data.sort((a, b) => (a.cardname.toUpperCase() > b.cardname.toUpperCase()) ? 1 : ((b.cardname.toUpperCase() > a.cardname.toUpperCase()) ? -1 : 0));
      return {
        ...state,
        adaptiveCardsList: data,
      };
    case IS_EDITING_ADAPTIVE_CARD:
      return {
        ...state,
        isEditingAdaptiveCard: data,
      };
    default:
      return state;
  }
};

export default adaptiveCardsReducer;