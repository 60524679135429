import Cookies from 'js-cookie';
import { batch } from 'react-redux';
import { fetchAdaptiveCardsToggle, setAdaptiveCards } from '../adaptive-cards/actionCreator';
import { fetchBotsToggle, setBots } from '../chatbots/actionCreator';
import { fetchCredentialsToggle, setCredentials } from '../credentials/actionCreator';
import { fetchKeyVaultModalToggle, fetchKeyVaultModalToggleAws, setKeyVaultDataAws, setKeyVaultDataAzure } from '../key-vault-modal/actionCreator';
import { fetchKnowledgeAIsToggle, setKnowledgeAIs } from '../knowledge-ai/actionCreator';
import { fetchRPAsToggle, setRPAs } from '../rpas/actionCreator';
import { fetchStoriesToggle, setStories } from '../stories/actionCreator';
import { fetchUploadedFilesToggle, setUploadedFiles } from '../upload-file-modal/actionCreator';
import actions from './actions';
import openNotificationWithIcon from '../../components/notification/notification';
import axios from "axios"
const { loginBegin, loginSuccess, loginErr, is_logging_in, logoutBegin, logoutSuccess, logoutErr } = actions;
const login = (token, username, email, userId) => {
  return async dispatch => {
    try {
      dispatch(loginBegin());
      // setTimeout(() => {
      Cookies.set('logedIn', true);
      Cookies.set('token', token);
      Cookies.set('username', username);
      Cookies.set('email', email);
      Cookies.set('userId', userId);

      dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const setIsLoggingIn = (status) => {
  return (dispatch) => {
    dispatch(is_logging_in(status));
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      localStorage.removeItem("userType")
      Cookies.remove('logedIn');
      Cookies.remove('token');
      Cookies.remove('userId');
      Cookies.remove('email');
      // Cookies.set('token');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};
const SignOut = () => {
  return (dispatch) => {
    batch(() => {
      dispatch(logOut());
      dispatch(setRPAs([]));
      dispatch(fetchRPAsToggle());

      dispatch(fetchStoriesToggle());
      dispatch(setStories());
      dispatch(setCredentials([]));
      dispatch(fetchCredentialsToggle(true));
      dispatch(setBots([]));
      dispatch(fetchBotsToggle());
      dispatch(setAdaptiveCards([]));
      dispatch(fetchAdaptiveCardsToggle());
      dispatch(setKnowledgeAIs([]));
      dispatch(fetchKnowledgeAIsToggle());
      dispatch(setUploadedFiles([]));
      dispatch(fetchUploadedFilesToggle());

      const initialIamKeyVaultData = [
        { label: "Client ID", name: "azureClientId", value: "", version: "" },
        {
          label: "Client Secret",
          name: "azureClientSecret",
          value: "",
          version: "",
        },
        {
          label: "Tanent ID",
          name: "azureClientTenantId",
          value: "",
          version: "",
        },
        { label: "Vault", name: "azureClientVault", value: "", version: "" },
      ];

      const initialIamKeyVaultDataAws = [
        { label: "Access Key Id", value: "", name: "" },
        { label: "Secret Access Key", value: "", name: "" },
        { label: "Region", value: "", name: "" },
        { label: "Secret Name", value: "", name: "" },
      ];

      dispatch(setKeyVaultDataAzure(initialIamKeyVaultData));
      dispatch(fetchKeyVaultModalToggle());
      dispatch(setKeyVaultDataAws(initialIamKeyVaultDataAws));
      dispatch(fetchKeyVaultModalToggleAws());
    })
  };
}
const changePassword = (newPassword) => {
  return async (dispatch,getState) => {
    const authToken = getState().auth.token;
    let config = {
      method: "put",
      url: `${process.env.REACT_APP_TRIGGERS_URL}user/change_password`,
      headers: {
        Authorization: "Bearer " + authToken, "Content-Type": "application/json",
      },
      data: {
        "password": newPassword
      }
    };
    try {
      await axios(config)
      openNotificationWithIcon('success', 'Change Password', 'Password changed successfully');
      return true
    }
    catch (err) {
      openNotificationWithIcon('error', 'Change Password', "Failed password change");
      console.log('Change Password Error', err);
      return false
    }
  }
}
export {changePassword, login, logOut, setIsLoggingIn, SignOut };

