import axios from "axios";
import openNotificationWithIcon from "../../components/notification/notification";
import actions from "./actions";

const {
  is_loading_user_details,
  fetch_user_details,
  set_user_details,
  set_first_element,
} = actions;
const setUserDetails = (newList, history) => {
  return (dispatch) => {
    dispatch(set_user_details(newList));
    // if (Object.keys(newList).find((x) => x === "Stories")) {
    // dispatch(set_first_element("Stories"));
    let firstElement = "Flows"
    if (Object.keys(newList).find((x) => x === "Flows")) {
      dispatch(set_first_element("Flows"));
    } else {
      dispatch(set_first_element(Object.keys(newList)[0]));
      firstElement = Object.keys(newList)[0]
    }
    if (history) {
      history.push(firstElement === "Stories"
        ? "/dashboard/stories"
        : firstElement === "Deployments"
          ? "/dashboard/deployments"
          : firstElement === "Flows"
            ? "/dashboard"
            : firstElement === "Knowledge AI"
              ? "/dashboard/knowledge-ai"
              : firstElement === "Chatbots"
                ? "/dashboard/chatbot"
                : firstElement === "Adaptive Cards"
                  ? "/dashboard/cards"
                  : firstElement === "Whatsapp Cards"
                    ? "/dashboard/whatsapp-cards"
                    : "/dashboard")
    }
  };
};
const getUserDetails = (token, history) => {
  return (dispatch, getState) => {
    dispatch(is_loading_user_details(true));
    dispatch(fetch_user_details(false));
    // let config = {
    //   method: "get",
    //   url: "http://20.232.174.69/rbac/list_menu_elts",
    //   headers: { Authorization: "Bearer " + token },
    // };
    // console.log("configconfigconfig", config);

    // axios(config)
    //   .then((response) => {
    //     alert("succ");
    //     dispatch(
    //       setUserDetails({
    //         "Adaptive Cards": ["read", "write"],
    //         Chatbots: ["execute", "read", "write"],
    //         Deployments: ["execute", "read", "write"],
    //         Flows: ["execute", "read", "write"],
    //         "Knowledge AI": ["read", "write"],
    //         Stories: ["read"],
    //       })
    //     );
    //     dispatch(is_loading_user_details(false));
    //     console.log("User Details", response);
    //     openNotificationWithIcon(
    //       "success",
    //       "User Details",
    //       "User Details Loaded successfully"
    //     );
    //     dispatch(is_loading_user_details(false));
    //   })
    //   .catch((error) => {
    //     alert("err");
    //     console.log("User Details Error", error);
    //     dispatch(is_loading_user_details(false));
    //   });
    dispatch(
      setUserDetails({
        "Adaptive Cards": ["read", "write"],
        Chatbots: ["execute", "read", "write"],
        Deployments: ["execute", "read", "write"],
        Flows: ["execute", "read", "write"],
        "Knowledge AI": ["read", "write"],
        Stories: ["read"],
      }, history)
    );
  };
};

const fetchRPAsToggle = () => {
  return (dispatch) => {
    dispatch(fetch_user_details(true));
  };
};

export {
  getUserDetails,
  fetchRPAsToggle,
  setUserDetails /* deleteRPAByID, deactivatedRPAByToken */,
};
