const actions = {
  IS_LOADING_KNOWLEDGE_AIS: 'IS_LOADING_KNOWLEDGE_AIS',
  FETCH_KNOWLEDGE_AIS: 'FETCH_KNOWLEDGE_AIS',
  SET_KNOWLEDGE_AIS: 'SET_KNOWLEDGE_AIS',

  is_loading_knowledge_ai: (status) => {
    return {
      type: actions.IS_LOADING_KNOWLEDGE_AIS,
      status: status,
    };
  },
  fetch_knowledge_ai: (status) => {
    return {
      type: actions.FETCH_KNOWLEDGE_AIS,
      status: status,
    };
  },
  set_knowledge_ai: (data) => {
    return {
      type: actions.SET_KNOWLEDGE_AIS,
      data: data,
    };
  },
};

export default actions;
