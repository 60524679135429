import { Card, Col, Popover, Row, Typography, Popconfirm, Pagination, Alert } from 'antd';
import { MenuOutlined, CloseCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from 'react';
import classes from "./Users.module.css"
import FeatherIcon from "feather-icons-react";
import UsersModal from './usersModal';
import AddUserModal from './addUserModal';
import { deleteUserByID } from '../../redux/users/actionCreator';
import { useDispatch } from 'react-redux';

function truncateString(inputString, maxLength) {
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.substring(0, maxLength) + "....";
    }
}
const UserCards = ({ modalToOpen, setModalToOpen, filteredData }) => {
    const [clickedCard, setClickedCard] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [visible, setVisible] = useState(false)
    const [popConfirmVisible, setPopConfirmVisible] = useState(false)
    const dispatch = useDispatch()
    const popOverRef = useRef(null)
    const popConfirmRef = useRef(null)
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                popOverRef.current &&
                !popOverRef.current.contains(event.target) &&
                visible
            ) {
                setVisible(false);
            }
            if (
                popConfirmRef.current &&
                !popConfirmRef.current.contains(event.target) &&
                popConfirmVisible
            ) {
                setPopConfirmVisible(false);
            }
        };
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [visible, popConfirmVisible]);
    const PopOverContent = () => (
        <div ref={popOverRef} >
            <Row className={classes.popOverRow}
                onClick={() => {
                    setModalToOpen("changeInstanceModal")
                }}>
                <FeatherIcon size={18} icon="edit-2" />
                <Typography>Change Instance Name</Typography>
            </Row>
            <Row className={classes.popOverRow}
                onClick={() => {
                    setModalToOpen("changeStatusModal")
                }}>
                <FeatherIcon size={18} icon="user-check" />
                <Typography>Change Status</Typography>
            </Row>
            <Row className={classes.popOverRow}
                onClick={() => {
                    setModalToOpen("changeRoleModal")
                }}>
                <FeatherIcon size={18} icon="settings" />
                <Typography>Change Role</Typography>
            </Row>
            <Row className={classes.popOverRow}
                onClick={() => {
                    setModalToOpen("changePasswordModal")
                }}>
                <FeatherIcon size={18} icon="lock" />
                <Typography>Change Password</Typography>
            </Row>
            <Row className={classes.popOverRow}>
                <Popconfirm
                    visible={popConfirmVisible}
                    icon={""}
                    placement="left"
                    title={
                        <div ref={popConfirmRef}>
                            <Alert style={{ maxWidth: "250px", height: "70px", fontSize: '0.75rem', marginBottom: "10px" }} type="info" showIcon
                                message="All data associated with this user will be permanently deleted." />
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px"
                            }}>
                                <CloseCircleOutlined style={{ color: "rgb(173, 50, 120)" }} />
                                Delete this user
                            </div>
                        </div>
                    }
                    onConfirm={() => {
                        setVisible(false)
                        dispatch(deleteUserByID(clickedCard.id))
                    }}
                    onCancel={() => {
                        setVisible(false)
                        setPopConfirmVisible(false)
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <div className={classes.delete} onClick={() => {
                        setPopConfirmVisible(true)
                    }}>
                        <FeatherIcon size={18} icon="trash-2" />
                        <Typography style={{ color: "#ad3278" }}>Delete User</Typography>
                    </div>
                </Popconfirm>
            </Row>
        </div>
    )
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const itemsPerPage = 8;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const totalItems = filteredData ? filteredData.length : 0;
    return (
        <div className="site-card-wrapper">
            <AddUserModal modalToOpen={modalToOpen} setModalToOpen={setModalToOpen} />
            <UsersModal modalToOpen={modalToOpen} setModalToOpen={setModalToOpen} cardData={clickedCard} />
            <Row gutter={[16, 16]}>
                {filteredData && filteredData.slice(startIndex, endIndex).map((elm, ind) => {
                    return (
                        <Col key={elm.id} xs={24} sm={12}  >
                            <Card className={classes.card}

                                title={
                                    <Row>
                                        <Col span={12}>
                                            <Typography style={{ fontSize: "1rem" }}
                                                className={`status ${elm.status === "Active" ? "Success" : "error"}`}>
                                                {elm.status}
                                            </Typography>
                                        </Col>
                                        <Col align="end" span={12} >
                                            <Popover visible={visible === ind + 1} placement="bottom" content={<PopOverContent />} trigger="click">
                                                <MenuOutlined style={{ fontSize: "20px" }}
                                                    onClick={() => {
                                                        setVisible(ind + 1)
                                                        setClickedCard(elm)
                                                    }}
                                                />
                                            </Popover>
                                        </Col>
                                    </Row>
                                } >
                                <Typography><span className={classes.bold}>ID: </span>{elm.id}</Typography>
                                <Typography><span className={classes.bold}>Name: </span>{elm.name}</Typography>
                                <Typography><span className={classes.bold}>Email: </span>{elm.email}</Typography>
                                <Popover placement="top" action="hover"
                                    content={<div style={{ color: "#AD3278" }}>{elm.ziwo_instance_name}</div>}
                                >
                                    <Typography><span className={classes.bold}>Instance name: </span>{truncateString(elm.ziwo_instance_name, 25)}</Typography>
                                </Popover>
                                <Typography><span className={classes.bold}>Number of active flows: </span>{elm.number_of_active_flows}</Typography>
                                <Typography><span className={classes.bold}>Number of flows: </span>{elm.number_of_flows}</Typography>
                                <Typography><span className={classes.bold}>Role: </span>{elm.role}</Typography>
                                <Typography><span className={classes.bold}>Created At: </span>{elm.created_at.slice(0, -3)}</Typography>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
            <Row align="end" style={{ marginTop: "20px", marginBottom: "10px" }}>
                {totalItems > itemsPerPage && (
                    <Pagination
                        style={{ marginTop: '16px', textAlign: 'center' }}
                        defaultCurrent={1}
                        total={totalItems}
                        pageSize={itemsPerPage}
                        showSizeChanger={false}
                        onChange={onPageChange}
                    />
                )}
            </Row>
        </div>
    )
}
export default UserCards;
