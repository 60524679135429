const actions = {
  IS_LOADING_DATA_SET: 'IS_LOADING_DATA_SET',
  FETCH_DATA_SET: 'FETCH_DATA_SET',
  SET_DATA_SET: 'SET_DATA_SET',
  IS_LOADING_DOCS: 'IS_LOADING_DOCS',
  IS_LOADING_GET_DOCS: 'IS_LOADING_GET_DOCS',
  FETCH_DOCS: 'FETCH_DOCS',
  SET_DOCS: 'SET_DOCS',

  is_loading_get_docs: (status) => {
    return {
      type: actions.IS_LOADING_GET_DOCS,
      status: status,
    };
  },
  is_loading_data_set: (status) => {
    return {
      type: actions.IS_LOADING_DATA_SET,
      status: status,
    };
  },
  fetch_data_set: (status) => {
    return {
      type: actions.FETCH_DATA_SET,
      status: status,
    };
  },
  set_data_set: (data) => {
    return {
      type: actions.SET_DATA_SET,
      data: data,
    };
  },
  is_loading_docs: (status) => {
    return {
      type: actions.IS_LOADING_DOCS,
      status: status,
    };
  },
  fetch_docs: (status) => {
    return {
      type: actions.FETCH_DOCS,
      status: status,
    };
  },
  set_docs: (data) => {
    return {
      type: actions.SET_DOCS,
      data: data,
    };
  },
};

export default actions;
