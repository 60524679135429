import React from "react";
import { Input, /* Button */ } from "antd";
import PropTypes from "prop-types";
// import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
// import { useSelector } from "react-redux";
import { AutoCompleteStyled } from "./style";
import { imagesByKey } from "../../container/credentials/credentialsData";

const renderItem = (title, count) => {
  return {
    key: count,
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
        {/* {count} */}
      </div>
    ),
  };
};
const renderCred = (item, count) => {
  return {
    key: count,
    value: item.title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
         <img
            src={item.image}
            alt={item.title}
            style={{ width: 20,height:20, marginRight: 8 }}
                />
       

        {item.title}
      </div>
    ),
  };
};
const checkIfServiceExists=(name)=>
     imagesByKey.hasOwnProperty(name)
   
const AutoComplete = (props) => {
  /* const { rtl } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
    };
  }); */

  const {
    customComponent,
    patterns,
    // patternButtons,
    width,
    onSearch,
    onSelect,
    onClear,
    dataSource,
    placeholder,
    value,
    disabled,
    id,
    onClick,
    onBlur,
    dropdownStyle
  } = props;
  const content =
    dataSource?.length > 0 &&
    dataSource.map((group, index) => {
      const { title } = group;
      return {
        options: [
          renderItem(
            title,
            index
            // <span className="certain-search-item-count"></span>
          ),
        ],
      };
    });
  return customComponent ? (
    <AutoCompleteStyled
      value={value}
      dataSource={dataSource}
      style={{ width }}
      onSelect={onSelect}
      onSearch={onSearch}
      disabled={disabled}

    >
      {customComponent}
    </AutoCompleteStyled>
  ) : patterns ? (
    <AutoCompleteStyled
      value={value}
      id={id}
      className="certain-category-search"
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ width: 300 }}
      style={{ width }}
      options={content}
      placeholder={placeholder}
      onSelect={onSelect}
      onSearch={onSearch}
      onClear={onClear}
      disabled={disabled}
      onClick={onClick}
    >
      <Input
        allowClear
        autoComplete="off" 
        // suffix={
        //   patternButtons ? (
        //     <Button
        //       className="search-btn"
        //       style={{ [rtl ? "marginLeft" : "marginRight"]: -20 }}
        //       type="primary"
        //     >
        //       {value} ?<CloseOutlined onClick={() => onClear} /> :{" "}
        //       <SearchOutlined />
        //     </Button>
        //   ) : value ? (
        //     <CloseOutlined
        //       onClick={() => {
        //         onClear;
        //       }}
        //     />
        //   ) : (
        //     <SearchOutlined />
        //   )
        // }
      />
    </AutoCompleteStyled>
  ) : (
    <AutoCompleteStyled
    id={id}
    dropdownMatchSelectWidth={false}
    dropdownStyle={{ width: 300 }}
      value={value}
      options={dataSource.map((item,ind)=>renderCred(item,ind))}
      style={{ width }}
      onSelect={onSelect}
      onSearch={onSearch}
      placeholder={placeholder}
      disabled={disabled}
      onClick={onClick}
      onBlur={onBlur}
      // dropdownClassName="custom-dropdown"
    >
         <Input
        allowClear
        autoComplete="off" 
        prefix={checkIfServiceExists(value)?<img src={`/components-icons/${value}.png`}  style={{marginRight:"10px",width:"20px"}}/>:null }
        />
      
        </AutoCompleteStyled>
  );
};

AutoComplete.defaultProps = {
  width: "350px",
  placeholder: "Input here",
};

AutoComplete.propTypes = {
  customComponent: PropTypes.node,
  patterns: PropTypes.bool,
  patternButtons: PropTypes.bool,
  width: PropTypes.string,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export { AutoComplete };
