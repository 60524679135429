import axios from 'axios';
import Cookies from 'js-cookie';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { is_loading_users, fetch_users, set_users, is_loading_flows_details, fetch_flows_details, set_flows_details } = actions;

const getFlowDetails = () => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        dispatch(is_loading_flows_details(true));
        dispatch(fetch_flows_details(false));

        let config = {
            method: "get",
            url: process.env.REACT_APP_TRIGGERS_URL + "flow/list",
            headers: { Authorization: "Bearer " + authToken },
        };

        axios(config)
            .then((response) => {
                dispatch(setFlowsDetails(response.data));
                dispatch(is_loading_flows_details(false));
                openNotificationWithIcon('success', 'Load flows list', 'flows list Loaded Successfully');
            })
            .catch((error) => {
                console.log("Load flows list Error", error);
                openNotificationWithIcon('error', 'Load flows list', 'Failed Loading flows list');
                dispatch(is_loading_flows_details(false));
            });
    };
};
const getUsers = () => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        dispatch(is_loading_users(true));
        dispatch(fetch_users(false));

        let config = {
            method: "get",
            url: process.env.REACT_APP_TRIGGERS_URL + "user/list",
            headers: { Authorization: "Bearer " + authToken },
        };

        axios(config)
            .then((response) => {
                dispatch(setUsers(response.data));
                dispatch(is_loading_users(false));
                openNotificationWithIcon('success', 'Load Users', 'Users Loaded Successfully');
            })
            .catch((error) => {
                console.log("Load Users Error", error);
                openNotificationWithIcon('error', 'Load Users', 'Failed Loading Users');
                dispatch(is_loading_users(false));
            });
    };
};

const addUserToDB = (addUserData) => {
    return async (dispatch, getState) => {
        try {
            const authToken = getState().auth.token;
            dispatch(is_loading_users(true));

            var data = addUserData

            var config = {
                method: "post",
                url: process.env.REACT_APP_TRIGGERS_URL + "user/add",
                headers: {
                    Authorization: "Bearer " + authToken,
                    "Content-Type": "application/json",
                },
                data: data,
            };
            const response = await axios(config)
            dispatch(addUserLocally(response?.data));
            dispatch(is_loading_users(false));
            openNotificationWithIcon("success", "Adding User", "User added Successfully");
            return true
        }
        catch (error) {
            console.log('Add user error', error);
            openNotificationWithIcon("error", "Adding User", "Adding User Was Not Successfully !");
            dispatch(is_loading_users(false));
            return false
        }
    };
};

const addUserLocally = (newUser) => {
    return (dispatch, getState) => {
        const { usersList } = getState().users;

        let newUsersList = [...usersList, newUser];
        dispatch(set_users(newUsersList));
    };
};

const fetchUsersToggle = () => {
    return dispatch => {
        dispatch(fetch_users(true));
        dispatch(fetch_flows_details(true))
    };
};

const setUsers = (newList) => {
    return dispatch => {
        dispatch(set_users(newList));
    };
};
const setFlowsDetails = (newList) => {
    return dispatch => {
        dispatch(set_flows_details(newList));
    };
};

const deleteUserByID = (id) => {
    return (dispatch, getState) => {
        const authToken = getState().auth.token;
        dispatch(is_loading_users(true));
        const data = JSON.stringify({ user_id: id });

        let config = {
            method: "DELETE",
            url: process.env.REACT_APP_TRIGGERS_URL + "user/delete",
            headers: {
                Authorization: "Bearer " + authToken,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                openNotificationWithIcon("success", "Deleting User", "User Deleted Successfully");
                dispatch(deleteUserByIDLocally(id));
                dispatch(is_loading_users(false));
            })
            .catch(function (error) {
                openNotificationWithIcon("error", "Deleting User", "Deleting User Was Not Successfully !");
                console.log("Deleting User Error", error.data);
                dispatch(is_loading_users(false));
            });
    };
};

const deleteUserByIDLocally = (id) => {
    return (dispatch, getState) => {
        const { usersList } = getState().users;
        let newUsersList = [...usersList];

        newUsersList = newUsersList.filter(user => user.id !== id);
        dispatch(set_users(newUsersList));
    };
};
const changePassword = (data) => {
    return async (dispatch, getState) => {
        try {
            const authToken = getState().auth.token;
            let config = {
                method: "put",
                url: process.env.REACT_APP_TRIGGERS_URL + "user/change_password_from_admin",
                data: data,
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            };


            const response = await axios(config)
            openNotificationWithIcon("success", "change password", "password updated Successfully");
            return true;

        } catch (error) {
            console.log("change_password Error", error);
            openNotificationWithIcon("error", "updating password", "Failed updating role !");
            return false
        }
    };
};
const changeRole = (data) => {
    return async (dispatch, getState) => {
        try {
            const authToken = getState().auth.token;
            dispatch(is_loading_users(true));
            let config = {
                method: "put",
                url: process.env.REACT_APP_TRIGGERS_URL + "user/change_role",
                data: data,
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            };


            const response = await axios(config)
            openNotificationWithIcon("success", "updationg Role", "Role updated Successfully");
            dispatch(updateUserLocally(response?.data))
            dispatch(is_loading_users(false));
            return true


        } catch (error) {
            dispatch(is_loading_users(false));
            console.log("update role Error", error);
            openNotificationWithIcon("error", "updating role", "Failed updating role !");
            return false
        }
    };
};

const changeStatus = (data) => {
    return async(dispatch, getState) => {
        try {
            const authToken = getState().auth.token;
            dispatch(is_loading_users(true));
            let config = {
                method: "put",
                url: process.env.REACT_APP_TRIGGERS_URL + "user/change_status",
                data: data,
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            };


            const response =await axios(config)
            openNotificationWithIcon("success", "updating status", "Status updated Successfully");
            dispatch(updateUserLocally(response?.data))
            dispatch(is_loading_users(false));
            return true

        } catch (error) {
            console.log("update status Error", error);
            openNotificationWithIcon("error", "updating status", "Failed updating status !");
            return false
        }
    };

};

const changeInstanceName = (data) => {
    return async(dispatch, getState) => {
        try {
            const authToken = getState().auth.token;
            dispatch(is_loading_users(true));
            let config = {
                method: "put",
                url: process.env.REACT_APP_TRIGGERS_URL + "user/change_instance",
                data: data,
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            };


            const response = await axios(config)
            openNotificationWithIcon("success", "updating instance", "instance updated Successfully");
            dispatch(updateUserLocally(response?.data))
            dispatch(is_loading_users(false));
            return true

        } catch (error) {
            console.log("update instance Error", error);
            openNotificationWithIcon("error", "updating instance", "Failed updating instance !");
            return false

        }
    };

}
const updateUserLocally = (updatedUser) => {
    return (dispatch, getState) => {
        const { usersList } = getState().users;
        let newUsersList = [...usersList];
        let newUserIndex = newUsersList.findIndex(user => user.id === updatedUser.id);
        let newUser = newUsersList.find(user => user.id === updatedUser.id);
        newUsersList[newUserIndex] = { ...newUser, ...updatedUser };
        dispatch(set_users(newUsersList));
    };
};

const accessUser = (userId, setIsAccessingUser) => {
    return async (dispatch, getState) => {
        try {
            const authToken = getState().auth.token;

            var config = {
                method: "post",
                url: process.env.REACT_APP_TRIGGERS_URL + "user/generate_temp_access_token",
                headers: {
                    Authorization: "Bearer " + authToken,
                    "Content-Type": "application/json",
                },
                data: { user_id: userId },
            };
            const response = await axios(config)

            Cookies.set("email", response.data.email)
            Cookies.set("userId", response.data.id)
            Cookies.set("username", response.data.name)
            Cookies.set("token", response.data.token)
            window.location.href = '/admin';
            setIsAccessingUser(false)
        }
        catch (error) {
            console.log('Add user error', error);
            openNotificationWithIcon("error", "Access User", "Accessing user failed !");
            setIsAccessingUser(false)
        }
    }
};

export { accessUser, addUserToDB, changeInstanceName, changePassword, changeRole, changeStatus, deleteUserByID, fetchUsersToggle, getFlowDetails, getUsers, setFlowsDetails, setUsers };
