const actions = {
  SHOW_KEY_VAULT_MODAL: 'SHOW_KEY_VAULT_MODAL',
  SET_KEY_VAULT_DATA: 'SET_KEY_VAULT_DATA',
  IS_LOADING_RESPONSE_KEY_VAULT_DATA: "IS_LOADING_RESPONSE_KEY_VAULT_DATA",
  FETCH_RESPONSE_KEY_VAULT_DATA: "FETCH_RESPONSE_KEY_VAULT_DATA",
  IS_NEW_USER:"IS_NEW_USER",
  IAM_KEY_VAULT_COOKIES:"IAM_KEY_VAULT_COOKIES",

  SHOW_KEY_VAULT_MODAL_AWS: 'SHOW_KEY_VAULT_MODAL_AWS',
  SET_KEY_VAULT_DATA_AWS: 'SET_KEY_VAULT_DATA_AWS',
  IS_LOADING_RESPONSE_KEY_VAULT_DATA_AWS: "IS_LOADING_RESPONSE_KEY_VAULT_DATA_AWS",
  FETCH_RESPONSE_KEY_VAULT_DATA_AWS: "FETCH_RESPONSE_KEY_VAULT_DATA_AWS",
  IS_NEW_USER_AWS:"IS_NEW_USER_AWS",
  IAM_KEY_VAULT_COOKIES_AWS:"IAM_KEY_VAULT_COOKIES_AWS",

  show_key_vault_modal: (status) => {
    return {
      type: actions.SHOW_KEY_VAULT_MODAL,
      status: status,
    };
  },
  set_key_vault_data: (iamKeyVaultData) => {
    return {
      type: actions.SET_KEY_VAULT_DATA,
      iamKeyVaultData: iamKeyVaultData,
    };
  },

  set_key_vault_cookies_data: (iamKeyVaultCookies) => {
    return {
      type: actions.IAM_KEY_VAULT_COOKIES,
      iamKeyVaultCookies: iamKeyVaultCookies,
      // keyVaultAdminData: keyVaultAdminData,
    };
  },


  is_loading_response_key_vault_data: (status) => {
    return {
      type: actions.IS_LOADING_RESPONSE_KEY_VAULT_DATA,
      status: status,
    };
  },

  fetch_response_key_vault_data: (status) => {
    return {
      type: actions.FETCH_RESPONSE_KEY_VAULT_DATA,
      status: status,
    };
  },

  is_new_user: (status) => {
    return {
      type: actions.IS_NEW_USER,
      status: status,
    };
  },


  



  show_key_vault_modal_aws: (status) => {
    return {
      type: actions.SHOW_KEY_VAULT_MODAL_AWS,
      status: status,
    };
  },
  set_key_vault_data_aws: (iamKeyVaultDataAws) => {
    return {
      type: actions.SET_KEY_VAULT_DATA_AWS,
      iamKeyVaultDataAws: iamKeyVaultDataAws,
      // keyVaultAdminData: keyVaultAdminData,
    };
  },

  set_key_vault_cookies_data_aws: (iamKeyVaultCookiesAws) => {
    return {
      type: actions.IAM_KEY_VAULT_COOKIES_AWS,
      iamKeyVaultCookiesAws: iamKeyVaultCookiesAws,
    };
  },


  is_loading_response_key_vault_data_aws: (status) => {
    return {
      type: actions.IS_LOADING_RESPONSE_KEY_VAULT_DATA_AWS,
      status: status,
    };
  },

  fetch_response_key_vault_data_aws: (status) => {
    return {
      type: actions.FETCH_RESPONSE_KEY_VAULT_DATA_AWS,
      status: status,
    };
  },

  is_new_user_aws: (status) => {
    return {
      type: actions.IS_NEW_USER_AWS,
      status: status,
    };
  },



};

export default actions;
