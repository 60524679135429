import { Col, Empty, Row } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { Cards } from "../../components/cards/frame/cards-frame";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Popover } from "../../components/popup/popup";
// import { showKeyVaultModalToggle } from "../../redux/key-vault-modal/actionCreator";
// import { deactivatedRPAByToken, deleteRPAByID, getRPAs } from "../../redux/rpas/actionCreator";
import {
  getStories,
  fetchStoriesToggle,
} from "../../redux/stories/actionCreator";
import KeyVaultModal from "../key-vault-modal/KeyVaultModal";
import { Main, TopToolBox } from "../styled";
import StoriesCard from "./storiesCard";

const StoriesList = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const {
    isLoadingStories,
    fetchStories,
    storiesList,
    keyVaultIamDataAzure,
    keyVaultIamDataAws /* , keyVaultAdminData */,
    email,
  } = useSelector((state) => {
    return {
      isLoadingStories: state.stories.isLoadingStories,
      fetchStories: state.stories.fetchStories,
      storiesList: state.stories.storiesList,
      keyVaultIamDataAzure: state.keyVault.keyVaultIamDataAzure,
      keyVaultIamDataAws: state.keyVault.keyVaultIamDataAws,
      email: state.auth.email,
    };
  });

  useEffect(() => {
    if (fetchStories) {
      clearFilteringInputs();
      dispatch(getStories());
    } else {
      if (storiesList) {
        setFilteredData([...storiesList]);
      }
    }
  }, [fetchStories, storiesList]); // eslint-disable-line

  const handleSearch = (searchText) => {
    storiesList.map((val) => val.tags.map((tag) => console.log("hi", tag)));
    let newFilteredData = storiesList.filter((value) =>
      value.name.toUpperCase().startsWith(searchText.toUpperCase())
    );
    let tagsdata = storiesList.filter((value) => {
      console.log(
        "testingg",
        value.tags.map((val) =>
          val.toUpperCase().startsWith(searchText.toUpperCase())
        )
      );

      return value.tags
        .map((val) => {
          console.log("hello world", [
            val,
            searchText,
            val.toUpperCase().startsWith(searchText.toUpperCase()),
          ]);
          return val.toUpperCase().startsWith(searchText.toUpperCase());
        })
        .includes(true);
    });

    newFilteredData = [...newFilteredData, ...tagsdata];
    newFilteredData = [...new Set(newFilteredData)];

    const title = newFilteredData.map((e) => {
      return {
        title: e.name,
      };
    });

    setFilteredData(newFilteredData);
    setAutoCompleteList(title);
    setSearchText(searchText);
  }; // handle search

  function clearFilteringInputs() {
    setSearchText("");

    setAutoCompleteList([]);
  } // clear search input

  const handleNewOrEditActionClick = (rpaToken, storyID) => {
    if (rpaToken) {
      Cookies.set("rpaToken", rpaToken);
    }
    Cookies.set("azureKeyVaultIamData", keyVaultIamDataAzure);
    Cookies.set("keyVaultIamDataAws", keyVaultIamDataAws);
    Cookies.set("email", email);
    // Cookies.set('keyVaultAdminData', keyVaultAdminData);
    window.open(
      process.env.REACT_APP_RPA_DESIGNER_STORY_LINK +
      "/" +
      storyID /* , "RPA Designer" */
    );
    // window.open('http://localhost:3000/', "RPA Designer");
  };

  return (
    <>
      {/* <KeyVaultModal /> */}
      <PageHeader ghost title="Stories" />
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={24}>
              <TopToolBox>
                <Row gutter={15} className="justify-content-center">
                  <Col span={9}>
                    <div className="table-search-box">
                      <AutoComplete
                        onSearch={(event) => handleSearch(event)} //event here is the input value.
                        onSelect={(event) => handleSearch(event)} //event here is the input value.
                        onClear={() => setSearchText("")}
                        dataSource={autoCompleteList}
                        value={searchText}
                        placeholder="Search here"
                        width="100%"
                        patterns
                      />
                    </div>
                  </Col>
                  <Col span={9}>
                    <Popover
                      placement="bottomRight"
                      content={"Refresh Flows"}
                      action="hover"
                    >
                      <LoadingButton
                        toggleCondition={isLoadingStories}
                        onClick={() => dispatch(fetchStoriesToggle())}
                      />
                    </Popover>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <b>Total:&nbsp;</b>{filteredData.length}
                  </Col>
                </Row>
              </TopToolBox>
            </Col>
          </Row>
          {filteredData.length === 0 && <Empty style={{ marginTop: "20px" }} />}
          {/* <div className="stories-container"> */}
          <Row gutter={[12, 12]}>
            {filteredData.map((data, ind) => {
              return (
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <StoriesCard
                    key={data.id}
                    title={data.name}
                    summary={data.summary}
                    img={`data:image/png;base64, ${data.image}`}
                    backupImage={!data.image}
                    tags={data.tags}
                    token={data.token}
                    storyID={data.id}
                    handleNewOrEditActionClick={handleNewOrEditActionClick}
                  />
                </Col>

              );
            })}
          </Row>
          {/* </div> */}
        </Cards>
      </Main>
    </>
  );
};

export default StoriesList;
