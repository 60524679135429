import React from "react"
import logo from "./ziwo-logo.png";
import "./loader.css";
export default function Loader(){
    console.log("loader");
    return(
         <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>
    <img src={logo} width={"25%"}/>

    <div className="loader">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
</div>
    )
}