import Cookies from "js-cookie";

/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function oauthSignIn() {
  // Google's OAuth 2.0 endpoint for requesting an access token
  const authEndpoint = "https://accounts.google.com/o/oauth2/auth";
  const clientId = Cookies.get("clientID");
  const redirectUri = process.env.REACT_APP_DOMAIN + "/dashboard/credentials";
  // const redirectUri = "http://localhost:3001/dashboard/credentials";
  const responseType = "code";
  const scope = [
    "https://mail.google.com/",
    "https://www.googleapis.com/auth/spreadsheets",
    "https://www.googleapis.com/auth/documents",
    "https://www.googleapis.com/auth/drive",
    "https://www.googleapis.com/auth/calendar",
    "https://www.googleapis.com/auth/contacts",
    "https://www.googleapis.com/auth/adwords",
    "https://www.googleapis.com/auth/youtube",
    "https://www.googleapis.com/auth/pubsub"
  ];
  const accessType = "offline"; // Include this to request a refresh_token
  const scopeString = scope.join(" ");
  const googleAuthUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scopeString}&access_type=${accessType}&prompt=consent`;
  window.location.href = googleAuthUrl; // Redirect the user to Google's authentication page
}
function SalesForceSignIn() {
  const domain = Cookies.get("domainName");
  const scope = "full refresh_token offline_access";
  const client_id = Cookies.get("clientID");
  // const redirect_uri = process.env.REACT_APP_DOMAIN+"/dashboard/credentials";
  // const SalesForceUrl = `https://${domain}.my.salesforce.com/services/oauth2/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&scope=${scope}`;
  const SalesForceUrl = `https://${domain}.my.salesforce.com/services/oauth2/authorize?client_id=${client_id}&code_challenge=V7R2SXbfwXn4A1v1TV81GFenhIbLv6VrRLuHfx04jgQ&redirect_uri=${process.env.REACT_APP_DOMAIN}/dashboard/credentials&response_type=code&code_challenge_method=S256&grant_type=authorization_code$scope=full refresh_token offline_access profile`;
  window.location.href = SalesForceUrl;
}

function ZohoSignIn() {
  const client_id = Cookies.get("clientID");
  const ZohoUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.settings.ALL,ZohoCRM.modules.ALL,ZohoCRM.notifications.ALL&client_id=${client_id}&response_type=code&access_type=offline&redirect_uri=${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  window.location.href = ZohoUrl;
}
function ZoomSignIn() {
  const client_id = Cookies.get("clientID");
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;

  const ZoomUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${REDIRECT_URI}`;
  window.location.href = ZoomUrl;
}
function HubSpotSignIn() {
  const client_id = Cookies.get("clientID");
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  const scope =
    "oauth%20forms%20tickets%20sales-email-read%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.deals.read%20crm.objects.owners.read";
  const HubSpotUrl = `https://app.hubspot.com/oauth/authorize?client_id=${client_id}&scope=${scope}&redirect_uri=${REDIRECT_URI}&response_type=code`;
  window.location.href = HubSpotUrl;
}
function DropboxSignIn() {
  const app_key = Cookies.get("appKey");
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  const dropboxUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${app_key}&response_type=code&token_access_type=offline&redirect_uri=${REDIRECT_URI}`;
  window.location.href = dropboxUrl;
}
function MicrosoftSignIn() {
  const client_id = Cookies.get("clientId");
  const domain = Cookies.get("domain");
  const customUSes = Cookies.get("customUses")
  const scopesWithoutCustomUses = ['https://graph.microsoft.com/Calendars.ReadWrite', 'https://graph.microsoft.com/Contacts.ReadWrite', 'https://graph.microsoft.com/Mail.ReadWrite', 'https://graph.microsoft.com/Mail.Send', 'https://graph.microsoft.com/Files.ReadWrite.All', 'https://graph.microsoft.com/Tasks.ReadWrite', 'https://graph.microsoft.com/Channel.ReadBasic.All', 'https://graph.microsoft.com/Team.Create', 'https://graph.microsoft.com/Team.ReadBasic.All', 'https://graph.microsoft.com/ChatMessage.Send', 'https://graph.microsoft.com/ChannelMessage.Send', 'offline_access'];
  const scopesWithCustomUses = ['https://analysis.windows.net/powerbi/api/Dataset.ReadWrite.All', 'https://analysis.windows.net/powerbi/api/Report.ReadWrite.All', 'https://analysis.windows.net/powerbi/api/Workspace.ReadWrite.All', 'https://analysis.windows.net/powerbi/api/Tenant.Read.All']
  let scopes
  if (domain) {
    // Use Microsoft Dynamics 365 scopes
    scopes = [`https://${domain}.crm4.dynamics.com/user_impersonation`];
  } else {
    if (customUSes === "true")
      scopes = [...scopesWithoutCustomUses, ...scopesWithCustomUses]
    else
      scopes = [...scopesWithoutCustomUses]
  }
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  const scopeString = scopes.join(" ");
  const MicrosoftUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&response_type=code&redirect_uri=${REDIRECT_URI}&response_mode=query&scope=${scopeString}`;
  window.location.href = MicrosoftUrl;
}
function XeroSignIn() {
  const client_id = Cookies.get("clientID");
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  // const REDIRECT_URI = 'http://localhost:3001/dashboard/credentials'
  const scope = [
    "offline_access",
    "openid",
    "profile",
    "accounting.settings",
    "accounting.contacts.read",
    "accounting.transactions.read",
    "accounting.transactions",
    "accounting.contacts",
    "email",
    "accounting.settings.read",
  ];
  const scopeString = scope.join(" ");
  const XeroUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${client_id}&redirect_uri=${REDIRECT_URI}&scope=${scopeString}`;
  window.location.href = XeroUrl;
}

function PipeDriveSignIn() {
  const client_id = Cookies.get("clientID");
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  const pipeDriveUrl = `https://oauth.pipedrive.com/oauth/authorize?client_id=${client_id}&redirect_uri=${REDIRECT_URI}`;
  window.location.href = pipeDriveUrl;
}
function QuickBooksSignIn() {
  const client_id = Cookies.get("clientID");
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  const QuickBooksUrl = `https://appcenter.intuit.com/connect/oauth2?response_type=code&client_id=${client_id}&redirect_uri=${REDIRECT_URI}&scope=com.intuit.quickbooks.accounting+openid+profile+email+phone+address&state=97gUOkLHnsnWIUrZHQU6uayFDgYBc5`;

  window.location.href = QuickBooksUrl;
}

function JiraSignIn() {
  const client_id = Cookies.get("clientID");
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  const scopes = [
    "read:jira-work",
    "manage:jira-webhook",
    "offline_access",
    "read:webhook:jira",
    "read:jql:jira",
    "write:webhook:jira",
    "delete:webhook:jira"
  ];
  const scopesString = scopes.join(" ")
  const encoded_scopes = encodeURIComponent(scopesString);
  const encoded_redirect_uri = encodeURIComponent(REDIRECT_URI);
  const JiraUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${client_id}&scope=${encoded_scopes}&redirect_uri=${encoded_redirect_uri}&response_type=code&prompt=consent`;
  window.location.href = JiraUrl;
}
function FacebookSignIn() {
  const app_id = Cookies.get("appId");
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  // const REDIRECT_URI = `http://localhost:3000/dashboard/credentials`;
  const url = "https://www.facebook.com/v20.0/dialog/oauth";
  const data = {
    client_id: app_id,
    redirect_uri: REDIRECT_URI,
    response_type: "token",
    auth_type: "rerequest",
    scope: "public_profile email pages_manage_engagement pages_manage_posts pages_read_user_content pages_manage_metadata pages_read_engagement page_events pages_messaging pages_show_list"
  };

  // Construct the full URL with query parameters
  const params = new URLSearchParams(data);
  const fullUrl = `${url}?${params.toString()}`;
  window.location.href = fullUrl;
}
function InstagramSignIn() {
  const client_id = Cookies.get("clientID");
  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  // const REDIRECT_URI = `http://localhost:3000/dashboard/credentials`;
  const scopes = 'business_basic,business_manage_messages,business_manage_comments,business_content_publish';
  const encoded_redirect_uri = encodeURIComponent(REDIRECT_URI);
  const InstaUrl = `https://www.instagram.com/oauth/authorize?client_id=${client_id}&redirect_uri=${encoded_redirect_uri}&scope=${scopes}&response_type=code`;
  window.location.href = InstaUrl;
}
function LinkedInSignIn() {
  const client_id = Cookies.get("clientID");
  const behalfOfCompany = Cookies.get("behalfOfCompany");

  const REDIRECT_URI = `${process.env.REACT_APP_DOMAIN}/dashboard/credentials`;
  // const REDIRECT_URI = `http://localhost:3000/dashboard/credentials`;
  const scopesWithoutOrganization = ["w_member_social", "profile", "email", "openid"];
  const scopes = behalfOfCompany === "true" ? [...scopesWithoutOrganization, "w_organization_social"] : [...scopesWithoutOrganization]
  const scopesString = scopes.join(" ")
  const encoded_redirect_uri = encodeURIComponent(REDIRECT_URI);
  const LinkedInUrl = `https://www.linkedin.com/oauth/v2/authorization?client_id=${client_id}&redirect_uri=${encoded_redirect_uri}&scope=${scopesString}&response_type=code`;
  window.location.href = LinkedInUrl;
}
function containsOnlyLettersAndNumbers(inputString) {
  // Regular expression pattern to match only letters and numbers
  var pattern = /^[a-zA-Z0-9]+$/;
  // Test the input string against the pattern
  return pattern.test(inputString);
}
const credsThatNeedRedirects = {
  Google: true,
  ZohoCRM: true,
  SalesForce: true,
  PipeDrive: true,
  Zoom: true,
  HubSpot: true,
  Dropbox: true,
  Microsoft: true,
  Xero: true,
  QuickBooks: true,
  Jira: true,
  Facebook: true,
  Instagram: true,
  LinkedIn: true,
};
const oAuth2RedirectUris =
{
  label: "Authorized redirect URIs",
  value: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
  type: Object.keys(credsThatNeedRedirects),
}
const javascriptOrigins = {
  label: "Authorized JavaScript origins",
  value: process.env.REACT_APP_DOMAIN,
  type: ["Google"],
}

const serviceFields = [
  {
    type: "AirTable",
    service: "AirTable",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accesstoken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Asana",
    service: "Asana",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  // {
  //   type:"Anthropic",
  //   service: "Anthropic",
  //   Service_name:"",
  //   cred:[
  //     {
  //       label: "API key",
  //       Credential_name: "apiKey",
  //       Credential_value: "",
  //       hashed: true,
  //     },
  //   ]
  // },
  // {
  //   type:"AWSBedrock",
  //   service: "AWSBedrock",
  //   Service_name:"",
  //   cred:[
  //     {
  //       label: "Region Name",
  //       Credential_name: "regionName",
  //       Credential_value: "",
  //     },
  //   ]
  // },
  // {
  //   type:"AzureOpenAi",
  //   service:"AzureOpenAi",
  //   Service_name:"",
  //   cred:[
  //     {
  //       label: "API key",
  //       Credential_name: "apiKey",
  //       Credential_value: "",
  //       hashed: true,
  //     },
  //   ]
  // },
  {
    type: "BambooHr",
    service: "BambooHr",
    Service_name: "",
    cred: [
      {
        label: "Api Key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Subdomain",
        Credential_name: "subdomain",
        Credential_value: "",
      },
    ],
  },
  {
    type: "Brevo",
    service: "Brevo",
    Service_name: "",
    cred: [
      {
        label: "API key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Calendly",
    service: "Calendly",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Clockify",
    service: "Clockify",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "ClickUp",
    service: "ClickUp",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      }
    ]
  },
  {
    type: "ConvertKit",
    service: "ConvertKit",
    Service_name: "",
    cred: [
      {
        label: "Api Secret",
        Credential_name: "apiSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  // {
  //   type: "DataMatrix",
  //   service: "DataMatrix",
  //   Service_name: "",
  //   cred: [
  //     {
  //       label: "API key",
  //       Credential_name: "apiKey",
  //       Credential_value: "",
  //       hashed: true,
  //     },
  //   ]
  // },
  {
    type: "Discord",
    service: "Discord",
    Service_name: "",
    cred: [
      {
        label: "Bot Token",
        Credential_name: "botToken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Dropbox",
    service: "Dropbox",
    Service_name: "",
    cred: [
      {
        label: "APP Key",
        Credential_name: "appKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "APP Secret",
        Credential_name: "appSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  // {
  //   type: "ElasticSearch",
  //   service: "ElasticSearch",
  //   Service_name: "",
  //   cred: [
  //     {
  //       label: "Cloud ID",
  //       Credential_name: "cloudId",
  //       Credential_value: "",
  //     },
  //     {
  //       label: "Username",
  //       Credential_name: "userName",
  //       Credential_value: "",
  //     },
  //     {
  //       label: "Password",
  //       Credential_name: "password",
  //       Credential_value: "",
  //       hashed: true,
  //     }
  //   ],
  // },
  {
    type: "Facebook",
    service: "Facebook",
    Service_name: "",
    cred: [
      {
        label: "App ID",
        Credential_name: "appId",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Freshdesk",
    service: "Freshdesk",
    Service_name: "",
    cred: [
      {
        label: "Api Key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Domain",
        Credential_name: "domain",
        Credential_value: "",
      },
    ],
  },
  {
    type: "Freshsales",
    service: "Freshsales",
    Service_name: "",
    cred: [
      {
        label: "Api Key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Domain",
        Credential_name: "domain",
        Credential_value: "",
      },
    ]
  },
  {
    type: "Github",
    service: "Github",
    Service_name: "",
    cred: [
      {
        label: "Type",
        Credential_name: "type",
        Credential_value: "Access Token",
        dropdown: true,
        list: [
          {
            label: "Access Token",
            value: "Access Token",
          },
          {
            label: "OAuth Application",
            value: "OAuth Application",
          },
        ],
        options: {
          "Access Token": [
            {
              label: "Access Token",
              Credential_name: "accessToken",
              Credential_value: "",
              hashed: true,
            },
          ],
          "OAuth Application": [
            {
              label: "Client ID",
              Credential_name: "clientID",
              Credential_value: "",
              hashed: true,
            },
            {
              label: "Client Secret",
              Credential_name: "clientSecret",
              Credential_value: "",
              hashed: true,
            },
          ],
        },
      },
    ],
  },
  {
    type: "Google",
    service: "Google",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Developer Token",
        Credential_name: "developerToken",
        Credential_value: "",
        optional: true,
        hashed: true,
      },
      {
        label: "Private Key",
        Credential_name: "privateKey",
        Credential_value: "",
        optional: true,
        hashed: true,
      },
      {
        label: "Service Account Email",
        Credential_name: "clientEmail",
        Credential_value: "",
        optional: true,
        // hashed: true,
      },
    ],
  },
  // {
  //   type:"GooglePaLMGemini",
  //   service : "GooglePaLMGemini",
  //   Service_name: "",
  //   cred:[
  //     {
  //       label: "API key",
  //       Credential_name: "apiKey",
  //       Credential_value: "",
  //       hashed: true,
  //     },
  //   ]
  // },
  {
    type: "Grafana",
    service: "Grafana",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Domain",
        Credential_name: "domain",
        Credential_value: "",
      },
    ],
  },
  // {
  //   type:"HuggingFace",
  //   service: "HuggingFace",
  //   Service_name: "",
  //   cred:[
  //     {
  //       label: "API Token",
  //       Credential_name: "apiToken",
  //       Credential_value: "",
  //       hashed: true,
  //     },
  //   ]
  // },
  {
    type: "HubSpot",
    service: "HubSpot",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Instagram",
    service: "Instagram",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Jira",
    service: "Jira",
    Service_name: "",
    cred: [
      {
        label: "Type",
        Credential_name: "type",
        Credential_value: "API Token",
        dropdown: true,
        credTypeConnection: true,
        list: [
          {
            label: "API Token",
            value: "API Token",
          },
          {
            label: "OAuth Application",
            value: "OAuth Application",
          },
        ],
        options: {
          "API Token": [
            {
              label: "Email",
              Credential_name: "email",
              Credential_value: "",
            },
            {
              label: "Api Token",
              Credential_name: "apiToken",
              Credential_value: "",
              hashed: true,
            },
            {
              label: "Domain",
              Credential_name: "domain",
              Credential_value: "",
            },
          ],
          "OAuth Application": [
            {
              label: "Client ID",
              Credential_name: "clientID",
              Credential_value: "",
              hashed: true,
            },
            {
              label: "Client Secret",
              Credential_name: "clientSecret",
              Credential_value: "",
              hashed: true,
            },
            {
              label: "Domain",
              Credential_name: "domain",
              Credential_value: "",
              placeholder: "https://your-domain-name.atlassian.net"
            }
          ]
        }
      }
    ],
  },
  {
    type: "Keap",
    service: "Keap",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    service: "LeadSquared",
    type: "LeadSquared",
    Service_name: "",
    cred: [
      {
        label: "Access Key",
        Credential_name: "accessKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Secret Key",
        Credential_name: "secretKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "API Host",
        Credential_name: "apiHost",
        Credential_value: "",
      },
    ]
  },
  {
    type: "LinkedIn",
    service: "LinkedIn",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "On behalf one of the company",
        Credential_name: "behalfOfCompany",
        Credential_value: false,
        switch: true,
      }
    ],
  },
  {
    type: "Magento2",
    service: "Magento2",
    Service_name: "",
    cred: [
      {
        label: "host",
        Credential_name: "host",
        Credential_value: "",
      },
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
    ]
  },
  {
    type: "MailerLite",
    service: "MailerLite",
    Service_name: "",
    cred: [
      {
        label: "API key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "MailChimp",
    service: "MailChimp",
    Service_name: "",
    cred: [
      {
        label: "Api Key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Server Prefix",
        Credential_name: "serverPrefix",
        Credential_value: "",
      },
    ],
  },
  // {
  //   type: "Milvus",
  //   service: "Milvus",
  //   Service_name: "",
  //   cred: [
  //     {
  //       label: "Host",
  //       Credential_name: "host",
  //       Credential_value: "",
  //     },
  //     {
  //       label: "Port",
  //       Credential_name: "port",
  //       Credential_value: "",
  //     }
  //   ],
  // },
  {
    type: "Monday",
    service: "Monday",
    Service_name: "",
    cred: [
      {
        label: "Api Token",
        Credential_name: "apiToken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "MongoDB",
    service: "MongoDB",
    Service_name: "",
    cred: [
      {
        label: "URI",
        Credential_name: "uri",
        Credential_value: ""
      },
      {
        label: "DataBase Name",
        Credential_name: "databaseName",
        Credential_value: "",
      }
    ]
  },
  {
    type: "Microsoft",
    service: "Microsoft",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientId",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Is Used For Power BI, Sharepoint, Teams Or Dynamic CRM",
        Credential_name: "customUses",
        Credential_value: false,
        switch: true,
      },
      {
        label: "Domain",
        Credential_name: "domain",
        Credential_value: "",
        optional: true,
        hashed: true,
      },
    ],
  },
  // {
  //   type:"MistralAi",
  //   service:"MistralAi",
  //   Service_name:"",
  //   cred:[
  //     {
  //       label: "API key",
  //       Credential_name: "apiKey",
  //       Credential_value: "",
  //       hashed: true,
  //     },
  //   ]
  // },
  {
    type: "MySQL",
    service: "MySQL",
    Service_name: "",
    cred: [
      {
        label: "Host",
        Credential_name: "host",
        Credential_value: "",
      },
      {
        label: "DataBase Name",
        Credential_name: "database",
        Credential_value: "",
      },
      {
        label: "User",
        Credential_name: "user",
        Credential_value: "",
      },
      {
        label: "Password",
        Credential_name: "password",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Port",
        Credential_name: "port",
        Credential_value: "",
      },
      {
        label: "Connect Timeout",
        Credential_name: "connectionTimeout",
        Credential_value: "",
      },
      {
        label: "SSL",
        Credential_name: "sslEnabled",
        Credential_value: false,
        switch: true,
        options: {
          true: [
            {
              label: "CA Certificate",
              Credential_name: "sslCa",
              Credential_value: "",
              hashed: true,
            },
            {
              label: "Client Private Key",
              Credential_name: "sslKey",
              Credential_value: "",
              hashed: true,
            },
            {
              label: "Client Certificate",
              Credential_name: "sslCert",
              Credential_value: "",
              hashed: true,
            },
          ],
        },
      },
      {
        label: "SSH Tunnel",
        Credential_name: "sshEnabled",
        Credential_value: false,
        switch: true,
        options: {
          true: [
            {
              label: "SSH Authenticate with",
              Credential_name: "sshAuthenticateWith",
              Credential_value: "Private Key",
              dropdown: true,
              list: [
                {
                  label: "Private Key",
                  value: "Private Key",
                },
                {
                  label: "Password",
                  value: "Password",
                },
              ],
              options: {
                "Private Key": [
                  {
                    label: "Private Key",
                    Credential_name: "sshPrivateKey",
                    Credential_value: "",
                    hashed: true,
                  },
                  {
                    label: "Passphrase",
                    Credential_name: "sshPassphrase",
                    Credential_value: "",
                  },
                ],
                Password: [
                  {
                    label: "SSH Password",
                    Credential_name: "sshPassword",
                    Credential_value: "",
                    hashed: true,
                  },
                ],
              },
            },
            {
              label: "SSH Host",
              Credential_name: "sshHost",
              Credential_value: "",
            },
            {
              label: "SSH Port",
              Credential_name: "sshPort",
              Credential_value: "",
            },
            {
              label: "SSH MySQL Port",
              Credential_name: "sshMysqlPort",
              Credential_value: "",
            },
            {
              label: "SSH User",
              Credential_name: "sshUser",
              Credential_value: "",
            },
          ],
        },
      },
    ],
  },
  {
    type: "Notion",
    service: "Notion",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Odoo",
    service: "Odoo",
    Service_name: "",
    cred: [
      {
        label: "Url",
        Credential_name: "url",
        Credential_value: "",
      },
      {
        label: "DataBase Name",
        Credential_name: "db",
        Credential_value: "",
      },
      {
        label: "Username",
        Credential_name: "username",
        Credential_value: "",
      },
      {
        label: "Api Password",
        Credential_name: "apiPassword",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "OpenAI",
    service: "OpenAI",
    Service_name: "",
    cred: [
      {
        label: "API Key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Organization",
        Credential_name: "organization",
        Credential_value: "",
        optional: true,
      },
    ],
  },
  // {
  //   type: "Ollama",
  //   service: "Ollama",
  //   Service_name: "",
  //   cred: [
  //     {
  //       label: "Base URL",
  //       Credential_name: "ollamaBaseUrl",
  //       Credential_value: "",
  //       hashed: true,
  //     },
  //   ],
  // },
  {
    type: "Pinecone",
    service: "Pinecone",
    Service_name: "",
    cred: [
      {
        label: "Api Key",
        Credential_name: "pineconeApiKey",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "PipeDrive",
    service: "PipeDrive",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Postgres",
    service: "Postgres",
    Service_name: "",
    cred: [
      {
        label: "Host",
        Credential_name: "host",
        Credential_value: "",
      },
      {
        label: "DataBase Name",
        Credential_name: "database",
        Credential_value: "",
      },
      {
        label: "User",
        Credential_name: "username",
        Credential_value: "",
      },
      {
        label: "Password",
        Credential_name: "password",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Port",
        Credential_name: "port",
        Credential_value: "",
      },
      {
        label: "SSL Mode",
        Credential_name: "sslMode",
        Credential_value: "None",
        dropdown: true,
        optional: true,
        list: [
          {
            label: "None",
            value: "None",
          },
          {
            label: "Disable",
            value: "disable",
          },
          {
            label: "Require",
            value: "require",
          },
          {
            label: "Allow",
            value: "allow",
          },
          {
            label: "Prefer",
            value: "prefer",
          },
          {
            label: "Verify-CA",
            value: "verify-ca",
          },
          {
            label: "Verify-Full",
            value: "verify-full",
          },
        ],
      }
    ],
  },
  {
    type: "QuickBooks",
    service: "QuickBooks",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Environment",
        Credential_name: "environment",
        Credential_value: "Sandbox",
        dropdown: true,
        list: [
          {
            label: "Sandbox",
            value: "Sandbox",
          },
          {
            label: "Production",
            value: "Production",
          },
        ],
      },
    ],
  },
  {
    type: "QuickBase",
    service: "QuickBase",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Realm hostname",
        Credential_name: "realmHostname",
        Credential_value: "",
      },
    ],
  },
  {
    type: "SalesForce",
    service: "SalesForce",
    Service_name: "",
    cred: [
      {
        label: "Domain Name",
        Credential_name: "domainName",
        Credential_value: "",
      },
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  // {
  //   type: "SerpApi",
  //   service: "SerpApi",
  //   Service_name: "",
  //   cred: [
  //     {
  //       label: "Api Key",
  //       Credential_name: "apiKey",
  //       Credential_value: "",
  //       hashed: true,
  //     },]
  // },
  {
    type: "Shopify",
    service: "Shopify",
    Service_name: "",
    cred: [
      {
        label: "Api Key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Api Password",
        Credential_name: "apiPassword",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Subdomain",
        Credential_name: "subdomain",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Slack",
    service: "Slack",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Stripe",
    service: "Stripe",
    Service_name: "",
    cred: [
      {
        label: "API key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Telegram",
    service: "Telegram",
    Service_name: "",
    cred: [
      {
        label: "Access Token",
        Credential_name: "accessToken",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  // {
  //   type:"TogetherAi",
  //   service: "TogetherAi",
  //   Service_name: "",
  //   cred:[
  //     {
  //       label: "API key",
  //       Credential_name: "apiKey",
  //       Credential_value: "",
  //       hashed: true,
  //     },
  //   ]
  // },
  {
    type: "Trello",
    service: "Trello",
    Service_name: "",
    cred: [
      {
        label: "Api Key",
        Credential_name: "apiKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Api Secret",
        Credential_name: "apiSecret",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Token",
        Credential_name: "token",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Wordpress",
    service: "Wordpress",
    Service_name: "",
    cred: [
      {
        label: "User Name",
        Credential_name: "username",
        Credential_value: "",
      },
      {
        label: "Password",
        Credential_name: "applicationPassword",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Base URL",
        Credential_name: "baseUrl",
        Credential_value: "",
      },
    ],
  },
  {
    type: "Woocommerce",
    service: "Woocommerce",
    Service_name: "",
    cred: [
      {
        label: "Base URL",
        Credential_name: "baseUrl",
        Credential_value: "",
      },
      {
        label: "Consumer Key",
        Credential_name: "consumerKey",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Consumer Secret",
        Credential_name: "consumerSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Xero",
    service: "Xero",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Zendesk",
    service: "Zendesk",
    Service_name: "",
    cred: [
      {
        label: "Email",
        Credential_name: "email",
        Credential_value: "",
      },
      {
        label: "Api Token",
        Credential_name: "apiToken",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Base URL",
        Credential_name: "baseUrl",
        Credential_value: "",
      },
    ],
  },
  {
    type: "ZohoCRM",
    service: "ZohoCRM",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    type: "Zoom",
    service: "Zoom",
    Service_name: "",
    cred: [
      {
        label: "Client ID",
        Credential_name: "clientID",
        Credential_value: "",
        hashed: true,
      },
      {
        label: "Client Secret",
        Credential_name: "clientSecret",
        Credential_value: "",
        hashed: true,
      },
    ],
  },
  {
    service: "Ziwo",
    Service_name: "",
    cred: [
      {
        label: "Base URL",
        Credential_name: "BaseURL",
        Credential_value: "",
      },
      {
        label: "User Name",
        Credential_name: "UserName",
        Credential_value: "",
      },
      {
        label: "Password",
        Credential_name: "Password",
        Credential_value: "",
        hashed: true,
      },
    ],
  }
];

const credentialTypes = ["All", ...serviceFields.map((s) => s.service)];
let imagesByKey = { noImage: "/components-icons/noImage.png" };
credentialTypes.map((c) => {
  let img;
  try {
    img = "/components-icons/" + c + ".png";
  } catch (error) {
    img = "/components-icons/noImage.png";
  }
  imagesByKey = { ...imagesByKey, [c]: img };
});
const setCredOnOneLevel = (apiRes) => {
  let fieldsToAdd = [];
  apiRes.forEach((credField) => {
    if (
      (credField.dropdown || credField.switch) &&
      credField.options &&
      credField.hasOwnProperty("options")
    ) {
      const clonedItem = { ...credField };
      delete clonedItem.options;

      if (!clonedItem.deleteOnCreate)
        fieldsToAdd.push({ ...clonedItem });
      Object.keys(credField.options).forEach((item20, i2) => {
        if (credField.Credential_value.toString() === item20) {
          fieldsToAdd = [
            ...fieldsToAdd,
            ...setCredOnOneLevel(
              credField.options[credField.Credential_value.toString()]
            ),
          ];
        }
      });
    }
    else if (credField.api) {
      const clonedItem = { ...credField };
      if (clonedItem.value && clonedItem.Credential_value !== "None") {
        const currentItem = clonedItem.list.find(item => item.value === clonedItem.Credential_value)
        Object.keys(currentItem[clonedItem.value]).forEach(key => {
          fieldsToAdd.push({ Credential_value: currentItem[clonedItem.value][key], Credential_name: key });
        })
      } else {
        fieldsToAdd.push({ ...credField });
      }
    }
    else if (!credField.deleteOnCreate)
      fieldsToAdd.push({ ...credField });
  });
  return fieldsToAdd;
};
export {
  LinkedInSignIn,
  InstagramSignIn, DropboxSignIn, FacebookSignIn, HubSpotSignIn, JiraSignIn, MicrosoftSignIn, PipeDriveSignIn, QuickBooksSignIn, SalesForceSignIn, XeroSignIn, ZohoSignIn, ZoomSignIn, containsOnlyLettersAndNumbers, credentialTypes,
  credsThatNeedRedirects, imagesByKey, javascriptOrigins, oAuth2RedirectUris, oauthSignIn, serviceFields, setCredOnOneLevel
};

