import { Col, Popconfirm, Radio, Row, Table } from "antd";
import FeatherIcon from "feather-icons-react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { Button } from "../../components/buttons/buttons";
import { Cards } from "../../components/cards/frame/cards-frame";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Popover } from "../../components/popup/popup";
import { deleteBotByID, fetchBotsToggle, getBots } from "../../redux/chatbots/actionCreator";
import { Main, TableWrapper, TopToolBox } from "../styled";
import { CloseCircleOutlined } from "@ant-design/icons";

const Bots = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const [radioGroupValue, setRadioGroupValue] = useState('All');
  const [filteredBotsListByType, setFilteredBotsListByType] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const botsTypeFilterKey = ["All", "Production", "Staging", "Draft"];

  let { isLoadingBots, fetchBots, botsList, selectedType } = useSelector(state => {
    return {
      selectedType: state.chatbots.selectedType,
      isLoadingBots: state.chatbots.isLoadingBots,
      fetchBots: state.chatbots.fetchBots,
      botsList: state.chatbots.botsList,
    };
  });

  useEffect(() => {
    clearFilteringInputs();
    let newFilteredBotsList = botsList.filter(bot => bot.flow_type === selectedType);
    setFilteredBotsListByType(newFilteredBotsList);
  }, [botsList, selectedType]);

  useEffect(() => {
    if (fetchBots) {
      clearFilteringInputs();
      dispatch(getBots());
    }
    else {
      setFilteredData(filteredBotsListByType);
    }
  }, [fetchBots, filteredBotsListByType]);// eslint-disable-line

  const handleSearch = (searchText) => {
    const newFilteredData = filteredBotsListByType.filter((value) => value.flow_name.toUpperCase().startsWith(searchText.toUpperCase()));

    const title = newFilteredData.map((e) => {
      return {
        title: e.flow_name,
      };
    });

    setFilteredData(newFilteredData);
    setAutoCompleteList(title);
    setRadioGroupValue('All');
    setSearchText(searchText);
  };

  const handleChangeForFilterByStatus = (event) => {
    let { value } = event.target;
    setSearchText('');
    setRadioGroupValue(value);

    let newFilteredData = [];
    if (value === "All") {
      newFilteredData = [...filteredBotsListByType]
    }
    else {
      newFilteredData = filteredBotsListByType.filter((bot) => bot.status === value);
    }

    setFilteredData(newFilteredData);
  };

  function clearFilteringInputs() {
    setSearchText('');
    setRadioGroupValue('All');
    setAutoCompleteList([]);
  }

  const handleEditOrAddActionClick = (botID) => {
    if (botID) {
      Cookies.set('botID', botID);
    };

    Cookies.set('botType', selectedType);
    // selectedType === "Web" ? window.open(process.env.REACT_APP_WEB_BOT_DESIGNER_LINK) :
      // selectedType === "Teams" ? window.open(process.env.REACT_APP_TEAMS_BOT_DESIGNER_LINK) :
        selectedType === "Whatsapp" ? window.open(process.env.REACT_APP_WHATSAPP_BOT_DESIGNER_LINK) :
          // selectedType === "Slack" ? window.open(process.env.REACT_APP_SLACK_BOT_DESIGNER_LINK) :
            window.open(process.env.REACT_APP_WEB_BOT_DESIGNER_LINK)
    // window.open('http://localhost:3000/');
    // window.open('http://localhost:3001/');
  };

  const convertFilteredDataToHTML = () => {
    const newFilteredDataAsHtml = [];

    filteredData.map((value, key) => {
      const { id, flow_name, status, } = value;
      return newFilteredDataAsHtml.push({
        key: key,
        name: <span className="order-id">{flow_name}</span>,
        botStatus: status === 'Draft'
          ? <span className={`status ${"error"}`}>{status}</span>
          : status === 'Production'
            ? <span className={`status ${"Success"}`}>{status}</span>
            : <span className={`status ${"warning"}`}>{status}</span>,
        action: (
          <div className="table-actions">
            <Button className="btn-icon" type="info" shape="circle" onClick={event => handleEditOrAddActionClick(id)}>
              <FeatherIcon icon="edit" size={16} />
            </Button>
            <Popconfirm
              icon={
                <CloseCircleOutlined style={{ color: "rgb(173,50,120)" }} />
              }
              placement="top"
              title="Delete this Bot"
              onConfirm={() => {
                dispatch(deleteBotByID(id, clearFilteringInputs))
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button className="btn-icon" type="info" shape="circle"
              // onClick={() => dispatch(deleteBotByID(id, clearFilteringInputs))}
              >
                <FeatherIcon icon="trash-2" size={16} />
              </Button>
            </Popconfirm>
          </div>
        ),
      });
    });

    return newFilteredDataAsHtml;
  };

  const columns = [
    { title: "Bot Name", dataIndex: "name" },
    { title: 'Status', dataIndex: 'botStatus' },
    { title: 'Action', dataIndex: 'action' }
  ];

  const filteredDataAsHtml = convertFilteredDataToHTML();

  return (
    <>
      <PageHeader ghost title={selectedType + " Bots"} />
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={24}>
              <TopToolBox>
                <Row gutter={15} className="justify-content-center">
                  <Col span={9}>
                    <div className="table-search-box">
                      <AutoComplete
                        onSearch={event => handleSearch(event)} //event here is the input value.
                        onSelect={event => handleSearch(event)} //event here is the input value.
                        onClear={() => setSearchText("")}
                        dataSource={autoCompleteList}
                        value={searchText}
                        placeholder="Search here"
                        width="100%"
                        patterns
                      />
                    </div>
                  </Col>
                  <Col span={9}>
                    <Popover placement="bottomRight" content={"Refresh Bots"} action="hover">
                      <LoadingButton
                        toggleCondition={isLoadingBots}
                        onClick={() => dispatch(fetchBotsToggle())}
                      />
                    </Popover>
                  </Col>
                  {/* <Col span={7} style={{ textAlign: 'center' }}>
                    <Radio.Group
                      onChange={handleChangeForFilterByStatus}
                      value={radioGroupValue}
                      disabled={isLoadingBots}
                    >
                      {botsTypeFilterKey.map((value) => {
                        return (
                          <Radio.Button key={value} value={value}>{value}</Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </Col> */}
                  <Col span={6} style={{ textAlign: 'right', display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Button size="small" style={{ display: "flex", padding: "13px 12px" }} type="primary" disabled={isLoadingBots} onClick={() => handleEditOrAddActionClick()}>
                      <FeatherIcon icon="plus" size={12} />
                      {"New " + selectedType + " Bot"}
                    </Button>
                  </Col>
                </Row>
                <Row gutter={15} className="justify-content-center">
                  <Col span={22}>
                    <Radio.Group
                      onChange={handleChangeForFilterByStatus}
                      value={radioGroupValue}
                      disabled={isLoadingBots}
                    >
                      {botsTypeFilterKey.map((value) => {
                        return (
                          <Radio.Button key={value} value={value}>{value}</Radio.Button>
                        );
                      })}

                    </Radio.Group>
                  </Col>
                </Row>
              </TopToolBox>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-order table-responsive">
                <Table
                  columns={columns}
                  dataSource={filteredDataAsHtml}
                  pagination={{ pageSize: 6, showSizeChanger: false, total: filteredDataAsHtml.length }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default Bots;