import axios from 'axios';
import openNotificationWithIcon from '../../components/notification/notification';
import actions from './actions';

const { is_loading_tags, fetch_tags, set_tags } = actions;
// const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM5IiwibmFtZSI6IkppcHNhIiwicm9sZSI6ImNsaWVudCIsImlhdCI6MTYzNjEwODcwMywiZXhwIjoxNjY3NjQ0NzAzfQ.lnikRRTe39g5RSbHKU91lM_5Aed3VfmUpMF-a7TtDdo';

const getTags = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_tags(true));
    dispatch(fetch_tags(false));

    let config = {
      method: "get",
      url: process.env.REACT_APP_TRIGGERS_URL + "tag/list",
      headers: { Authorization: "Bearer " + authToken },
    };

    axios(config)
      .then((response) => {
        dispatch(setTags(response.data));
        dispatch(is_loading_tags(false));
        openNotificationWithIcon('success', 'Load Tags', 'Tags Loaded Successfully');
      })
      .catch((error) => {
        console.log("Load Tags Error", error);
        openNotificationWithIcon('error', 'Load Tags', 'Failed Loading Tags');
        dispatch(is_loading_tags(false));
      });
  };
};

const addTagToDB = (tagName) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_tags(true));

    var data = { "name": tagName };

    var config = {
      method: "post",
      url: process.env.REACT_APP_TRIGGERS_URL + "tag/add",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    const date = new Date()
    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Adding Tag", "Tag added Successfully");
        dispatch(addTagLocally({ name: tagName, number_of_flows: 0, created_at: "now" }));
        dispatch(is_loading_tags(false));
      })
      .catch(function (error) {
        console.log('Add tag error', error);
        openNotificationWithIcon("error", "Adding Tag", "Adding Tag Was Not Successfully !");
        dispatch(is_loading_tags(false));
      });
  };
};

const addTagLocally = (newTag) => {
  return (dispatch, getState) => {
    const { tagsList } = getState().tags;

    let newTagsList = [...tagsList, newTag];
    dispatch(set_tags(newTagsList));
  };
};

const fetchTagsToggle = () => {
  return dispatch => {
    dispatch(fetch_tags(true));
  };
};

const setTags = (newList) => {
  return dispatch => {
    dispatch(set_tags(newList));
  };
};

const deleteTagByID = (id, clearFilteringInputs) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch(is_loading_tags(true));
    const data = JSON.stringify({ id: id });

    let config = {
      method: "DELETE",
      url: process.env.REACT_APP_TRIGGERS_URL + "tag/delete/" + id,
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      // data: data,
    };

    axios(config)
      .then(function (response) {
        openNotificationWithIcon("success", "Deleting Tag", "Tag Deleted Successfully");
        dispatch(deleteTagByIDLocally(id));
        dispatch(is_loading_tags(false));
        clearFilteringInputs();
      })
      .catch(function (error) {
        openNotificationWithIcon("error", "Deleting Tag", "Deleting Tag Was Not Successfully !");
        console.log("Deleting Tag Error", error.data);
        dispatch(is_loading_tags(false));
      });
  };
};

const deleteTagByIDLocally = (id) => {
  return (dispatch, getState) => {
    const { tagsList } = getState().tags;
    let newTagsList = [...tagsList];

    newTagsList = newTagsList.filter(tag => tag.id !== id);
    dispatch(set_tags(newTagsList));
  };
};

export { getTags, addTagToDB, fetchTagsToggle, setTags, deleteTagByID };