import { combineReducers } from "redux";
// import themeUsersReducer from "./themeUsers TODELETE/reducers";
import { readMessageReducer } from "./message/reducers";
import { readNotificationReducer } from "./notification/reducers";
import authReducer from "./authentication/reducers";
import ChangeLayoutMode from "./themeLayout/reducers";
// import { teamReducer } from "./team TODELETE/reducers";
// import { userReducer, userGroupReducer } from "./users TODELETE/reducers";
// import { sellersReducer } from "./sellers TODELETE/reducers";
// import { headerSearchReducer } from "./headerSearch TODELETE/reducers";
// import orderReducer from "./orders TODELETE/reducers";
// import galleryReducer from "./gallary TODELETE/reducers";
import chartContentReducer from "./chartContent/reducers";
// import { emailReducer, SingleEmailReducer } from "./email TODELETE/reducers";
// import { productReducer, SingleProductReducer } from "./product TODELETE/reducers";
// import { chatReducer, SingleChatReducer, groupChatReducer, SingleChatGroupReducer, } from "./chat TODELETE/reducers";
// import { projectReducer, SingleProjectReducer } from "./project/reducers";
// import cartData from "./cart TODELETE/reducers";
// import Todo from "./todo TODELETE/reducers";
import Note from "./note/reducers";
// import Task from "./task TODELETE/reducers";
// import kanbanBoardReducer from "./kanban TODELETE/reducers";
// import Contact from "./contact TODELETE/reducers";
import Profile from "./profile/reducers";
// import Calender from "./calendar TODELETE/reducers";
// import FileManager from "./fileManager TODELETE/reducers";
import rpasReducer from "./rpas/reducers";
import knowledgeAIReducer from "./knowledge-ai/reducers";
import adaptiveCardsReducer from "./adaptive-cards/reducers";
import uploadFilesReducer from "./upload-file-modal/reducers";
import keyVaultReducer from "./key-vault-modal/reducers";
import chatbotsReducer from "./chatbots/reducers";
import storiesReducer from "./stories/reducers";
import deploymentsReducer from "./deployments/reducers";
import userDetailsReducer from "./user-details/reducers";
import CredentialsReducer from "./credentials/reducer";
import whatsappCardsReducer from "./whatsapp-card/reducers";
import reportingReducer from "./reporting/reducers";
import UsersReducer from "./users/reducers";
import TagsReducer from "./tags/reducers";
import tokenUsageReducer from "./token-usage/reducers";
import DataSetReducer from "./data-set/reducers";

const rootReducers = combineReducers({
  // themeUsers: themeUsersReducer,
  // headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  // orders: orderReducer,
  // sellers: sellersReducer,
  // users: userReducer,
  // userGroup: userGroupReducer,
  // team: teamReducer,
  auth: authReducer,
  // gallery: galleryReducer,
  // email: emailReducer,
  // emailSingle: SingleEmailReducer,
  // products: productReducer,
  // product: SingleProductReducer,
  // chatSingle: SingleChatReducer,
  // chatSingleGroup: SingleChatGroupReducer,
  // chat: chatReducer,
  // groupChat: groupChatReducer,
  // projects: projectReducer,
  // project: SingleProjectReducer,
  ChangeLayoutMode,
  chartContent: chartContentReducer,
  // cart: cartData,
  // Todo,
  Note,
  // Task,
  // KanbanBoard: kanbanBoardReducer,
  // Contact,
  Profile,
  // Calender,
  // FileManager,
  adaptiveCards: adaptiveCardsReducer,
  whatsappCards: whatsappCardsReducer,
  rpas: rpasReducer,
  chatbots: chatbotsReducer,
  knowledgeAI: knowledgeAIReducer,
  uploadFiles: uploadFilesReducer,
  keyVault: keyVaultReducer,
  stories: storiesReducer,
  deployments: deploymentsReducer,
  userDetails: userDetailsReducer,
  credentials: CredentialsReducer,
  reporting: reportingReducer,
  users: UsersReducer,
  tags: TagsReducer,
  tokenUsage: tokenUsageReducer,
  dataSet: DataSetReducer,

});

export default rootReducers;
