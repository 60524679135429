const actions = {
  IS_LOADING_TAGS: 'IS_LOADING_TAGS',
  FETCH_TAGS: 'FETCH_TAGS',
  SET_TAGS: 'SET_TAGS',

  is_loading_tags: (status) => {
    return {
      type: actions.IS_LOADING_TAGS,
      status: status,
    };
  },
  fetch_tags: (status) => {
    return {
      type: actions.FETCH_TAGS,
      status: status,
    };
  },
  set_tags: (data) => {
    return {
      type: actions.SET_TAGS,
      data: data,
    };
  },
};

export default actions;
