import PropTypes from 'prop-types';
import React from 'react';
import { IMG } from './style';


const Image = ({ src, alt }) => {
    return (
        <IMG
            src={src}
            alt={alt}
        />
    );
};

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
};

export default Image;
