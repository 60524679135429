import React from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Button, Tag } from "antd";
import noImage from "./noImage.png"
import { Link } from "react-router-dom";
const CardWrapper = styled.figure`
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 0;
  text-align:center;
  

  
  .imgcont{
    margin-top:5px
    max-height:110px;
    display:flex;
align-items:center;
justify-content:center;
  }
  img{
      width: 100%;
      // height:100%
      height:60%;
      // max-width:90%
    }
  figcaption {
    display:flex;
    flex-direction:column
     justify-content:flex-start;
     align-items:flex-start;
     margin-left:5px;
    h2 {
      margin: 13px 0 5px 0;
      margin-bottom:0px
      font-size: 18px;
      font-weight: 700;
      max-width:75%
      height:54px;
      overflow-wrap: break-word;
      overflow:hidden
    }
    p {
      /* line-height: 1.79; */
      color: ${({ theme }) => theme["gray-color"]};
      max-width:100%
      font-size:14px;
      height:auto;
      overflow-wrap: break-word;
      overflow:hidden
      margin-bottom:0px;
    }
    
    a {
      color: ${({ theme }) => theme["primary-color"]};
      font-size: 17px;
      font-weight: 600;
    }
    .link{
      margin-top:20px;
    }
    .tag{
      display: flex;     
      flex-direction:row
      flex-wrap:wrap
      gap:6px
      align-items:center;
      height:50px
      /* max-width:90%; */
      overflow:hidden;
    }
  }
`;

const StoriesCard = (props) => {
  const {
    summary,
    title,
    img,
    tags,
    token,
    storyID,
    handleNewOrEditActionClick,
    backupImage,
  } = props;

  return (
    <CardWrapper
      onClick={() => handleNewOrEditActionClick(token, storyID)}
      style={{ borderRadius: "5px", border: '1px solid #e0e0e0', width: "95%", height: "97%", alignItems: "center", justifyContent: "flex-start", display: "flex", flexDirection: "column" }} className="card">
      <div className="imgcont" >
        <img src={backupImage ? noImage : img} alt="" />
      </div>
      <figcaption>
        <Link
          onClick={() => handleNewOrEditActionClick(token, storyID)}
          className="title"
        >
          {title}
        </Link>
        <div className="summary">{summary}</div>
        {/* <Radio.Group class> */}
        {/* {triggerTypeAndStatusFilterKey.map((value) => { */}
        {/* return ( */}
        <div className="tagg">
          {tags.map((tag, index) => {
            return (
              <div className="tags" key={index}>
                {tag}
              </div>
            );
          })}
        </div>

        {/* <div className='link'>
          <Button type="primary" onClick={() => handleNewOrEditActionClick(token, storyID)}>
            View Story
          </Button>
        </div> */}
      </figcaption>
    </CardWrapper>
  );
};

StoriesCard.propTypes = {
  item: propTypes.object,
};

StoriesCard.defaultProps = {
  item: {
    id: 1,
    title: "Story Title",
    summary:
      "Lorem Ipsum is simply dummy text of the printer took a galley of type and scrambled and typesetting industry.",
    img: "../../static/img/AzureApplicationSecurityGroup.png",
    tags: ["tag1", "tag2", "tag3"],
  },
};

export default StoriesCard;
