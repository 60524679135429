import Styled from 'styled-components';

const LoadingButtonStyled = Styled.button`
    cursor:pointer
    height: 34px;
    width: 37px;
    padding: 1px 0px 0px 1px;
    margin: 6px 0px 0px 15px;
    background: ${({ theme }) => theme["primary-color"]};
    border: 0px;
    border-radius: 20px;
    &:hover{
        background: #9f276c;
    }
    &:disabled{
        background: #b5b5b5;
        cursor: not-allowed
    }
`;

const DivSpinStyled = Styled.div`
    height: 34px;
    width: 37px;
    margin: 3px 0px 0px 15px;
    padding: 9px 0px 0px 12px;
    border: 0px;
`;

export { DivSpinStyled, LoadingButtonStyled };
